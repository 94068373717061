import { Avatar, Box, Button, Typography, withStyles } from "@material-ui/core";
import {
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  Edit as EditIcon
} from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { getProfileQuery } from "../../ducks/selectors";
import ChipStatus from "./ChipStatus";
import * as Messages from "./Messages";
import OutlinedDiv from "./OutlinedDiv";
import PowerButton from "./PowerButton";
import PowerButtonLink from "./PowerButtonLink";

const SmallButton = withStyles({
  label: {
    padding: 0,
    fontSize: "unset"
  },
  outlined: {
    padding: "4px 12px"
  }
})(Button);

const CompanyPersonBox = ({ companyPerson, isCompanyOwned, onDelete }) => {
  const { url } = useRouteMatch();

  const {
    data: { data: profile }
  } = useSelector(getProfileQuery);

  const showStatus =
    (isCompanyOwned && companyPerson.aasm_state !== "accepted") ||
    (companyPerson.person?.id === profile.person?.id && companyPerson.aasm_state === "proposed");

  return (
    <OutlinedDiv>
      <Box display="flex" padding="8px">
        <Avatar
          src={((companyPerson.person || {}).avatar_urls || {}).small}
          alt={`${companyPerson.person.first_name} ${companyPerson.person.last_name}`}
        >{`${companyPerson.person.first_name[0]}${companyPerson.person.last_name[0]}`}</Avatar>
        <Box marginLeft="8px">
          <Typography variant="body2">
            <strong>{`${companyPerson.person.first_name} ${companyPerson.person.last_name}`}</strong>
          </Typography>
          <Typography variant="body2">{companyPerson.job_title}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={1}>
          {isCompanyOwned && (
            <PowerButtonLink
              to={`${url}/${companyPerson.id}`}
              icon={companyPerson.aasm_state === "proposed" ? DoneIcon : EditIcon}
              title={companyPerson.aasm_state === "proposed" ? Messages.Accept : Messages.Edit}
            />
          )}
          {isCompanyOwned && (
            <PowerButton
              onClick={onDelete}
              icon={companyPerson.aasm_state === "proposed" ? ClearIcon : DeleteIcon}
              title={companyPerson.aasm_state === "proposed" ? Messages.Discard : Messages.Delete}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {showStatus && (
          <Box marginRight={1}>
            <ChipStatus status={companyPerson.aasm_state} />
          </Box>
        )}
        <SmallButton component={Link} to={`/people/${companyPerson.person.id}`} variant="outlined">
          {Messages.ShowProfile}
        </SmallButton>
      </Box>
    </OutlinedDiv>
  );
};

export default CompanyPersonBox;
