import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { bindActionCreators } from "redux";
import { error } from "redux-saga-requests";
import { getProfile } from "../ducks/actions";
import { isAuthenticated } from "../ducks/selectors";
import { GET_PROFILE } from "../ducks/types";

const enhance = compose(
  withRouter,
  connect(
    state => ({ isAuth: isAuthenticated(state) }),
    dispatch => ({ actions: bindActionCreators({ getProfile }, dispatch) })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions, history, isAuth } = this.props;
      if (isAuth) {
        try {
          await actions.getProfile();
        } catch (err) {
          console.warn(err);
          if (err.type === error(GET_PROFILE)) history.push("/profile/new");
        }
      }
    }
  })
);

export default enhance;
