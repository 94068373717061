import React from "react";
import PropTypes from "prop-types";
import { Tooltip, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const PowerButton = ({ title, size = "small", icon: MaterialIcon, onClick }) => (
  <Tooltip title={title} placement="top">
    <IconButton size={size} onClick={onClick}>
      <MaterialIcon />
    </IconButton>
  </Tooltip>
);

PowerButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  size: PropTypes.string,
  icon: PropTypes.shape({}),
  onClick: PropTypes.func
};

PowerButton.defaultProps = {
  title: "",
  size: "small",
  icon: Add,
  onClick: PropTypes.func
};

export default PowerButton;
