import React from "react";
import { AuthBody, AuthLayout } from "../layouts/auth";
import * as Messages from "./Messages";
import SignUpForm from "./SignUpForm";

const SignUpPage = () => (
  <AuthLayout>
    <AuthBody>
      <AuthBody.Title>{Messages.SignUp}</AuthBody.Title>
      <AuthBody.Content>
        <SignUpForm />
      </AuthBody.Content>
    </AuthBody>
  </AuthLayout>
);

export default SignUpPage;
