/* eslint-disable react/prop-types */
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import { generateCompany, getCompanyImport } from "../ducks/actions";
import { companyImport } from "../ducks/selectors";
import { withNotifications } from "../notifications";
import About from "../shared/components/About";
import * as Messages from "./Messages";

const CompanyImportConfirm = ({ company, onConfirm }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <About company={company} />
      </Grid>
      <Grid xs={5}>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {Messages.Confirm}
        </Button>
      </Grid>
    </Grid>
  );
};

const enhance = compose(
  withNotifications,
  withRouter,
  connect(
    state => ({
      company: companyImport(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getCompanyImport, generateCompany }, dispatch) })
  ),
  withHandlers({
    onConfirm: ({ history, actions, company, ko }) => async () => {
      try {
        const { tax_code: taxCode } = company;
        const { response } = await actions.generateCompany({ id: taxCode });
        if (response.data.success) {
          history.push(`/companies/${response.data.data.company_id}`);
        } else {
          ko(Messages.CompanyConfirmError);
        }
      } catch (err) {
        ko(Messages.GenerateCompanyFailed);
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const {
        actions,
        match: {
          params: { id }
        }
      } = this.props;
      actions.getCompanyImport({ id });
    }
  })
);

export default enhance(CompanyImportConfirm);
