import { LinearProgress, styled } from "@material-ui/core";
import _ from "lodash";
import qs from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCompaniesQuery } from "../ducks/selectors";
import StartupTable from "../shared/components/StartupTable";
import NoResultsAskForImport from "./NoResultsAskForImport";
import SeeMore from "./SeeMore";

const LoaderContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const StartupList = ({ isInvestor }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { page, per_page: perPage, ...queryParams } = qs.parse(search, { arrayFormat: "bracket" });

  const onSetPage = value => {
    history.push({
      search: qs.stringify({
        ...queryParams,
        page: value + 1,
        per_page: perPage || 10
      })
    });
  };

  const onSetPerPage = value => {
    history.push(
      { search: qs.stringify({ ...queryParams, page: 1, per_page: value }) },
      { arrayFormat: "bracket" }
    );
  };

  const {
    data: {
      data: companies,
      meta: { total }
    },
    loading: companiesLoading
  } = useSelector(getCompaniesQuery);

  if (companiesLoading) {
    return (
      <LoaderContainer>
        <LinearProgress />
      </LoaderContainer>
    );
  }

  if (_.isEmpty(companies)) {
    return <NoResultsAskForImport withImage />;
  }

  return (
    <>
      <StartupTable
        startups={companies}
        isPaginated
        isInvestor={isInvestor}
        page={+page - 1}
        perPage={+perPage}
        total={total}
        onSetPage={onSetPage}
        onSetPerPage={onSetPerPage}
      />
      <SeeMore />
    </>
  );
};

export default StartupList;
