import React from "react";
import { compose, lifecycle, branch, renderComponent } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, withFormik, Field } from "formik";
import { Button, Grid, LinearProgress } from "@material-ui/core";
import * as yup from "yup";
import { find } from "lodash";
import { getInterests, updateProfile } from "../ducks/actions";
import { interests, interestsLoading, profile } from "../ducks/selectors";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import ImageCheckboxField from "../shared/formik/ImageCheckboxField";
import * as Messages from "./Messages";
import { withNotifications } from "../notifications";

const ProfileInterests = ({ interests, dirty, isSubmitting, isValid }) => (
  <Form>
    <Grid container spacing={2}>
      {interests.map(({ id, name, cover_image_url: coverImageUrl }) => (
        <Grid key={id} item xs={12} sm={6}>
          <Field name={id} label={name} imageSrc={coverImageUrl} component={ImageCheckboxField} />
        </Grid>
      ))}
    </Grid>
    <Grid container justify="flex-end" spacing={2}>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
        >
          {Messages.Save}
        </Button>
      </Grid>
    </Grid>
  </Form>
);

ProfileInterests.propTypes = FormPropTypes;

yup.setLocale({
  mixed: {
    required: "validation.FieldRequired"
  }
});

const validationSchema = yup.object().shape({});

const enhance = compose(
  withNotifications,
  withRouter,
  connect(
    state => ({
      profile: profile(state),
      interests: interests(state),
      interestsLoading: interestsLoading(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getInterests, updateProfile }, dispatch) })
  ),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getInterests();
    }
  }),
  branch(
    ({ interestsLoading }) => interestsLoading,
    renderComponent(() => <LinearProgress />)
  ),
  withFormik({
    mapPropsToValues: ({ interests, profile }) => {
      const selectedInterests = interests.reduce((result, value) => {
        return {
          ...result,
          [value.id]: find(profile.interests || [], { id: String(value.id) }) || false
        };
      }, {});
      return selectedInterests;
    },
    handleSubmit: async (values, { props: { actions, history, ok } }) => {
      const interestIds = Object.keys(values).filter(key => values[key]);
      try {
        await actions.updateProfile({ profile: { interestIds } });
        ok(Messages.ProfileUpdated);
        history.push("/profile/me");
      } catch (err) {
        console.warn(err);
      }
    },
    enableReinitialize: true,
    validationSchema
  })
);

export default enhance(ProfileInterests);
