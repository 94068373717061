import { Button, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { DateRange as DateRangeIcon } from "@material-ui/icons";
import { Field, Form, withFormik } from "formik";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { branch, compose, lifecycle, renderComponent, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import * as yup from "yup";
import { getProfile, updateAvatar, updateProfile } from "../ducks/actions";
import { profile, profileLoading } from "../ducks/selectors";
import { withNotifications } from "../notifications";
import DatePickerField from "../shared/formik/DatePickerField";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import TextField from "../shared/formik/TextField";
import AvatarUploader from "./AvatarUploader";
import * as Messages from "./Messages";

const useStyles = makeStyles(() => ({
  avatarUploaderWrap: {
    textAlign: "center"
  },
  saveButton: {
    marginTop: 20
  }
}));

const EditProfileForm = ({ isSubmitting, isValid, dirty, onFileUpload }) => {
  const classes = useStyles();

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.avatarUploaderWrap}>
            <AvatarUploader onFileUpload={onFileUpload} />
          </div>
          <Field
            component={TextField}
            label={Messages.FirstName}
            name="firstName"
            helperText={false}
            fullWidth
          />
          <Field
            component={TextField}
            label={Messages.LastName}
            name="lastName"
            helperText={false}
            fullWidth
          />
          <Field
            component={DatePickerField}
            label={Messages.Birthday}
            name="birthday"
            format="DD/MM/YYYY"
            inputVariant="outlined"
            disableFuture
            KeyboardButtonProps={{ size: "small" }}
            keyboardIcon={<DateRangeIcon fontSize="small" />}
            variant="inline"
            helperText={false}
            fullWidth
          />
          <Field
            component={TextField}
            label={Messages.Place}
            helperText={false}
            name="place"
            fullWidth
          />
          <Field
            component={TextField}
            label={Messages.LastCompanyName}
            name="lastCompanyName"
            fullWidth
          />
          <Field
            component={TextField}
            label={Messages.LastJobTitle}
            name="lastJobTitle"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!dirty || !isValid || isSubmitting}
            type="submit"
            className={classes.saveButton}
          >
            {Messages.Save}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

EditProfileForm.propTypes = FormPropTypes;

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  birthday: yup
    .date()
    .nullable()
    .max(new Date(Date.now()), "La data non può essere nel futuro"),
  place: yup.string().required(),
  lastCompanyName: yup.string(),
  lastJobTitle: yup.string()
});

const enhance = compose(
  withNotifications,
  connect(
    state => ({
      profile: profile(state),
      profileLoading: profileLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getProfile, updateProfile, updateAvatar }, dispatch)
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions } = this.props;
      try {
        await actions.getProfile();
      } catch (err) {
        console.warn(err);
      }
    }
  }),
  branch(
    ({ profileLoading }) => profileLoading,
    renderComponent(() => <LinearProgress />)
  ),
  withFormik({
    mapPropsToValues: ({ profile }) => ({
      avatarChanged: false,
      firstName: profile.first_name || "",
      lastName: profile.last_name || "",
      birthday: profile.birthday ? moment(profile.birthday) : null,
      place: profile.place || "",
      lastCompanyName: profile.last_company_name || "",
      lastJobTitle: profile.last_job_title || ""
    }),
    enableReinitialize: true,
    handleSubmit: async ({ avatarChanged, ...values }, { props: { actions, ok, ko } }) => {
      try {
        await actions.updateProfile({ profile: values });
        ok(Messages.ProfileUpdated);
      } catch (err) {
        ko(Messages.ErrorOnProfileUpdate);
        console.warn(err);
      }
    },
    validationSchema
  }),
  withHandlers({
    onFileUpload: ({ actions, ok, ko }) => async ({ file }) => {
      try {
        await actions.updateAvatar({ avatar: file });
        ok(Messages.ProfileUpdated);
      } catch (err) {
        ko(Messages.ErrorOnProfileUpdate);
      }
    }
  })
);

export default enhance(EditProfileForm);
