/* eslint-disable camelcase */
import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ownedCompanies, profile } from "../ducks/selectors";
import { SearchLayout } from "../layouts/search";
import CompanySearch from "./CompanySearch";
import Favourites from "./Favourites";
import { AllFeedRss, MyFeedRss } from "./FeedRSS";
import * as Messages from "./Messages";
import MyStartups from "./MyStartups";
import ProfileBox from "./ProfileBox";
import SideMenu from "./SideMenu";

const PrivateHomePage = () => {
  const ownedCompaniesList = useSelector(ownedCompanies({ state: "accepted" }));
  const currentProfile = useSelector(profile);

  const items = [
    { to: "/home/news", label: Messages.AllNews },
    { to: "/home/me", label: Messages.YourInterests },
    { to: "/home/favourites", label: Messages.Favourites }
  ];

  if (ownedCompaniesList.lenght > 0 || currentProfile.person?.investor_id) {
    items.unshift({ to: "/home", label: Messages.MyStartups });
  }

  return (
    <SearchLayout>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={3}>
          <ProfileBox />
          <SideMenu
            title={Messages.Explore}
            items={[
              { to: "/search?type=startups", label: Messages.SearchStartUps },
              { to: "/search?type=people", label: Messages.SearchPeople },
              { to: "/search?type=investors", label: Messages.SearchInvestors }
            ]}
          />
          <SideMenu showStartups title={Messages.MyUpBase} items={items} />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Switch>
            {ownedCompaniesList && <Route path="/home" exact component={MyStartups} />}
            <Route path="/home/news" exact component={AllFeedRss} />
            <Route path="/home/me" exact component={MyFeedRss} />
            <Route path="/home/favourites" exact component={Favourites} />
            <Route path="/home/search_startup" exact component={CompanySearch} />
            <Redirect to="/home/me" />
          </Switch>
        </Grid>
      </Grid>
    </SearchLayout>
  );
};

export default PrivateHomePage;
