import React from "react";
import { Grid } from "@material-ui/core";
import { AuthLayout, AuthBody } from "../layouts/private";
import InterestsForm from "./InterestsForm";
import * as Messages from "./Messages";

const InterestPage = () => {
  return (
    <AuthLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <AuthBody>
            <AuthBody.Title>{Messages.WhatInterestsYou}</AuthBody.Title>
            <InterestsForm />
          </AuthBody>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default InterestPage;
