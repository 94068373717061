import { Chip, makeStyles } from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import { capitalize } from "lodash";
import React from "react";

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: ({ status }) => (status === "accepted" ? green[100] : orange[100])
  }
}));

const ChipStatus = ({ status, size = "small" }) => {
  const classes = useStyles({ status });

  return <Chip size={size} label={capitalize(status)} className={classes.status} />;
};

export default ChipStatus;
