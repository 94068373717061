import { Table } from "@devexpress/dx-react-grid-material-ui";
import { makeStyles } from "@material-ui/core";
import { isString } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import DenseRow from "./DenseRow";

const useStyles = makeStyles(() => ({
  row: { cursor: "pointer", "&:hover": { backgroundColor: "#ebebeb" } }
}));

const LinkRow = ({ row, dense, linkTo, ...restProps }) => {
  const history = useHistory();
  const classes = useStyles();
  return dense ? (
    <DenseRow
      {...restProps}
      className={classes.row}
      onClick={() => history.push(isString(linkTo) ? linkTo : linkTo({ row }))}
    />
  ) : (
    <Table.Row
      {...restProps}
      className={classes.row}
      onClick={() => history.push(isString(linkTo) ? linkTo : linkTo({ row }))}
    />
  );
};

LinkRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  dense: PropTypes.bool,
  linkTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired
};

LinkRow.defaultProps = {
  dense: false
};

export default LinkRow;
