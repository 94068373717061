import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { createCompanyOwner, createCompanyPropertyRequest, getProfile } from "../../ducks/actions";
import { getProfileQuery } from "../../ducks/selectors";
import { useNotifications } from "../../notifications";
import AutoForm from "./AutoForm";
import * as Messages from "./Messages";

const CompanyWithDomainForm = ({ id, domain, onClose }) => {
  const dispatch = useDispatch();
  const { ok, ko } = useNotifications();
  const [fieldConfiguration] = useState({
    default: {
      fields: [
        {
          name: "owner_email",
          label: Messages.Email,
          helperText: domain && Messages.InsertDomainEmail(domain)
        }
      ]
    }
  });

  const [formikProps] = useState({
    initialValues: {
      owner_email: ""
    },
    validationSchema: yup.object().shape({
      owner_email: yup
        .string()
        .email()
        .required()
    }),
    onSubmit: async ({ owner_email: ownerEmail }) => {
      try {
        await dispatch(createCompanyOwner({ id, ownerEmail }));
        await dispatch(getProfile());
        ok(Messages.OwnershipRequestSuccess);
      } catch {
        ko(Messages.OwnershipRequestError);
      }
      onClose();
    }
  });

  return (
    <AutoForm
      fieldConfiguration={fieldConfiguration}
      formikProps={formikProps}
      label={Messages.ConfirmOwnership}
    />
  );
};
const CompanyWithoutDomainForm = ({ id, entity, onClose }) => {
  const dispatch = useDispatch();
  const { ok, ko } = useNotifications();

  const {
    data: { data: profile }
  } = useSelector(getProfileQuery);

  const [fieldConfiguration] = useState({
    default: {
      fields: [
        {
          name: "companyName",
          label: Messages.Company,
          disabled: true
        },
        {
          name: "profileName",
          label: Messages.Profile,
          disabled: true
        },
        {
          name: "website",
          label: Messages.Website,
          helperText: Messages.WebsiteHelperText
        },
        {
          name: "body",
          label: Messages.Message,
          multiline: true,
          rows: 3
        }
      ]
    }
  });

  const [formikProps] = useState({
    initialValues: {
      companyName: entity.name,
      profileName: `${profile.first_name} ${profile.last_name}`,
      website: "",
      body: ""
    },
    validationSchema: yup.object().shape({
      companyName: yup.string().required(),
      profileName: yup.string().required(),
      website: yup.string().required(),
      body: yup.string()
    }),
    onSubmit: async ({ website, body }) => {
      try {
        await dispatch(createCompanyPropertyRequest({ id, website, body }));
        ok(Messages.CreatePropertyRequestSuccess);
      } catch {
        ko(Messages.CreatePropertyRequestError);
        dispatch(getProfile());
      }
      onClose();
    }
  });

  return (
    <AutoForm
      fieldConfiguration={fieldConfiguration}
      formikProps={formikProps}
      label={Messages.ConfirmOwnership}
    />
  );
};

const CreateOwnerDialog = ({ open, onClose, entity }) => {
  const domain = entity.website?.split(/\/\/|www./).pop();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box m={1} display="flex" justifyContent="space-between" alignItems="start">
          {domain ? Messages.CompanyOwnershipConfirmation : Messages.MissingDomainTitle}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {domain ? (
          <CompanyWithDomainForm id={entity.id} domain={domain} onClose={onClose} />
        ) : (
          <CompanyWithoutDomainForm id={entity.id} entity={entity} onClose={onClose} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateOwnerDialog;
