import { styled, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import React from "react";
import * as Messages from "./Messages";

const ContactTitle = styled(Typography)({
  fontSize: 14,
  textTransform: "uppercase",
  fontWeight: "800"
});

const ContactContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2)
}));

const ContactLink = styled("a")(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main
}));

const ContactPersonalsContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const SocialLink = ({ icon: Icon, url }) => {
  return url ? (
    <ContactLink href={url} target="_blank" rel="noopener noreferrer">
      <Icon />
    </ContactLink>
  ) : null;
};

const Contacts = ({ entity }) => {
  if (
    !entity.website &&
    !entity.project_website &&
    !entity.email &&
    !entity.facebook_url &&
    !entity.twitter_url &&
    !entity.linkedin_url &&
    !entity.instagram_url
  ) {
    return (
      <ContactContainer>
        <Typography variant="body2">{Messages.NoAvailableContact}</Typography>
      </ContactContainer>
    );
  }
  return (
    <ContactContainer>
      <ContactTitle variant="h6">{Messages.Contacts}</ContactTitle>
      {(entity.project_website || entity.website || entity.email) && (
        <ContactPersonalsContainer>
          {entity.project_website ||
            (entity.website && (
              <a
                href={`//${entity.project_website || entity.website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {Messages.OpenWebsite}
              </a>
            ))}
          <Typography>{entity.email}</Typography>
        </ContactPersonalsContainer>
      )}
      <SocialLink icon={FacebookIcon} url={entity.facebook_url} />
      <SocialLink icon={TwitterIcon} url={entity.twitter_url} />
      <SocialLink icon={LinkedInIcon} url={entity.linkedin_url} />
      <SocialLink icon={InstagramIcon} url={entity.instagram_url} />
    </ContactContainer>
  );
};

export default Contacts;
