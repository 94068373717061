import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const CompanyImportTitle = (
  <FormattedMessage
    id="company-import.CompanyImportTitle"
    defaultMessage="Importa la tua Satartup"
  />
);

export const InsertPiva = (
  <FormattedMessage id="company-import.InsertPiva" defaultMessage="Inserisci partita IVA" />
);

export const MissingPiva = (
  <FormattedMessage id="company-import.MissingPiva" defaultMessage="Partita iva non trovata" />
);

export const SearchStartup = (
  <FormattedMessage id="company-import.SearchStartup" defaultMessage="Cerca Startup" />
);

export const Confirm = <FormattedMessage id="company-import.Confirm" defaultMessage="Importa" />;

export const CompanyConfirmError = (
  <FormattedMessage
    id="company-import.CompanyConfirmError"
    defaultMessage="Si è verificato un errore durante la conferma."
  />
);
export const SearchStartupFailed = (
  <FormattedMessage
    id="company-import.SearchStartupFailed"
    defaultMessage="La ricerca della Startup non è andata a buon fine"
  />
);

export const GenerateCompanyFailed = (
  <FormattedMessage
    id="company-import.GenerateCompanyFailed"
    defaultMessage="La creazione della Startup non è andata a buon fine"
  />
);
