import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  styled,
  Toolbar,
  withStyles
} from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../../ducks/selectors";
import ContextMenu from "../ContextMenu";
import SearchForm from "./SearchForm";

const PersonIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));
const SignUpButton = withStyles({ label: { fontWeight: 400 } })(Button);

const Layout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isAuth = useSelector(isAuthenticated);

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Container>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Link to={isAuth ? "/home" : "/"}>
                <img src="/images/upbase/logo-home.svg" alt="UpBase" height="36px" />
              </Link>
              <SearchForm />
              {isAuth ? (
                <>
                  <PersonIconButton size="small" onClick={handleClick}>
                    <PersonIcon />
                  </PersonIconButton>
                  <ContextMenu anchorEl={anchorEl} onClose={handleClose} />
                </>
              ) : (
                <SignUpButton component={Link} to="/login">
                  Sign in
                </SignUpButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>{children}</Container>
    </>
  );
};

export default Layout;
