import { useFormikContext } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustriesTags, getIndustry } from "../../ducks/actions";
import { getIndustriesTagsQuery, getIndustryQuery } from "../../ducks/selectors";
import ReactSelectField from "./ReactSelectField";

const TagsSelectField = ({ byIndustry, field, ...props }) => {
  const dispatch = useDispatch();
  const { setFieldValue, values, initialValues } = useFormikContext();

  useEffect(() => {
    if (byIndustry && values.industryId) {
      dispatch(getIndustry({ id: values.industryId })).catch(err => console.log(err));
      if (initialValues.industryId !== values.industryId) {
        setFieldValue(field.name, []);
      }
    } else {
      dispatch(getIndustriesTags()).catch(err => console.log(err));
    }
  }, [
    byIndustry,
    dispatch,
    field.name,
    setFieldValue,
    values.industryId,
    initialValues.industryId
  ]);

  const {
    data: { data: industry }
  } = useSelector(getIndustryQuery);
  const {
    data: { data: industriesTags }
  } = useSelector(getIndustriesTagsQuery);

  const tagOptions = byIndustry
    ? _.chain(industry.tag_list)
        .sort()
        .map(tag => ({ value: tag, label: tag }))
        .value()
    : _.chain(industriesTags)
        .sortBy("name")
        .map(({ name }) => ({ value: name, label: name }))
        .value();

  return <ReactSelectField isMulti options={tagOptions} field={field} {...props} />;
};

export default TagsSelectField;
