/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  makeStyles,
  styled,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import { AccountBalanceWallet, Add, BusinessCenter, Edit } from "@material-ui/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { branch, compose, lifecycle, renderNothing, withHandlers, withState } from "recompose";
import { bindActionCreators } from "redux";
import {
  deleteCompanyFundingRound,
  getCompanyFundingRounds,
  getCompanyInvestors,
  getCompany
} from "../ducks/actions";
import {
  companyFundingRounds,
  companyFundingRoundsLoading,
  companyInvestors,
  companyInvestorsLoading,
  isCompanyOwned,
  totalFunding
} from "../ducks/selectors";
import { withNotifications } from "../notifications";
import DeleteRoundDialog from "../shared/components/DeleteRoundDialog";
import PowerButtonLink from "../shared/components/PowerButtonLink";
import { fundingStatusMap } from "../shared/utils";
import AddRoundDialog from "./AddRoundDialog";
import EditRoundDialog from "./EditRoundDialog";
import * as Messages from "./Messages";

const SummaryBox = styled(Box)({
  border: "solid 1px #ebebeb",
  borderRadius: 4,
  backgroundColor: "#f8f8f8"
});

const NoRoundsFound = styled(Box)({
  padding: 10
});

const OutlinedDiv = styled("div")({
  border: "solid 1px #ebebeb",
  borderRadius: 4,
  padding: 16,
  margin: "16px 0"
});

const SmallButton = withStyles({
  label: {
    padding: 0,
    fontSize: "unset"
  },
  outlined: {
    padding: "4px 12px"
  }
})(Button);

const useStyles = makeStyles(() => ({
  logos: {
    marginLeft: 8
  },
  investorName: {
    marginLeft: 8
  }
}));

numeral.register("locale", "it", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  currency: {
    symbol: "€"
  }
});

numeral.locale("it");

const FundingTab = ({
  rounds,
  total,
  isCompanyOwned,
  investors,
  onConfirmClick,
  open,
  setOpen
}) => {
  const { url } = useRouteMatch();

  const classes = useStyles();

  if (isEmpty(rounds)) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <NoRoundsFound>
          <Typography variant="body1">
            <strong>{isCompanyOwned ? Messages.InsertFundingData : Messages.NoFundingData}</strong>
          </Typography>
        </NoRoundsFound>
        {isCompanyOwned && (
          <PowerButtonLink to={`${url}/create`} icon={Add} title={Messages.AddRound} />
        )}
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="button">
          <strong>{Messages.Funding}</strong>
        </Typography>
        {isCompanyOwned && (
          <PowerButtonLink to={`${url}/create`} icon={Add} title={Messages.AddRound} />
        )}
      </Box>
      <SummaryBox p={2} m={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <strong>{Messages.FundingAmount}</strong>
            </Typography>
            <Typography color="primary" variant="h4">
              {numeral(total).format("$0,0[.]00")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              <strong>{Messages.NumberOfFundingRounds}</strong>
            </Typography>
            <Typography color="primary" variant="h4">
              {rounds.length} rounds
            </Typography>
          </Grid>
        </Grid>
      </SummaryBox>
      <Typography variant="button">
        <strong>{Messages.Round}</strong>
      </Typography>
      <List>
        {rounds.map((round, index) => (
          <React.Fragment key={round.id}>
            <ListItem style={{ display: "block" }}>
              <Box display="flex" alignItems="center">
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: round.status_color }}>
                    <AccountBalanceWallet />
                  </Avatar>
                </ListItemAvatar>
                <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
                  <Typography>
                    <strong>
                      {round.amount.cents
                        ? numeral(round.amount.cents / 100).format("$0,0[.]00")
                        : Messages.Undisclosed}
                    </strong>
                  </Typography>
                  {isCompanyOwned && (
                    <div>
                      <PowerButtonLink
                        title={Messages.EditRound}
                        icon={Edit}
                        to={`${url}/${round.id}`}
                      />
                      <DeleteRoundDialog
                        onConfirmClick={() => onConfirmClick({ roundId: round.id })}
                        open={open}
                        setOpen={setOpen}
                      />
                    </div>
                  )}
                </Box>
              </Box>
              <Box display="flex" list-style="none" justifyContent="space-between" marginTop="16px">
                <Box width="33%" justifyContent="left" display="flex">
                  <Typography text-align="center">
                    {fundingStatusMap[round.status_description]}
                  </Typography>
                </Box>
                <Box width="33%" justifyContent="center" display="flex">
                  <Typography text-align="center">
                    {round.date ? moment(round.date).format("MMM YYYY") : " "}
                  </Typography>
                </Box>
                <Box width="33%">
                  <Box textAlign="-webkit-right">
                    {(round.investors || []).map(investor => (
                      <Tooltip
                        title={investor.name}
                        placement="top"
                        key={investor.id}
                        className={classes.logos}
                      >
                        <Avatar
                          src={
                            investor.investorable.type === "person"
                              ? investor.investorable.avatar_urls.thumb
                              : investor.investorable.logo_urls.thumb
                          }
                          alt={investor.name}
                          style={{ height: 24, width: 24 }}
                          variant="square"
                        >
                          <BusinessCenter />
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Box>
                </Box>
              </Box>
            </ListItem>
            {index !== rounds.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      {investors && investors.length !== 0 && (
        <>
          <Typography variant="button">
            <strong>{Messages.Investor}</strong>
          </Typography>
          <Grid container spacing={2}>
            {investors.map(investor => (
              <Grid key={investor.id} item xs={12} sm={6} md={4}>
                <OutlinedDiv>
                  <Box display="flex" alignItems="center">
                    <Avatar
                      src={
                        investor.investorable.type === "person"
                          ? investor.investorable.avatar_urls.thumb
                          : investor.investorable.logo_urls.thumb
                      }
                      alt={investor.name}
                      style={{ height: 48, width: 48 }}
                      variant="square"
                    >
                      <BusinessCenter fontSize="large" />
                    </Avatar>
                    <Tooltip placement="top" title={investor.name}>
                      <Typography variant="body2" className={classes.investorName} noWrap>
                        <strong>{investor.name}</strong>
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginTop="32px">
                    <SmallButton
                      variant="outlined"
                      component={Link}
                      to={`/investors/${investor.id}/overview`}
                    >
                      {Messages.ShowProfile}
                    </SmallButton>
                  </Box>
                </OutlinedDiv>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

const CompanyFundingTab = props => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/create`} component={AddRoundDialog} />
        <Route path={`${path}/:roundId`} component={EditRoundDialog} />
      </Switch>
      <FundingTab {...props} />
    </>
  );
};

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      rounds: companyFundingRounds(state),
      total: totalFunding(state),
      loading: companyFundingRoundsLoading(state),
      isCompanyOwned: isCompanyOwned({ companyId: id, state: "accepted" })(state),
      investors: companyInvestors(state),
      investorsLoading: companyInvestorsLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        { deleteCompanyFundingRound, getCompanyFundingRounds, getCompanyInvestors, getCompany },
        dispatch
      )
    })
  ),
  withState("open", "setOpen", false),
  withHandlers({
    onConfirmClick: ({ ok, ko, setOpen, actions, match }) => async ({ roundId }) => {
      try {
        await actions.deleteCompanyFundingRound({ roundId });
        await actions.getCompany({ id: match.params.id });
        ok(Messages.DeleteRoundSuccess);
        setOpen(false);
      } catch (err) {
        ko(Messages.DeleteRoundError);
      }
    }
  }),
  lifecycle({
    async componentDidMount() {
      const { actions, match } = this.props;
      await actions.getCompanyFundingRounds({ id: match.params.id });
      await actions.getCompanyInvestors({ id: match.params.id });
    }
  }),
  branch(({ loading, investorsLoading }) => loading && investorsLoading, renderNothing)
);

export default enhance(CompanyFundingTab);
