/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { last } from "lodash";
import * as types from "./types";

const axiosWrapper = jsonApiData => ({ data: { ...jsonApiData } });

const news = [
  {
    title: "Decreto liquidità, tutte le misure per imprese, startup e PMI",
    url:
      "https://startupitalia.eu/126119-20200407-decreto-liquidita-tutte-le-misure-imprese-startup-pmi",
    published: "2020-04-07 09:27:41 UTC",
    author: "Carlo Terzano",
    categories: [
      "Economia Digitale",
      "coronavirus",
      "crisi",
      "decreto liquidità",
      "giuseppe conte"
    ],
    summary: "Dai prestiti garantiti al rinvio delle tasse, ecco le novità del decreto liquidità",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/decreto-liquidità.jpg"
  },
  {
    title: "Baretta, bonus da 600 euro alle partite Iva sarà portato a 800",
    url:
      "https://startupitalia.eu/126111-20200407-baretta-bonus-600-euro-alle-partite-iva-sara-portato-800",
    published: "2020-04-07 08:50:37 UTC",
    author: "Carlo Terzano",
    categories: [
      "Lavoro",
      "bonus da 600 euro",
      "coronavirus",
      "Covid-19",
      "crisi",
      "Partite IVA",
      "Pier Paolo Baretta"
    ],
    summary:
      'Quanto ai prestiti alle realtà produttive previsti nel decreto imprese "saranno erogati a tassi simbolici dello 0,2-0,3%, e i finanziamenti potranno essere usati solo se si investe in Italia"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/BARRETTA.jpg"
  },
  {
    title: "Coronavirus | Il bazooka di Abe: in Giappone pronti mille miliardi di dollari",
    url:
      "https://startupitalia.eu/126102-20200407-coronavirus-bazooka-abe-giappone-pronti-mille-miliardi-dollari",
    published: "2020-04-07 08:45:50 UTC",
    author: "Alessandro Di Stefano",
    categories: ["Economia Digitale", "coronavirus", "giappone"],
    summary: "Un pacchetto senza precedenti. E forse non basterà",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/EQu61z_UEAAEsnM.jpeg"
  },
  {
    title: "Digital transformation: le soluzioni per un’organizzazione efficace",
    url:
      "https://startupitalia.eu/126784-20200414-digital-transformation-le-soluzioni-unorganizzazione-efficace",
    published: "2020-04-14T07:09:26.000Z",
    author: "Alessandro Di Stefano",
    categories: ["Lavoro", "4ward", "Digital Transformation", "smart working"],
    summary: "Intervista a Christian Parmigiani, CEO di 4ward",
    img_url:
      "https://startupitalia.eu/wp-content/uploads/2020/04/hand-using-stylus-pen-for-touching-the-digital-tablet-screen-6336.jpg"
  },
  {
    title: "Coronavirus | Ospedali mobili, con Shellbe nascono reparti smontabili",
    url:
      "https://startupitalia.eu/126269-20200408-coronavirus-ospedali-mobili-shellbe-nascono-reparti-smontabili",
    published: "2020-04-14T07:31:40.000Z",
    author: "Chiara Buratti",
    categories: [
      "Tecnologia",
      "coronavirus",
      "emergenza",
      "Giuseppe Galimberti",
      "moduli",
      "ospedale",
      "Protezione Civile",
      "Regione Marche",
      "Shellbe",
      "terapia subintensiva"
    ],
    summary:
      "Shellbe produce moduli smontabili, riutilizzabili e 100% riciclabili. Due di questi diverranno terapie subintensive in aiuto alla sanità marchigiana",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/Shellbe_3.jpg"
  },
  {
    title: "Macron: “È il momento di rifondare l’Unione europea”",
    url: "https://startupitalia.eu/126779-20200413-macron-momento-rifondare-lunione-europea",
    published: "2020-04-13T19:39:15.000Z",
    author: "Carlo Terzano",
    categories: [
      "Economia Digitale",
      "coronabond",
      "coronavirus",
      "Covid-19",
      "covidbond",
      "Eliseo",
      "Emmanuel Macron",
      "Eurobond",
      "francia",
      "lock down",
      "mes",
      "Unione europea"
    ],
    summary:
      "Il presidente della Repubblica francese, nuovamente in televisione per annunciare il prolungamento del lock down, ha anche mandato segnali a Bruxelles",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/03/macron-1.jpg"
  },
  {
    title: "Bollette: no a distacchi elettricità, gas e acqua fino al 3 maggio",
    url:
      "https://startupitalia.eu/126772-20200413-bollette-domestiche-no-distacchi-elettricita-gas-acqua-al-3-maggio",
    published: "2020-04-13T19:23:38.000Z",
    author: "Carlo Terzano",
    categories: [
      "Economia Digitale",
      "ARERA",
      "bollette",
      "coronavirus",
      "Covid-19",
      "Stefano Besseghini"
    ],
    summary:
      "Prorogata la misura straordinaria per la pandemia del Coronavirus. Ma attenzione: le utenze non sono sospese, vanno pagate",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/bollette-B.jpg"
  },
  {
    title: "Covid 19, bonus 600 euro alle partite IVA: entro venerdì accreditato a tutti",
    url:
      "https://startupitalia.eu/126764-20200413-covid-19-bonus-600-euro-alle-partite-iva-entro-venerdi-accreditato-tutti",
    published: "2020-04-13T16:33:12.000Z",
    author: "Alessandro Di Stefano",
    categories: ["Lavoro", "bonus 600 euro", "coronavirus", "lockdown"],
    summary: "A comunicarlo è stato il Governo in una nota",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/03/600-euro.jpg"
  },
  {
    title: "Covid-19, in Europa non c’è accordo nemmeno sulla Fase 2",
    url:
      "https://startupitalia.eu/126749-20200413-covid-19-europa-non-ce-accordo-nemmeno-sulla-fase-2",
    published: "2020-04-13T15:19:54.000Z",
    author: "Carlo Terzano",
    categories: ["Sicurezza", "coronavirus", "Covid-19", "fase 2", "Unione europea"],
    summary:
      "Prime aperture in Spagna già da oggi. In Austria domani riaprono i piccoli negozi, in Danimarca si tornerà a scuola mercoledì. In Francia si attende il discorso di questa sera di Macron",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/quali-attività-riaprono.jpg"
  },
  {
    title: "Andrea Bocelli, concerto in streaming per la speranza. Noi lo seguiremo",
    url:
      "https://startupitalia.eu/126728-20200412-andrea-bocelli-concerto-streaming-la-speranza-lo-seguiremo",
    published: "2020-04-12T13:56:14.000Z",
    author: "Carlo Terzano",
    categories: [
      "Lifestyle",
      "Andrea Bocelli",
      "coronavirus",
      "Covid-19",
      "Duomo di Milano",
      "milano"
    ],
    summary:
      "Si terrà questa sera, nel cuore di una Milano acciaccata ma che non vede l'ora di ripartire, il concerto del tenore",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/andrea-bocelli.jpg"
  },
  {
    title: "Von der Leyen: “Rischio di disgregazione dell’Ue mai così alto”",
    url:
      "https://startupitalia.eu/126719-20200412-von-der-leyen-rischio-disgregazione-dellue-mai-cosi-alto",
    published: "2020-04-12T13:08:34.000Z",
    author: "Carlo Terzano",
    categories: [
      "Sicurezza",
      "Commissione europea",
      "coronavirus",
      "Covid-19",
      "Unione europea",
      "Ursula von der Leyen"
    ],
    summary:
      'E agli anziani: "Meglio che limitino i contatti fino alla scoperta di una cura", che però difficilmente sarà pronta "prima di fine anno"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/von-der-leyen-int.jpg"
  },
  {
    title: "Il Papa striglia l’Ue: Non perda occasione di dare prova di solidarietà",
    url:
      "https://startupitalia.eu/126710-20200412-papa-striglia-lue-non-perda-occasione-dare-prova-solidarieta",
    published: "2020-04-12T12:15:49.000Z",
    author: "Carlo Terzano",
    categories: [
      "Sicurezza",
      "coronavirus",
      "Covid-19",
      "covidbond",
      "Eurobond",
      "mes",
      "Papa",
      "Papa Francesco"
    ],
    summary:
      'Il Pontefice entra nel dibattito politico e sembra persino alludere agli Eurobond: "Si ricorra a soluzioni innovative"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/papa-2.jpg"
  },
  {
    title: "Covid-19, Tokyo si svuota. Sei webcam per restare informati",
    url:
      "https://startupitalia.eu/126697-20200411-covid-19-tokyo-si-svuota-webcam-restare-informati",
    published: "2020-04-11T17:31:57.000Z",
    author: "Carlo Terzano",
    categories: ["Sicurezza", "coronavirus", "Covid-19", "giappone", "tokyo", "webcam"],
    summary: "I cittadini si autoisolano, la capitale è meno vitale, ma ciò non ferma il contagio",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/tokyo-shibuya.jpg"
  },
  {
    title: "Mattarella: “Sarà per molti una Pasqua di solitudine. Anche per me”",
    url: "https://startupitalia.eu/126700-20200411-mattarella-sara-molti-pasqua-solitudine-anche",
    published: "2020-04-11T17:45:25.000Z",
    author: "Carlo Terzano",
    categories: [
      "Sicurezza",
      "Capo dello Stato",
      "coronavirus",
      "Covid-19",
      "pasqua",
      "presidente della repubblica",
      "Quirinale",
      "Sergio Mattarella"
    ],
    summary:
      '"In questi giorni intravediamo anche la concreta possibilità di superare questa emergenza. Ma non possiamo fermarci proprio adesso"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/mattarella.jpg"
  }
];
const myNews = [
  {
    title: "Covid-19, in Europa non c’è accordo nemmeno sulla Fase 2",
    url:
      "https://startupitalia.eu/126749-20200413-covid-19-europa-non-ce-accordo-nemmeno-sulla-fase-2",
    published: "2020-04-13T15:19:54.000Z",
    author: "Carlo Terzano",
    categories: ["Sicurezza", "coronavirus", "Covid-19", "fase 2", "Unione europea"],
    summary:
      "Prime aperture in Spagna già da oggi. In Austria domani riaprono i piccoli negozi, in Danimarca si tornerà a scuola mercoledì. In Francia si attende il discorso di questa sera di Macron",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/quali-attività-riaprono.jpg"
  },
  {
    title: "Von der Leyen: “Rischio di disgregazione dell’Ue mai così alto”",
    url:
      "https://startupitalia.eu/126719-20200412-von-der-leyen-rischio-disgregazione-dellue-mai-cosi-alto",
    published: "2020-04-12T13:08:34.000Z",
    author: "Carlo Terzano",
    categories: [
      "Sicurezza",
      "Commissione europea",
      "coronavirus",
      "Covid-19",
      "Unione europea",
      "Ursula von der Leyen"
    ],
    summary:
      'E agli anziani: "Meglio che limitino i contatti fino alla scoperta di una cura", che però difficilmente sarà pronta "prima di fine anno"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/von-der-leyen-int.jpg"
  },
  {
    title: "Il Papa striglia l’Ue: Non perda occasione di dare prova di solidarietà",
    url:
      "https://startupitalia.eu/126710-20200412-papa-striglia-lue-non-perda-occasione-dare-prova-solidarieta",
    published: "2020-04-12T12:15:49.000Z",
    author: "Carlo Terzano",
    categories: [
      "Sicurezza",
      "coronavirus",
      "Covid-19",
      "covidbond",
      "Eurobond",
      "mes",
      "Papa",
      "Papa Francesco"
    ],
    summary:
      'Il Pontefice entra nel dibattito politico e sembra persino alludere agli Eurobond: "Si ricorra a soluzioni innovative"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/papa-2.jpg"
  },
  {
    title: "Covid-19, Tokyo si svuota. Sei webcam per restare informati",
    url:
      "https://startupitalia.eu/126697-20200411-covid-19-tokyo-si-svuota-webcam-restare-informati",
    published: "2020-04-11T17:31:57.000Z",
    author: "Carlo Terzano",
    categories: ["Sicurezza", "coronavirus", "Covid-19", "giappone", "tokyo", "webcam"],
    summary: "I cittadini si autoisolano, la capitale è meno vitale, ma ciò non ferma il contagio",
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/tokyo-shibuya.jpg"
  },
  {
    title: "Mattarella: “Sarà per molti una Pasqua di solitudine. Anche per me”",
    url: "https://startupitalia.eu/126700-20200411-mattarella-sara-molti-pasqua-solitudine-anche",
    published: "2020-04-11T17:45:25.000Z",
    author: "Carlo Terzano",
    categories: [
      "Sicurezza",
      "Capo dello Stato",
      "coronavirus",
      "Covid-19",
      "pasqua",
      "presidente della repubblica",
      "Quirinale",
      "Sergio Mattarella"
    ],
    summary:
      '"In questi giorni intravediamo anche la concreta possibilità di superare questa emergenza. Ma non possiamo fermarci proprio adesso"',
    img_url: "https://startupitalia.eu/wp-content/uploads/2020/04/mattarella.jpg"
  }
];

const people = [
  {
    id: "1",
    type: "people",
    attributes: {
      avatar_url: null,
      name: "Paolo Barberis",
      company: "Nana Bianca",
      role: "Founder",
      place: "Firenze, Italia"
    }
  },
  {
    id: "2",
    type: "people",
    attributes: {
      avatar_url: "https://via.placeholder.com/125",
      name: "Jacopo Marello",
      company: "Nana Bianca",
      role: "Founder",
      place: "Firenze, Italia"
    }
  },
  {
    id: "3",
    type: "people",
    attributes: {
      avatar_url: null,
      name: "Alessandro Sordi",
      company: "Nana Bianca",
      role: "Founder",
      place: "Firenze, Italia"
    }
  }
];
const startups = [
  {
    id: "1",
    type: "company",
    attributes: {
      name: "Popsa holdings ltd",
      legal_nature: "Gruppo europeo di interesse economico",
      tax_code: "00191829993",
      pv: "CT",
      municipality: "E irlanda del no london",
      start_date_in_the_startup_section: "2017-07-20",
      start_date_in_the_business_register: "2017-07-20",
      start_date_of_the_actual_exercise_of_the_activity: "2017-06-27",
      ateco_2007: "1813",
      sector: "Industria/artigianato",
      activity: "C 18 stampa e riproduzione di supporti registrati",
      website: "www.popsa.com",
      region: "Sicilia",
      activity_section: "C attivita' manifatturiere",
      class_of_employees: "",
      class_of_product_value: "",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: null,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2017-07-20",
      female_prevalence: null,
      youth_prevalence: null,
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "2",
    type: "company",
    attributes: {
      name: "Optodinamica s.r.l.",
      legal_nature: "Societa' a responsabilita' limitata",
      tax_code: "00921870945",
      pv: "IS",
      municipality: "Pesche",
      start_date_in_the_startup_section: "2016-10-26",
      start_date_in_the_business_register: "2017-03-22",
      start_date_of_the_actual_exercise_of_the_activity: "2014-02-13",
      ateco_2007: "32504",
      sector: "Industria/artigianato",
      activity: "C 32 altre industrie manifatturiere",
      website: "www.optodinamica.com",
      region: "Molise",
      activity_section: "C attivita' manifatturiere",
      class_of_employees: "D",
      class_of_product_value: "D",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 3,
      research_and_development_expenses: null,
      labor_force_with_qualifications: null,
      possession_of_patents: "yes",
      date_of_declaration: "2017-06-28",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "3",
    type: "company",
    attributes: {
      name: "Futureb societa' a responsabilita' limitata",
      legal_nature: "Societa' a responsabilita' limitata",
      tax_code: "00929950947",
      pv: "IS",
      municipality: "Monteroduni",
      start_date_in_the_startup_section: "2015-07-27",
      start_date_in_the_business_register: "2015-01-02",
      start_date_of_the_actual_exercise_of_the_activity: "2015-03-09",
      ateco_2007: "4645",
      sector: "Commercio",
      activity: "G 46 commercio all'ingrosso (escluso quello di autoveicoli e di m",
      website: "www.mookaje.com",
      region: "Molise",
      activity_section: "G commercio all'ingrosso e al dettaglio; riparazione di autove...",
      class_of_employees: "C",
      class_of_product_value: "B",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 4,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2018-10-31",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "4",
    type: "company",
    attributes: {
      name: "Novus studios s.r.l.",
      legal_nature: "Societa' a responsabilita' limitata",
      tax_code: "00933570947",
      pv: "IS",
      municipality: "Isernia",
      start_date_in_the_startup_section: "2017-09-28",
      start_date_in_the_business_register: "2015-04-03",
      start_date_of_the_actual_exercise_of_the_activity: "2015-10-22",
      ateco_2007: "6202",
      sector: "Servizi",
      activity: "J 62 produzione di software, consulenza informatica e attivita' c",
      website: "www.novusstudios.it",
      region: "Molise",
      activity_section: "J servizi di informazione e comunicazione",
      class_of_employees: "A",
      class_of_product_value: "A",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 3,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2019-10-03",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "5",
    type: "company",
    attributes: {
      name: "Modaimpresa srl",
      legal_nature: "Societa' a responsabilita' limitata",
      tax_code: "00938990942",
      pv: "IS",
      municipality: "Miranda",
      start_date_in_the_startup_section: "2016-05-27",
      start_date_in_the_business_register: "2015-12-02",
      start_date_of_the_actual_exercise_of_the_activity: "2015-12-22",
      ateco_2007: "14131",
      sector: "Industria/artigianato",
      activity: "C 14 confezione di articoli di abbigliamento; confezione di artic",
      website: "www.modaimpresa.com",
      region: "Molise",
      activity_section: "C attivita' manifatturiere",
      class_of_employees: "D",
      class_of_product_value: "D",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 8,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2019-09-17",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "6",
    type: "company",
    attributes: {
      name: "Diba bio innovative farm - societa' agricola a responsabilita' limitata",
      legal_nature: "Societa' a responsabilita' limitata",
      tax_code: "00941460941",
      pv: "IS",
      municipality: "Macchiagodena",
      start_date_in_the_startup_section: "2017-01-23",
      start_date_in_the_business_register: "2016-02-22",
      start_date_of_the_actual_exercise_of_the_activity: "2016-03-01",
      ateco_2007: "015",
      sector: "Agricoltura/pesca",
      activity: "A 01 coltivazioni agricole e produzione di prodotti animali, cacc",
      website: "www.saporidelmatese.it",
      region: "Molise",
      activity_section: "A agricoltura, silvicoltura e pesca",
      class_of_employees: "A",
      class_of_product_value: "A",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 3,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2017-01-23",
      female_prevalence: "no",
      youth_prevalence: "high",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "7",
    type: "company",
    attributes: {
      name: "Dive different s.r.l.",
      legal_nature: "Societa' a responsabilita' limitata",
      tax_code: "00941500944",
      pv: "IS",
      municipality: "Isernia",
      start_date_in_the_startup_section: "2016-08-23",
      start_date_in_the_business_register: "2016-02-23",
      start_date_of_the_actual_exercise_of_the_activity: "2016-08-03",
      ateco_2007: "14191",
      sector: "Industria/artigianato",
      activity: "C 14 confezione di articoli di abbigliamento; confezione di artic",
      website: "www.divedifferent.it",
      region: "Molise",
      activity_section: "C attivita' manifatturiere",
      class_of_employees: "",
      class_of_product_value: "A",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 3,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2016-08-23",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "8",
    type: "company",
    attributes: {
      name: "New co. green societa' a responsabilita' limitata semplificata",
      legal_nature: "Societa' a responsabilita' limitata semplificata",
      tax_code: "00945860948",
      pv: "IS",
      municipality: "Pozzilli",
      start_date_in_the_startup_section: "2016-07-22",
      start_date_in_the_business_register: "2016-07-22",
      start_date_of_the_actual_exercise_of_the_activity: "2016-07-06",
      ateco_2007: "63113",
      sector: "Servizi",
      activity: "J 63 attivita' dei servizi d'informazione e altri servizi informa",
      website: "www.newcogreen.it",
      region: "Molise",
      activity_section: "J servizi di informazione e comunicazione",
      class_of_employees: "",
      class_of_product_value: "B",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 1,
      research_and_development_expenses: null,
      labor_force_with_qualifications: "yes",
      possession_of_patents: null,
      date_of_declaration: "2019-02-08",
      female_prevalence: null,
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "9",
    type: "company",
    attributes: {
      name: "Creazioni moda societa' cooperativa",
      legal_nature: "Societa' cooperativa",
      tax_code: "00955620943",
      pv: "IS",
      municipality: "Pettoranello del molise",
      start_date_in_the_startup_section: "2017-10-03",
      start_date_in_the_business_register: "2017-08-17",
      start_date_of_the_actual_exercise_of_the_activity: "2017-09-15",
      ateco_2007: "829999",
      sector: "Servizi",
      activity: "N 82 attivita' di supporto per le funzioni d'ufficio e altri serv",
      website: "www.laboratorimoda.it",
      region: "Molise",
      activity_section: "N noleggio, agenzie di viaggio, servizi di supporto alle impre...",
      class_of_employees: "B",
      class_of_product_value: "A",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: null,
      research_and_development_expenses: "yes",
      labor_force_with_qualifications: null,
      possession_of_patents: null,
      date_of_declaration: "2019-10-14",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  },
  {
    id: "10",
    type: "company",
    attributes: {
      name: "Welfarein - srls",
      legal_nature: "Societa' a responsabilita' limitata semplificata",
      tax_code: "00956340947",
      pv: "IS",
      municipality: "Isernia",
      start_date_in_the_startup_section: "2017-09-27",
      start_date_in_the_business_register: "2017-09-19",
      start_date_of_the_actual_exercise_of_the_activity: "2017-09-26",
      ateco_2007: "702209",
      sector: "Servizi",
      activity: "M 70 attivita' di direzione aziendale e di consulenza gestionale",
      website: "www.welfareinsamex.it",
      region: "Molise",
      activity_section: "M attivita' professionali, scientifiche e tecniche",
      class_of_employees: "A",
      class_of_product_value: "A",
      high_technological_value: "no",
      social_vocation: null,
      class_of_social_capital: 2,
      research_and_development_expenses: null,
      labor_force_with_qualifications: "yes",
      possession_of_patents: null,
      date_of_declaration: "2019-01-07",
      female_prevalence: "no",
      youth_prevalence: "no",
      prevalence_foreign: "unknow",
      tag_list: [],
      startup_stage: 0,
      product_and_service_stage: 0,
      team_stage: 0,
      selling_areas: [],
      selling_channels: [],
      business_model: ""
    }
  }
];
const investors = [
  {
    id: "1",
    type: "investor",
    attributes: {
      name: "Nana Bianca",
      investments_number: 72,
      exits_number: 3,
      city: "Firenze"
    }
  }
];

export default {
  [types.GET_PROFILE]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "profile",
        attributes: {
          first_name: "Samuele",
          last_name: "Di Rito",
          birthday: "1984-09-04",
          place: "Firenze",
          last_company_name: "T\u0026O Consulting",
          last_job_title: "Lead Developer",
          created_at: "2020-04-20T11:45:38.200Z",
          updated_at: "2020-04-20T12:38:02.761Z",
          avatar_urls: { original: null, small: null, thumb: null }
        },
        relationships: {
          interests: { data: [{ id: "1", type: "interest" }] },
          user: { data: { id: "2", type: "user" } }
        }
      },
      included: [
        {
          id: "1",
          type: "interest",
          attributes: {
            name: "Interesse #1",
            tag_list: [],
            created_at: "2020-04-20T11:46:05.960Z",
            cover_image_url: null
          }
        },
        {
          id: "2",
          type: "user",
          attributes: {
            email: "samuele.dirito@gmail.com",
            created_at: "2020-04-20T11:45:08.430Z",
            updated_at: "2020-04-21T08:07:44.413Z"
          },
          relationships: {
            profile: { data: { id: "1", type: "profile" } },
            companies: { data: [{ id: "1", type: "company" }] }
          }
        },
        {
          id: "1",
          type: "company",
          attributes: {
            name: "Popsa holdings ltd",
            legal_nature: "Gruppo europeo di interesse economico",
            tax_code: "00191829993",
            pv: "CT",
            municipality: "E irlanda del no london",
            start_date_in_the_startup_section: "2017-07-20",
            start_date_in_the_business_register: "2017-07-20",
            start_date_of_the_actual_exercise_of_the_activity: "2017-06-27",
            ateco_2007: "1813",
            sector: "Industria/artigianato",
            activity: "C 18 stampa e riproduzione di supporti registrati",
            website: "www.popsa.com",
            region: "Sicilia",
            activity_section: "C attivita' manifatturiere",
            class_of_employees: "",
            class_of_product_value: "",
            high_technological_value: "no",
            social_vocation: null,
            class_of_social_capital: null,
            research_and_development_expenses: "yes",
            labor_force_with_qualifications: null,
            possession_of_patents: null,
            date_of_declaration: "2017-07-20",
            female_prevalence: null,
            youth_prevalence: null,
            prevalence_foreign: "unknow",
            tag_list: [],
            activity_and_expenses_description: null,
            professional_relationships_description: null,
            intellectual_property_description: null,
            startup_stage: null,
            product_and_service_stage: null,
            team_stage: null,
            selling_areas: null,
            selling_channels: null,
            business_model: null
          }
        }
      ]
    }),
  [types.CREATE_PROFILE]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "profile",
        attributes: {
          first_name: "Mario",
          last_name: "Rossi",
          birthday: "1993-04-03T13:11:26.506Z",
          place: "Firenze",
          last_company_name: "Super aziendona",
          last_job_title: "CEO",
          avatar_urls: {
            original: null,
            small: null,
            thumb: null
          },
          created_at: "1985-08-16T15:56:04.558Z",
          updated_at: "1940-04-15T20:37:16.532Z"
        },
        relationships: {
          interests: {
            data: null
          }
        }
      }
    }),
  [types.UPDATE_PROFILE]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "profile",
        attributes: {
          first_name: "Mario",
          last_name: "Rossi",
          birthday: "1993-04-03T13:11:26.506Z",
          place: "Firenze",
          last_company_name: "Super aziendona",
          last_job_title: "CEO",
          avatar_urls: {
            original: null,
            small: null,
            thumb: null
          },
          created_at: "1985-08-16T15:56:04.558Z",
          updated_at: "1940-04-15T20:37:16.532Z"
        },
        relationships: {
          interests: {
            data: [
              {
                id: "1",
                type: "interest"
              },
              {
                id: "3",
                type: "interest"
              }
            ]
          }
        },
        included: [
          {
            id: "1",
            type: "interest",
            attributes: {
              name: "Sicurezza",
              tag_list: ["voluptate aute", "dolor et non"],
              cover_image_url: "https://via.placeholder.com/300x128",
              created_at: "1951-01-05T23:18:00.684Z",
              updated_at: "1984-03-27T07:02:33.578Z"
            }
          },
          {
            id: "3",
            type: "interest",
            attributes: {
              name: "Gossip",
              tag_list: ["aliquip amet", "velit sed cupidatat aute"],
              cover_image_url: "https://via.placeholder.com/300x128",
              created_at: "2017-07-25T22:43:55.601Z",
              updated_at: "1942-05-05T16:54:31.01Z"
            }
          }
        ]
      }
    }),
  [types.UPDATE_AVATAR]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "profile",
        attributes: {
          first_name: "Mario",
          last_name: "Rossi",
          birthday: "1993-04-03T13:11:26.506Z",
          place: "Firenze",
          last_company_name: "Super aziendona",
          last_job_title: "CEO",
          avatar_urls: {
            original: null,
            small: null,
            thumb: null
          },
          created_at: "1985-08-16T15:56:04.558Z",
          updated_at: "1940-04-15T20:37:16.532Z"
        },
        relationships: {
          interests: {
            data: [
              {
                id: "1",
                type: "interest"
              },
              {
                id: "3",
                type: "interest"
              }
            ]
          }
        }
      },
      included: [
        {
          id: "1",
          type: "interest",
          attributes: {
            name: "Coronavirus",
            tag_list: ["voluptate aute", "dolor et non"],
            cover_image_url: "https://via.placeholder.com/300x128",
            created_at: "1951-01-05T23:18:00.684Z",
            updated_at: "1984-03-27T07:02:33.578Z"
          }
        },
        {
          id: "3",
          type: "interest",
          attributes: {
            name: "Gossip",
            tag_list: ["aliquip amet", "velit sed cupidatat aute"],
            cover_image_url: "https://via.placeholder.com/300x128",
            created_at: "2017-07-25T22:43:55.601Z",
            updated_at: "1942-05-05T16:54:31.01Z"
          }
        }
      ]
    }),
  [types.GET_INTERESTS]: () =>
    axiosWrapper({
      data: [
        {
          id: "1",
          type: "interest",
          attributes: {
            name: "Sicurezza",
            tag_list: ["voluptate aute", "dolor et non"],
            cover_image_url: "https://via.placeholder.com/300x128",
            created_at: "1951-01-05T23:18:00.684Z",
            updated_at: "1984-03-27T07:02:33.578Z"
          }
        },
        {
          id: "2",
          type: "interest",
          attributes: {
            name: "Tecnologia",
            tag_list: [],
            cover_image_url: "https://via.placeholder.com/300x128",
            created_at: "2017-07-25T22:43:55.601Z",
            updated_at: "1942-05-05T16:54:31.01Z"
          }
        },
        {
          id: "3",
          type: "interest",
          attributes: {
            name: "Gossip",
            tag_list: ["aliquip amet", "velit sed cupidatat aute"],
            cover_image_url: "https://via.placeholder.com/300x128",
            created_at: "2017-07-25T22:43:55.601Z",
            updated_at: "1942-05-05T16:54:31.01Z"
          }
        }
      ],
      meta: {
        total: 3
      }
    }),
  [types.GET_NEWS]: requestConfig => {
    const { page, per_page: perPage } = requestConfig.params;

    return axiosWrapper({
      data: news.slice((page - 1) * perPage, page * perPage),
      meta: {
        total: news.length,
        current_page: page,
        per_page: perPage
      }
    });
  },
  [types.GET_MORE_NEWS]: requestConfig => {
    const { page, per_page: perPage } = requestConfig.params;

    return axiosWrapper({
      data: news.slice((page - 1) * perPage, page * perPage),
      meta: {
        total: news.length,
        current_page: page,
        per_page: perPage
      }
    });
  },
  [types.GET_MY_NEWS]: requestConfig => {
    const { page, per_page: perPage } = requestConfig.params;

    return axiosWrapper({
      data: myNews.slice((page - 1) * perPage, page * perPage),
      meta: {
        total: myNews.length,
        current_page: page,
        per_page: perPage
      }
    });
  },
  [types.GET_MORE_MY_NEWS]: requestConfig => {
    const { page, per_page: perPage } = requestConfig.params;

    return axiosWrapper({
      data: myNews.slice((page - 1) * perPage, page * perPage),
      meta: {
        total: myNews.length,
        current_page: page,
        per_page: perPage
      }
    });
  },
  [types.SEARCH]: requestConfig => {
    const { page, per_page } = requestConfig.params;

    if (requestConfig.params["q[no_results_eq]"])
      return axiosWrapper({ data: [], meta: { total: 0, page, per_page } });

    const type = last(requestConfig.url.split("/"));

    let data = [];

    switch (type) {
      case "people":
        data = people;
        break;
      case "companies":
        data = startups;
        break;
      case "investors":
        data = investors;
        break;
      default:
        break;
    }

    return axiosWrapper({
      data: page && per_page ? data.slice((page - 1) * per_page, page * per_page) : data,
      meta: { total: data.length, page, per_page }
    });
  },
  [types.GET_COMPANY]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "company",
        attributes: {
          name: "Popsa holdings ltd",
          legal_nature: "Gruppo europeo di interesse economico",
          tax_code: "00191829993",
          pv: "CT",
          municipality: "E irlanda del no london",
          start_date_in_the_startup_section: "2017-07-20",
          start_date_in_the_business_register: "2017-07-20",
          start_date_of_the_actual_exercise_of_the_activity: "2017-06-27",
          ateco_2007: "1813",
          sector: "Industria/artigianato",
          activity: "C 18 stampa e riproduzione di supporti registrati",
          website: "www.popsa.com",
          region: "Sicilia",
          activity_section: "C attivita' manifatturiere",
          class_of_employees: "A",
          class_of_product_value: "B",
          high_technological_value: "no",
          social_vocation: 4,
          class_of_social_capital: null,
          research_and_development_expenses: "yes",
          labor_force_with_qualifications: null,
          possession_of_patents: null,
          date_of_declaration: "2017-07-20",
          female_prevalence: "yes",
          youth_prevalence: null,
          prevalence_foreign: "unknow",
          tag_list: ["Influencer marketing", "Advertising"],
          activity_and_expenses_description: "asdf",
          professional_relationships_description: "asdf",
          intellectual_property_description: "asdf",
          startup_stage: 0,
          product_and_service_stage: 0,
          team_stage: 0,
          selling_areas: [0, 1],
          selling_channels: [1, 2],
          business_model: "Business model",
          profile_completion_percent: 45
        }
      }
    }),
  [types.CREATE_COMPANY_OWNER]: () =>
    axiosWrapper({
      data: {
        id: "2",
        type: "company_owner",
        attributes: {
          created_at: "2020-04-21T13:19:42.226Z",
          updated_at: "2020-04-21T13:19:42.226Z"
        },
        relationships: {
          user: { data: { id: "10", type: "user" } },
          company: { data: { id: "2", type: "company" } }
        }
      }
    }),
  [types.UPDATE_COMPANY]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "company",
        attributes: {
          name: "Popsa holdings ltd",
          legal_nature: "Gruppo europeo di interesse economico",
          tax_code: "00191829993",
          pv: "CT",
          municipality: "E irlanda del no london",
          start_date_in_the_startup_section: "2017-07-20",
          start_date_in_the_business_register: "2017-07-20",
          start_date_of_the_actual_exercise_of_the_activity: "2017-06-27",
          ateco_2007: "1813",
          sector: "Industria/artigianato",
          activity: "C 18 stampa e riproduzione di supporti registrati",
          website: "www.popsa.com",
          region: "Sicilia",
          activity_section: "C attivita' manifatturiere",
          class_of_employees: "",
          class_of_product_value: "",
          high_technological_value: "no",
          social_vocation: null,
          class_of_social_capital: null,
          research_and_development_expenses: "yes",
          labor_force_with_qualifications: null,
          possession_of_patents: null,
          date_of_declaration: "2017-07-20",
          female_prevalence: null,
          youth_prevalence: null,
          prevalence_foreign: "unknow",
          tag_list: [],
          activity_and_expenses_description: null,
          professional_relationships_description: null,
          intellectual_property_description: null,
          startup_stage: null,
          product_and_service_stage: null,
          team_stage: null,
          selling_areas: null,
          selling_channels: null,
          business_model: null
        }
      }
    }),
  [types.UPDATE_COMPANY_CULTURE]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "company_culture",
        attributes: {
          title: "Can can",
          description: "Ciao",
          video_url: "https://www.youtube.com/watch?v=y6120QOlsfU",
          created_at: "2020-04-21T09:43:55.247Z",
          updated_at: "2020-04-21T13:15:51.666Z"
        },
        relationships: { company: { data: { id: "1", type: "company" } } }
      }
    }),
  [types.GET_COMPANY_CULTURE]: () =>
    axiosWrapper({
      data: {
        id: "1",
        type: "company_culture",
        attributes: {
          title: "Questo è il titolo",
          description: "Questa è la descrizione",
          video_url: "https://www.youtube.com/watch?v=LkCNJRfSZBU",
          created_at: "2020-04-21T09:43:55.247Z",
          updated_at: "2020-04-21T12:55:35.424Z"
        },
        relationships: { company: { data: { id: "1", type: "company" } } }
      }
    }),
  [types.GET_COMPANY_FUNDING_ROUNDS]: () =>
    axiosWrapper({
      data: [
        {
          id: "1",
          type: "company_funding_rounds",
          attributes: {
            amount: { cents: 10000, currency_iso: "EUR" },
            status_color: "#FF0000",
            status_description: "Closed",
            date: "2019-11-01"
          }
        },
        {
          id: "2",
          type: "company_funding_rounds",
          attributes: {
            amount: { cents: 10000, currency_iso: "EUR" },
            status_color: "#FF0000",
            status_description: "Closed",
            date: "2019-11-01"
          }
        }
      ]
    })
};
