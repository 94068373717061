import qs from "query-string";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { isAuthenticated } from "../../ducks/selectors";

const PrivateRoute = ({ ...props }) => {
  const { pathname } = useLocation();
  const isAuth = useSelector(isAuthenticated);

  useEffect(() => {
    // console.log(`PRIVATE - ${window.location.href} - ${window.location.pathname}`);

    window.gtag('send', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname            
    });
  });

  if (isAuth) {
    const invitationToken = localStorage.getItem("INVITATION_TOKEN");

    if (invitationToken && pathname !== "/profile/new") {
      localStorage.removeItem("INVITATION_TOKEN");
      return (
        <Redirect
          to={qs.stringifyUrl({
            url: "/invite-company-people",
            query: { invitation_token: invitationToken }
          })}
        />
      );
    }

    return <Route {...props} />;
  }
  return <Redirect to="/" />;
};

export default PrivateRoute;
