
import React, { useEffect } from "react";
import { Route } from "react-router-dom";

const PublicRoute = ({ ...props }) => {

  useEffect(() => {
    // console.log(`PUBLIC - ${window.location.href} - ${window.location.pathname}`);

    window.gtag('send', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname            
    });
  });

  return <Route {...props} />;
};

export default PublicRoute;
