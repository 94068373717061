import axios from "axios";
import config from "../config";

const axiosInstance = axios.create({ baseURL: config.apiBaseUrl });

axiosInstance.interceptors.response.use(response => {
  if (!response.headers.authorization) {
    return response;
  }
  return {
    ...response,
    data: { ...response.data, token: response.headers.authorization }
  };
});

export default axiosInstance;
