/* eslint-disable react/jsx-props-no-spreading */
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Avatar } from "@material-ui/core";
import React from "react";

const AvatarDataProvider = ({ isSquare, ...props }) => (
  <DataTypeProvider
    {...props}
    formatterComponent={({ value, row }) => {
      return (
        <Avatar
          src={(row.avatar_urls || {}).small || value}
          variant={isSquare ? "rounded" : "circle"}
        >
          {row.first_name && row.last_name && `${row.first_name[0]}${row.last_name[0]}`}
        </Avatar>
      );
    }}
  />
);

export default AvatarDataProvider;
