/* eslint-disable react/jsx-props-no-spreading */
import MomentUtils from "@date-io/moment";
import { makeStyles, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isNull } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl.macro";

const useStyles = makeStyles(() => ({
  field: {
    margin: "8px 0"
  }
}));

const handleChange = ({ form, field }) => date => {
  form.setFieldTouched(field.name, true, true);
  return isNull(date)
    ? form.setFieldValue(field.name, null, true)
    : form.setFieldValue(field.name, moment.utc(date.format("YYYY-MM-DD")), true);
};

const DatePickerField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];
  const error = Boolean(currentError) && Boolean(form.touched[field.name]);

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={classes.field}
          margin="dense"
          name={field.name}
          value={field.value}
          error={error}
          onBlur={field.onBlur}
          onChange={handleChange({ form, field })}
          {...other}
        />
      </MuiPickersUtilsProvider>
      {error && (
        <Typography variant="body2" color="error">
          <FormattedMessage id={currentError} />
        </Typography>
      )}
    </>
  );
};

DatePickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({})
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldError: PropTypes.func.isRequired
  }).isRequired
};

export default DatePickerField;
