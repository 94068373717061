import { compose, withProps, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getCompanyFundingRounds,
  createCompanyFundingRound,
  getCompanyInvestors,
  getCompany
} from "../ducks/actions";
import RoundDialog from "./RoundDialog";
import * as Messages from "./Messages";

const enhance = compose(
  connect(undefined, dispatch => ({
    actions: bindActionCreators(
      { getCompanyFundingRounds, createCompanyFundingRound, getCompanyInvestors, getCompany },
      dispatch
    )
  })),
  withProps({
    title: Messages.AddRound
  }),
  withHandlers({
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/companies/${id}/funding`);
    },
    onSubmit: ({ actions, match, history, ok, ko }) => async values => {
      try {
        await actions.createCompanyFundingRound({
          companyId: match.params.id,
          round: {
            ...values,
            investor_ids: (values.investor_ids || []).map(investor => investor.value)
          }
        });
        await actions.getCompanyFundingRounds({ id: match.params.id });
        await actions.getCompanyInvestors({ id: match.params.id });
        await actions.getCompany({ id: match.params.id });
        history.push(`/companies/${match.params.id}/funding`);
        ok(Messages.RoundCreateSuccess);
      } catch (err) {
        ko(Messages.RoundCreateError);
      }
    }
  })
);

export default enhance(RoundDialog);
