/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, FormGroup, FormLabel } from "@material-ui/core";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React from "react";
import TextField from "../formik/TextField";

const AutoForm = (
  { formikProps = {}, fieldConfiguration = { default: {} }, label },
  classes = {}
) => {
  const applyClassFromFieldName = name => {
    return clsx(classes.field, classes[name] || "");
  };

  return (
    <Formik {...formikProps}>
      {({ dirty, isSubmitting, isValid }) => (
        <Form>
          {Object.keys(fieldConfiguration).map(group => (
            <React.Fragment key={group}>
              {group !== "default" && <h4>{fieldConfiguration[group].label}</h4>}
              <div className={classes.form}>
                {fieldConfiguration[group].fields.map(
                  ({
                    label,
                    name,
                    component = TextField,
                    hidden = false,
                    required = false,
                    ...field
                  }) =>
                    !hidden && (
                      <FormGroup key={group + name} className={applyClassFromFieldName(name)}>
                        <FormLabel required={required}>{label}</FormLabel>
                        <Field component={component} name={name} {...field} />
                      </FormGroup>
                    )
                )}
              </div>
            </React.Fragment>
          ))}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!dirty || !isValid || isSubmitting}
            >
              {label}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AutoForm;
