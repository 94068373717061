/* eslint-disable react/prop-types */
import React from "react";
import { LinearProgress } from "@material-ui/core";
import { compose, lifecycle, branch, renderComponent } from "recompose";
import { connect } from "react-redux";
import { useRouteMatch, withRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  companyCultureLoading,
  companyCulture,
  isInvestorOwned,
  company
} from "../ducks/selectors";
import { getCompanyCulture } from "../ducks/actions";
import CultureTab from "../shared/components/CultureTab";
import CultureEditDialog from "./CultureEditDialog";

const CompanyCultureTab = ({ companyCulture, isInvestorOwned }) => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${path}/edit`} component={CultureEditDialog} />
      </Switch>
      <CultureTab companyCulture={companyCulture} isCompanyOwned={isInvestorOwned} />
    </>
  );
};

const enhance = compose(
  withRouter,
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      company: company(state),
      companyCulture: companyCulture(state),
      companyCultureLoading: companyCultureLoading(state),
      isInvestorOwned: isInvestorOwned({ investorId: id, state: "accepted" })(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getCompanyCulture }, dispatch) })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions, company } = this.props;
      await actions.getCompanyCulture({ id: company.id });
    }
  }),
  branch(
    ({ companyCultureLoading }) => companyCultureLoading,
    renderComponent(() => <LinearProgress />)
  )
);

export default enhance(CompanyCultureTab);
