import { Box, Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import numeral from "numeral";
import React, { useEffect } from "react";
import HubspotForm from "react-hubspot-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCompanies } from "../ducks/actions";
import { getCompaniesQuery } from "../ducks/selectors";
import { PublicLayout } from "../layouts/public";
import SearchUpBaseForm from "../shared/components/SearchUpBaseForm";
import ContentCard from "./ContentCard";
import * as Messages from "./Messages";
import NewsSection from "./NewsSection";

const useStyles = makeStyles(theme => ({
  logo: {
    marginTop: "4em",
    marginBottom: "1em"
  },
  searchUpBase: { padding: "2rem 0 1rem 0" },
  cardsWrapper: {
    margin: "6rem 0",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem"
    }
  },
  imageResponsive: {
    display: "block",
    width: "100%"
  },
  bigImageResponsive: {
    width: "100%",
    padding: "1rem 0 1rem 4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0 1rem 0"
    }
  },
  fullWidthBand: {
    position: "relative",
    margin: "6rem 0 6rem 0",
    paddingBottom: "2rem",
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
  },
  logoItaly: {
    display: "inline-block",
    backgroundColor: theme.palette.secondary.main,
    padding: "1rem",
    border: "8px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "100%",
    position: "absolute",
    top: "-72px",
    left: "50%",
    marginLeft: "-72px",
    "& img": {
      width: "96px",
      height: "96px"
    }
  },
  hugeText: {
    marginTop: "3rem",
    fontSize: "18rem",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "6rem"
    }
  },
  centerText: { textAlign: "center" }
}));

const HomePage = () => {
  const styles = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies({ startups: true, page: 1, perPage: 1 }));
  }, [dispatch]);

  const {
    data: {
      meta: { total }
    }
  } = useSelector(getCompaniesQuery);

  return (
    <PublicLayout>
      <Container>
        <Grid container justify="center">
          <img
            className={styles.logo}
            src="/images/upbase/logo-home.svg"
            alt="UpBase"
            width={108}
          />
        </Grid>

        <Grid container justify="center">
          <Grid item xs={12} sm={10} lg={8}>
            <Typography className={styles.centerText} variant="h1">
              {Messages.Title}
            </Typography>

            <Box className={styles.searchUpBase}>
              <SearchUpBaseForm />
            </Box>

            <Typography className={styles.centerText} variant="subtitle1">
              {Messages.SubTitle}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" spacing={3} className={styles.cardsWrapper}>
          <Grid item sm={12} md={6}>
            <ContentCard
              image="/images/upbase/upbase-startup.svg"
              title={Messages.CardStartupTitle}
              text={Messages.CardStartupText}
              buttonText={Messages.CardStartupButtonText}
              to="/login"
              isLink
            />
          </Grid>
          {/* <Grid item sm={12} md={4}>
            <ContentCard
              image="/images/upbase/upbase-talent-icon.svg"
              title={Messages.CardTalentTitle}
              text={Messages.CardTalentText}
              buttonText={Messages.CardTalentButtonText}
              to="/search?type=people"
              isLink
            />
          </Grid> */}
          <Grid item sm={12} md={6}>
            <ContentCard
              image="/images/upbase/upbase-investor.svg"
              title={Messages.CardInvestorTitle}
              text={Messages.CardInvestorText}
              buttonText={Messages.CardInvestorButtonText}
              to="/login"
              isLink
            />
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={3}>
          <Box clone order={{ xs: 3, sm: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h3">{Messages.LetYourStartUpFly}</Typography>
              <br />
              <Typography variant="subtitle1">{Messages.BestTool}</Typography>
              <br />
              <Button variant="contained" color="primary" component={Link} to="/search?page=1&per_page=10&type=all">
                {Messages.StartNow}
              </Button>
            </Grid>
          </Box>
          <Box clone order={{ xs: 2, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <img
                className={styles.bigImageResponsive}
                src="/images/upbase/decollare-startup-illustration.svg"
                alt="Fai decollare la tua startup"
              />
            </Grid>
          </Box>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <img
              className={styles.bigImageResponsive}
              src="/images/upbase/scopri-talenti-illustration.svg"
              alt="Scopri i talenti dietro alle migliori startup italiane"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{Messages.DiscoverTalent}</Typography>
            <br />
            <Typography variant="subtitle1">{Messages.ManyTalent}</Typography>
            <br />
            <Button variant="contained" color="primary" component={Link} to="/search?page=1&per_page=10&type=people">
              {Messages.FindTheBestTalent}
            </Button>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={3}>
          <Box clone order={{ xs: 3, sm: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h3">
                {Messages.FindYourNextUnicorn}
                <img src="/images/upbase/unicorn-illustration.png" height="48" alt="Unicorn" />
              </Typography>
              <br />
              <Typography variant="subtitle1">{Messages.DiscoverNewProfiles}</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/search?page=1&per_page=10&type=investors"
              >
                {Messages.FindInvestments}
              </Button>
            </Grid>
          </Box>
          <Box clone order={{ xs: 2, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <img
                className={styles.bigImageResponsive}
                src="/images/upbase/trova-unicorn-illustration.svg"
                alt="Trova il tuo prossimo Unicorno"
              />
            </Grid>
          </Box>
        </Grid>
      </Container>

      <Container maxWidth={false} className={styles.fullWidthBand}>
        <div className={styles.logoItaly}>
          <img src="/images/upbase/illustrazione-italia.svg" alt="Italy" />
        </div>

        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} className={styles.fullWidthBandWrapper}>
            <Typography variant="h1" className={styles.hugeText} color="secondary">
              {numeral(total).format("0,0")}
            </Typography>
            <Typography variant="h6" color="secondary" className={styles.centerText}>
              {Messages.StartUpSubscribed}
            </Typography>
            <br />
          </Grid>
        </Grid>
        {/* TODO: riabilitare quando verrà richiesto  */}
        {/* 
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <img
              className={styles.imageResponsive}
              src="//via.placeholder.com/120x32"
              alt="placeholder"
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary" className={styles.centerText}>
              {Messages.Ecosystem}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            <img
              className={styles.imageResponsive}
              src="/images/upbase/startupitalia-logo.png"
              alt="StartupItalia"
            />
          </Grid>
          <Grid item xs={3}>
            <img
              className={styles.imageResponsive}
              src="/images/upbase/crunchbase-logo.png"
              alt="Crunchbase"
            />
          </Grid>
        </Grid> */}
      </Container>
      <Container>
        <Typography variant="h5">
          {Messages.HubSpotTitle}
        </Typography>
        <br/>
        <br/>
        <HubspotForm
          region="na1"
          portalId="2177334"
          formId="b1da78f4-627a-4dc0-b8aa-908c337cd18a"
        />
      </Container>

      <Container>
        <Box marginTop={4}>
          <Typography variant="h5" paragraph>
            {Messages.LastNews}
          </Typography>
        </Box>
        <NewsSection />
      </Container>
    </PublicLayout>
  );
};

export default HomePage;
