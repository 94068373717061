import { Box, Divider, Grid, List, ListItem, styled, Typography } from "@material-ui/core";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon
} from "@material-ui/icons";
import { isEmpty } from "lodash";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useRouteMatch } from "react-router-dom";
import { capitalClassMap } from "../utils";
import FabButton from "./FabButton";
import * as SharedMessages from "./Messages";

const CheckedIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: 4
}));
const UncheckedIcon = styled(RadioButtonUncheckedIcon)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginRight: 4
}));

const Title = styled(Typography)({});
Title.defaultProps = { variant: "body2" };

const Text = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(4)
}));
Text.defaultProps = { variant: "body2" };

const CheckBox = ({ checked, label }) => (
  <Box display="flex" alignItems="center" mr={2}>
    {checked ? <CheckedIcon /> : <UncheckedIcon />}
    <Typography variant="body2">
      <strong>{label}</strong>
    </Typography>
  </Box>
);

const ClassBox = ({ label, value, valueLabelMapper = ({ value }) => value }) => {
  const renderValue = valueLabelMapper({ value }) || value || "-";
  return (
    <>
      <Title>
        <strong>{label}</strong>
      </Title>
      <Typography variant="body2">{renderValue}</Typography>
    </>
  );
};
const OutlinedList = styled(List)({
  border: "1px solid #ebebeb",
  borderRadius: 4,
  padding: 0
});

const OptionsBox = ({ selected, options }) => (
  <Box m={1}>
    <OutlinedList dense>
      {options.map(({ key, label }, index) => (
        <ListItem key={key} divider={index !== options.length - 1}>
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            {key === selected ? (
              <>
                <CheckedIcon />
                <Title>
                  <strong>{label}</strong>
                </Title>
              </>
            ) : (
              <Title color="textSecondary">{label}</Title>
            )}
          </Box>
          <Divider />
        </ListItem>
      ))}
    </OutlinedList>
  </Box>
);

const SectionContainer = styled("div")({
  marginBottom: "16px"
});

const TextSection = ({ value, label, alwaysShow }) => {
  if (!value && !alwaysShow) {
    return null;
  }

  return (
    <SectionContainer>
      <Title>
        <strong>{label}</strong>
      </Title>
      <Text>{value || SharedMessages.NotDescribed}</Text>
    </SectionContainer>
  );
};

const OverviewTab = ({ company, isCompanyOwned, isStartup }) => {
  const { url } = useRouteMatch();

  return (
    <Box p={2}>
      <FabButton show={isCompanyOwned} to={`${url}/edit`} />
      <TextSection label="Nome progetto" value={company.project_name} />
      <TextSection label="Nome legale" value={company.name} alwaysShow />
      <SectionContainer>
        <Title>
          <strong>Descrizione</strong>
        </Title>
        <ReactMarkdown className="MuiTypography-body2">{company.description}</ReactMarkdown>
      </SectionContainer>
      {isStartup &&
        (company.startup_stage || company.product_and_service_stage || company.team_stage) && (
          <SectionContainer>
            <Grid container spacing={8}>
              {company.startup_stage && (
                <Grid item xs={12} md={4}>
                  <Title>
                    <strong>Stadio della startup</strong>
                  </Title>
                  <OptionsBox
                    selected={company.startup_stage}
                    options={[
                      { key: "idea", label: "Idea" },
                      { key: "development", label: "Sviluppo" },
                      { key: "on_market", label: "Sul mercato" },
                      { key: "scale", label: "Scale" },
                      { key: "exit", label: "Exit" }
                    ]}
                  />
                </Grid>
              )}
              {company.product_and_service_stage && (
                <Grid item xs={12} md={4}>
                  <Title>
                    <strong>Prodotti / Servizio</strong>
                  </Title>
                  <OptionsBox
                    selected={company.product_and_service_stage}
                    options={[
                      { key: "idea", label: "Idea" },
                      { key: "prototype", label: "Prototipo / Beta" },
                      { key: "ready", label: "Pronto" },
                      { key: "first_clients", label: "Primi Clienti" },
                      { key: "on_market", label: "Sul mercato" }
                    ]}
                  />
                </Grid>
              )}
              {company.team_stage && (
                <Grid item xs={12} md={4}>
                  <Title>
                    <strong>Team</strong>
                  </Title>
                  <OptionsBox
                    selected={company.team_stage}
                    options={[
                      { key: "to_be_defined", label: "Da definire" },
                      { key: "technical_ok", label: "Ok lato tecnico" },
                      { key: "business_ok", label: "Ok lato business" },
                      { key: "complete", label: "Completo" }
                    ]}
                  />
                </Grid>
              )}
            </Grid>
          </SectionContainer>
        )}
      <SectionContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Title>
                <strong>Area di vendita</strong>
              </Title>
              <Box display="flex" flexWrap="wrap" m={1}>
                <CheckBox
                  checked={
                    isEmpty(company.selling_areas) ? false : company.selling_areas.includes("0")
                  }
                  label="Italia"
                />
                <CheckBox
                  checked={
                    isEmpty(company.selling_areas) ? false : company.selling_areas.includes("1")
                  }
                  label="Estero"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Title>
                <strong>Canali di vendita</strong>
              </Title>
              <Box display="flex" flexWrap="wrap" m={1}>
                <CheckBox
                  checked={
                    isEmpty(company.selling_channels)
                      ? false
                      : company.selling_channels.includes("0")
                  }
                  label="Direttamente"
                />
                <CheckBox
                  checked={
                    isEmpty(company.selling_channels)
                      ? false
                      : company.selling_channels.includes("1")
                  }
                  label="Grande Distribuzione"
                />
                <CheckBox
                  checked={
                    isEmpty(company.selling_channels)
                      ? false
                      : company.selling_channels.includes("2")
                  }
                  label="E-Commerce"
                />
                <CheckBox
                  checked={
                    isEmpty(company.selling_channels)
                      ? false
                      : company.selling_channels.includes("3")
                  }
                  label="Agenti"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </SectionContainer>
      <TextSection label="Business model" value={company.business_model} />
      <SectionContainer>
        <Title>
          <strong>Requisiti di innovazione tecnologica</strong>
        </Title>
        <Box m={1}>
          <Grid container spacing={2}>
            <Grid item>
              <CheckBox
                checked={
                  company.research_and_development_expenses &&
                  company.research_and_development_expenses === "yes"
                }
                label="R&S"
              />
            </Grid>
            <Grid item>
              <CheckBox
                checked={
                  company.labor_force_with_qualifications &&
                  company.labor_force_with_qualifications === "yes"
                }
                label="Team Qualificato"
              />
            </Grid>
            <Grid item>
              <CheckBox
                checked={company.possession_of_patents && company.possession_of_patents === "yes"}
                label="Proprietà Intellettuale"
              />
            </Grid>
          </Grid>
        </Box>
      </SectionContainer>
      {isStartup && (
        <SectionContainer>
          <Grid container spacing={4}>
            <Grid item>
              <ClassBox
                label="Classe di produzione"
                value={company.class_of_product_value}
                valueLabelMapper={({ value }) => {
                  return {
                    A: "1-100k €",
                    B: "100k-500k €",
                    C: "500k-1M €",
                    D: "1M-2M €",
                    E: "2M-5M €",
                    F: "oltre 5M €"
                  }[value];
                }}
              />
            </Grid>
            <Grid item>
              <ClassBox
                label="Classe di addetti"
                value={company.class_of_employees}
                valueLabelMapper={({ value }) => {
                  return { A: "0-9", B: "10-49", C: "50-249", D: "250 e più" }[value];
                }}
              />
            </Grid>
            <Grid item>
              <ClassBox
                label="Classe di capitale"
                value={company.class_of_social_capital}
                valueLabelMapper={({ value }) => {
                  return capitalClassMap[value > 5 ? 5 : value];
                }}
              />
            </Grid>
          </Grid>
        </SectionContainer>
      )}
      <SectionContainer>
        <Title>
          <strong>Composizione Team a prevalenza</strong>
        </Title>
        <Box m={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CheckBox checked={company.female_prevalence === "yes"} label="Femminile" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CheckBox checked={company.youth_prevalence === "yes"} label="Giovanile" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CheckBox checked={company.prevalence_foreign === "yes"} label="Straniera" />
            </Grid>
          </Grid>
        </Box>
      </SectionContainer>

      <TextSection
        label="Attività e spese in ricerca e sviluppo"
        value={company.activity_and_expenses_description}
      />
      <TextSection
        label="Relazioni professionali con incubatori, investitori, università ricerca"
        value={company.professional_relationships_description}
      />
      <TextSection
        label="Elenco diritti privativa su proprietà industriale, intellettuale"
        value={company.intellectual_property_description}
      />
    </Box>
  );
};
export default OverviewTab;
