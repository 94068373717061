import { Box, Button } from "@material-ui/core";
import { Field, Form, Formik, useFormikContext } from "formik";
import React from "react";
import IndustrySelectField from "../shared/formik/IndustrySelectField";
import TagsSelectField from "../shared/formik/TagsSelectField";
import { useQueryParams } from "../shared/hooks";
import * as Messages from "./Messages";

const PersonFilterForm = () => {
  const { isValid } = useFormikContext();
  return (
    <Form>
      <Field
        component={IndustrySelectField}
        label={Messages.Industry}
        name="industryId"
        noOptionsMessage={Messages.NoOptions}
        placeholder={Messages.Industry}
      />
      <Field
        component={TagsSelectField}
        label={Messages.Tags}
        name="tags"
        closeMenuOnSelect={false}
        noOptionsMessage={Messages.NoOptions}
        placeholder={Messages.MultiPlaceholder}
        byIndustry
      />
      <Box marginTop={2}>
        <Button variant="contained" color="primary" fullWidth disabled={!isValid} type="submit">
          {Messages.Filter}
        </Button>
      </Box>
    </Form>
  );
};

const PersonFilter = () => {
  const { queryParams, mergeParams } = useQueryParams();

  const handleSubmit = values => {
    mergeParams(values);
  };
  return (
    <Formik
      initialValues={{ industryId: queryParams.industryId || "", tags: queryParams.tags || [] }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <PersonFilterForm />
    </Formik>
  );
};

export default PersonFilter;
