/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import {
  Box,
  LinearProgress
} from "@material-ui/core";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import { compose, lifecycle, branch, renderComponent, withHandlers } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InfiniteScroll from "react-infinite-scroller";
import {
  myNews,
  myNewsLoading,
  news,
  newsLoading,
  newsTotal,
  myNewsTotal
} from "../ducks/selectors";
import { getMyNews, getNews, getMoreNews, getMoreMyNews } from "../ducks/actions";
import * as Messages from "./Messages";
import NewsCard from "./NewsCard";

const FeedRss = ({ items, total, onLoad }) => {

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={onLoad}
      hasMore={onLoad ? items.length < total : false}
      loader={<Loader />}
    >
      {items.map(({ title, author, summary, categories, published, url, img_url: imgUrl }) => (
        <NewsCard title={title} author={author} summary={summary} categories={categories} published={published} url={url} imgUrl={imgUrl} />
      ))}
    </InfiniteScroll>
  );
};

const Loader = () => (
  <Box m={2} key={moment().toString()}>
    <LinearProgress />
  </Box>
);

const enhanceMyFeedRssPreview = compose(
  connect(
    state => ({
      items: myNews(state),
      total: myNewsTotal(state),
      loading: myNewsLoading(state),
      onLoad: false
    }),
    dispatch => ({ actions: bindActionCreators({ getMyNews, getMoreMyNews }, dispatch) })
  ),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getMyNews({ source: "all", page: 1, perPage: 10 });
    }
  }),
  branch(({ loading }) => loading, renderComponent(Loader)),
  branch(
    ({ items }) => items.length === 0,
    renderComponent(() => (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SpeakerNotesOffIcon fontSize="large" />
          {Messages.NoNews}
        </Box>
      </>
    ))
  )
);

export const MyFeedRssPreview = enhanceMyFeedRssPreview(FeedRss);

const enhanceMyFeedRss = compose(
  connect(
    state => ({
      items: myNews(state),
      total: myNewsTotal(state),
      loading: myNewsLoading(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getMyNews, getMoreMyNews }, dispatch) })
  ),
  withHandlers({
    onLoad: ({ actions }) => page => {
      actions.getMoreMyNews({ source: "all", page, perPage: 10 });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getMyNews({ source: "all", page: 1, perPage: 10 });
    }
  }),
  branch(({ loading }) => loading, renderComponent(Loader)),
  branch(
    ({ items }) => items.length === 0,
    renderComponent(() => (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SpeakerNotesOffIcon fontSize="large" />
          {Messages.NoNews}
        </Box>
      </>
    ))
  )
);

export const MyFeedRss = enhanceMyFeedRss(FeedRss);

const enhanceAllFeedRssPreview = compose(
  connect(
    state => ({
      items: news(state),
      total: newsTotal(state),
      loading: newsLoading(state),
      onLoad: false
    }),
    dispatch => ({ actions: bindActionCreators({ getNews, getMoreNews }, dispatch) })
  ),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getNews({ source: "all", page: 1, perPage: 10 });
    }
  }),
  branch(({ loading }) => loading, renderComponent(Loader)),
  branch(
    ({ items }) => items.length === 0,
    renderComponent(() => (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SpeakerNotesOffIcon fontSize="large" />
          {Messages.NoNews}
        </Box>
      </>
    ))
  )
);
export const AllFeedRssPreview = enhanceAllFeedRssPreview(FeedRss);

const enhanceAllFeedRss = compose(
  connect(
    state => ({
      items: news(state),
      total: newsTotal(state),
      loading: newsLoading(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getNews, getMoreNews }, dispatch) })
  ),
  withHandlers({
    onLoad: ({ actions }) => page => {
      actions.getMoreNews({ source: "all", page, perPage: 10 });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getNews({ source: "all", page: 1, perPage: 10 });
    }
  }),
  branch(({ loading }) => loading, renderComponent(Loader)),
  branch(
    ({ items }) => items.length === 0,
    renderComponent(() => (
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SpeakerNotesOffIcon fontSize="large" />
          {Messages.NoNews}
        </Box>
      </>
    ))
  )
);
export const AllFeedRss = enhanceAllFeedRss(FeedRss);