import { LinearProgress, styled } from "@material-ui/core";
import _ from "lodash";
import qs from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getPeopleQuery } from "../ducks/selectors";
import PersonTable from "../shared/components/PersonTable";
import NoResults from "./NoResults";
import SeeMore from "./SeeMore";

const LoaderContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const PersonList = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { page, per_page: perPage, ...queryParams } = qs.parse(search, { arrayFormat: "bracket" });

  const onSetPage = value => {
    history.push({
      search: qs.stringify(
        {
          ...queryParams,
          page: value + 1,
          per_page: perPage || 10
        },
        { arrayFormat: "bracket" }
      )
    });
  };

  const onSetPerPage = value => {
    history.push(
      { search: qs.stringify({ ...queryParams, page: 1, per_page: value }) },
      { arrayFormat: "bracket" }
    );
  };

  const {
    data: {
      data: people,
      meta: { total }
    },
    loading: peopleLoading
  } = useSelector(getPeopleQuery);

  if (peopleLoading) {
    return (
      <LoaderContainer>
        <LinearProgress />
      </LoaderContainer>
    );
  }

  if (_.isEmpty(people)) {
    return <NoResults />;
  }

  return (
    <>
      <PersonTable
        people={people}
        isPaginated
        page={+page - 1}
        perPage={+perPage}
        total={total}
        onSetPage={onSetPage}
        onSetPerPage={onSetPerPage}
      />
      <SeeMore />
    </>
  );
};

export default PersonList;
