import React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import Content from "./Content";
import Title from "./Title";

const StyledDiv = styled("div")({
  width: "100%",
  borderRadius: 5,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 0 10px 0 rgba(70,66,66,0.25)",
  marginBottom: 32
});

const Body = ({ children }) => <StyledDiv>{children}</StyledDiv>;

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

Body.displayName = "AuthBody";

Body.Content = Content;
Body.Title = Title;
export default Body;
