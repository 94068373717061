/* eslint-disable react/jsx-props-no-spreading */
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Avatar } from "@material-ui/core";
import { BusinessCenter } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";

const LogoDataProvider = ({ altKey, fallbackIcon: FallbackIcon, ...props }) => (
  <DataTypeProvider
    {...props}
    formatterComponent={({ value, row }) => {
      return (
        <Avatar src={value} alt={row[altKey] || value} {...props} variant="rounded">
          <FallbackIcon />
        </Avatar>
      );
    }}
  />
);

LogoDataProvider.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  altKey: PropTypes.string.isRequired,
  fallbackIcon: PropTypes.string
};

LogoDataProvider.defaultProps = {
  fallbackIcon: BusinessCenter
};

export default LogoDataProvider;
