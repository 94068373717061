import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  IconButton,
  styled,
  Toolbar
} from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContextMenu from "../ContextMenu";

const PersonIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));

const Layout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Container>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Link to="/home">
                <img src="/images/upbase/logo-home.svg" alt="UpBase" height="36px" />
              </Link>
              <PersonIconButton size="small" onClick={handleClick}>
                <PersonIcon />
              </PersonIconButton>
              <ContextMenu anchorEl={anchorEl} onClose={handleClose} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container>{children}</Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default Layout;
