import { styled, Typography } from "@material-ui/core";
import React from "react";

const SelectableImg = styled("img")(({ selected }) => ({
  width: "100%",
  height: 128,
  borderRadius: 5,
  // "-webkit-filter": !selected && "grayscale(100%)",
  // filter: !selected && "grayscale(100%)",
  opacity: selected ? 0.9 : 1,
  border: selected && "4px solid transparent",
  "&:hover": !selected
    ? {
        opacity: 0.9,
        border: "4px solid transparent"
        // "-webkit-filter": "grayscale(100%)",
        // filter: "grayscale(100%)"
      }
    : {}
  // : { "-webkit-filter": "none", filter: "none" }
}));

const ImageBackgroundDiv = styled("div")({
  width: "100%",
  height: 128,
  position: "relative",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.18)",
  borderRadius: 5,
  objectFit: "cover",
  background: "linear-gradient(45.21deg, #BD0310 0%, #95093B 100%)"
});

const OverTypography = styled(Typography)({
  position: "absolute",
  color: "#FFF",
  fontFamily: "aktiv-grotesk",
  fontSize: 16,
  fontWeight: 900,
  letterSpacing: 0,
  lineHeight: "20px",
  zIndex: 1,
  margin: "0 auto",
  top: 96,
  left: 32
});

const ImageCheckboxField = ({ form, field, label, imageSrc }) => {
  return (
    <ImageBackgroundDiv onClick={() => form.setFieldValue(field.name, !field.value, true)}>
      <SelectableImg selected={field.value} src={imageSrc} />
      <OverTypography>{label}</OverTypography>
    </ImageBackgroundDiv>
  );
};

export default ImageCheckboxField;
