/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */

import React from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyle = makeStyles(theme => ({
  card: {},
  cardMedia: {
    height: "96px",
    width: "auto",
    margin: "0 auto 8px auto"
  },
  cardTitle: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem"
    }
  },
  cardText: {
    textAlign: "center",
    height: "96px",
    fontSize: "1.3rem",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      height: "128px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
      height: "auto"
    }
  },
  cardButton: {
    margin: "8px auto"
  }
}));

const ContentCard = ({ image, title, text, buttonText, to, isLink, onClick }) => {
  const styles = useStyle();

  return (
    <Card className={styles.card}>
      <CardActionArea component={Link} to={to}>
        <CardMedia
          className={styles.cardMedia}
          component="img"
          alt={title}
          image={image}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" className={styles.cardTitle}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="p"
            className={styles.cardText}
          >
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          className={styles.cardButton}
          variant="contained"
          color="primary"
          onClick={onClick}
          component={isLink && Link}
          to={to}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ContentCard;
