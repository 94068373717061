import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const MyUpBase = <FormattedMessage id="private.MyUpBase" defaultMessage="My UpBase" />;
export const AllNews = <FormattedMessage id="private.AllNews" defaultMessage="Tutte le News" />;
export const YourInterests = (
  <FormattedMessage id="private.YourInterests" defaultMessage="I tuoi interessi" />
);
export const SavedSearch = (
  <FormattedMessage id="private.SavedSearch" defaultMessage="Ricerche salvate" />
);
export const Explore = <FormattedMessage id="private.Explore" defaultMessage="Esplora" />;
export const SearchStartUps = (
  <FormattedMessage id="private.SearchStartUps" defaultMessage="Cerca Startup" />
);
export const SearchInvestors = (
  <FormattedMessage id="private.SearchInvestors" defaultMessage="Cerca Investitori" />
);
export const SearchPeople = (
  <FormattedMessage id="private.SearchPeople" defaultMessage="Cerca Persone" />
);
export const NoNews = <FormattedMessage id="private.NoNews" defaultMessage="Non ci sono novità." />;
export const Favourites = <FormattedMessage id="private.Favourites" defaultMessage="Preferiti" />;
export const Startups = <FormattedMessage id="home.Startups" defaultMessage="Startup" />;
export const People = <FormattedMessage id="home.People" defaultMessage="Persone" />;
export const Investors = <FormattedMessage id="home.Investors" defaultMessage="Investitori" />;
export const MyStartups = <FormattedMessage id="home.MyStartups" defaultMessage="Le mie startup" />;
export const CompanySearchTitle = (
  <FormattedMessage id="company-search.CompanySearchTitle" defaultMessage="Cerca la tua Satartup" />
);
export const InsertPiva = (
  <FormattedMessage id="company-search.InsertPiva" defaultMessage="Inserisci partita IVA" />
);
export const MissingPiva = (
  <FormattedMessage id="company-search.MissingPiva" defaultMessage="Partita iva non trovata" />
);
export const SearchStartup = (
  <FormattedMessage id="company-search.SearchStartup" defaultMessage="Cerca Startup" />
);
export const SearchStartupFailed = (
  <FormattedMessage
    id="company-search.SearchStartupFailed"
    defaultMessage="La ricerca della Startup non è andata a buon fine"
  />
);
export const AcceptRequest = (
  <FormattedMessage
    id="company-search.AcceptRequest"
    defaultMessage="Conferma la tua proprietà attraverso l'email che ti è stata inviata"
  />
);
export const RequestOwnership = (
  <FormattedMessage id="private.RequestOwnership" defaultMessage="Richiedi proprietà" />
);
export const GoToStartup = (
  <FormattedMessage id="private.GoToStartup" defaultMessage="Vai alla Startup" />
);
export const Show = (
  <FormattedMessage id="private.Show" defaultMessage="Mostra" />
);
export const YourProfileCompletedAt = (
  <FormattedMessage id="private.YourProfileCompleteAt" defaultMessage="Il tuo profilo è completo al" />
);
export const GoToEdit = (
  <FormattedMessage id="private.GoToEdit" defaultMessage="Vai a Modifica" />
);
export const PublicInvestorProfile = (
  <FormattedMessage id="private.PublicInvestorProfile" defaultMessage="Profilo pubblico da investitore" />
);
export const WhatsYourJobWelcomeText = (
  <FormattedMessage id="private.WhatsYourJobWelcomeText" defaultMessage="UpBase è la piattaforma di StartupItalia che mette in contatto startup e investitori.
  Completa il tuo profilo per ottimizzare la possibilità di matching con l’investitore.
  Più dettagli inserisci più sarà facile raggiungere il tuo obiettivo. Segui questi
  semplici passaggi e come prima cosa richiedi la proprietà della tua startup." />
);
export const WhatsYourJob = (
  <FormattedMessage id="private.WhatsYourJob" defaultMessage="Di cosa ti occupi?" />
);
export const ImStartupper = (
  <FormattedMessage id="private.ImStartupper" defaultMessage="Sono uno Startupper" />
);
export const ImInvestor = (
  <FormattedMessage id="private.ImInvestor" defaultMessage="Sono un investitore" />
);
export const ImBothStartupperAndInvestor = (
  <FormattedMessage id="private.ImBothStartupperAndInvestor" defaultMessage="Mi occupo di entrambi gli aspetti" />
);