/* eslint-disable react/prop-types */
import { LinearProgress } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { branch, compose, lifecycle, renderComponent } from "recompose";
import { bindActionCreators } from "redux";
import { getCompanyCulture } from "../ducks/actions";
import { companyCulture, companyCultureLoading, isCompanyOwned } from "../ducks/selectors";
import CultureTab from "../shared/components/CultureTab";
import CultureEditDialog from "./CultureEditDialog";

const CompanyCultureTab = ({ companyCulture, isCompanyOwned }) => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${path}/edit`} component={CultureEditDialog} />
      </Switch>
      <CultureTab companyCulture={companyCulture} isCompanyOwned={isCompanyOwned} />
    </>
  );
};

const enhance = compose(
  withRouter,
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      companyCulture: companyCulture(state),
      companyCultureLoading: companyCultureLoading(state),
      isCompanyOwned: isCompanyOwned({ companyId: id, state: "accepted" })(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getCompanyCulture }, dispatch) })
  ),
  lifecycle({
    async componentDidMount() {
      const {
        actions,
        match: {
          params: { id }
        }
      } = this.props;
      await actions.getCompanyCulture({ id });
    }
  }),
  branch(
    ({ companyCultureLoading }) => companyCultureLoading,
    renderComponent(() => <LinearProgress />)
  )
);

export default enhance(CompanyCultureTab);
