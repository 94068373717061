/* eslint-disable react/prop-types */
import React from "react";
import { Typography, styled, Box, makeStyles } from "@material-ui/core";
import moment from "moment";
import { truncate, isNull } from "lodash";
import * as Messages from "./Messages";

const Title = styled(Typography)({
  fontSize: 16
});

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(1)
  }
}));

const About = ({ company }) => {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Title variant="button">
        <strong>
          {Messages.About} {truncate(company.name, { length: 40 })}
        </strong>
      </Title>
      <Typography variant="body2">
        <strong>{Messages.CompanyBuild}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {isNull(company.start_date_of_the_actual_exercise_of_the_activity)
          ? "-"
          : moment(company.start_date_of_the_actual_exercise_of_the_activity, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.StartupSection}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {isNull(company.start_date_in_the_startup_section)
          ? "-"
          : moment(company.start_date_in_the_startup_section, "YYYY-MM-DD").format("DD/MM/YYYY")}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.Name}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {company.name}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.Municipality}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {`${company.municipality} (${company.pv})`}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.FiscalCode}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {company.tax_code || "-"}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.LegalNature}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {company.legal_nature || "-"}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.CompanyEstablished}</strong>
      </Typography>
      <Typography variant="body2">-</Typography>
      <Typography variant="body2">
        <strong>{Messages.AtecoCode}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {company.ateco_2007 || "-"}
      </Typography>
      <Typography variant="body2">
        <strong>{Messages.Sector}</strong>
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {company.sector || "-"}
      </Typography>
    </Box>
  );
};

export default About;
