import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Box, Container, styled, withStyles } from "@material-ui/core";

const Logo = () => <img src="/images/upbase/upbase-logo-login.svg" alt="UpBase" />;

const LogoContainer = styled("div")({
  marginTop: 64,
  marginBottom: 32
});

const Layout = ({ children }) => (
  <Container maxWidth="sm">
    <Box display="flex" alignItems="center" flexDirection="column">
      <LogoContainer>
        <Logo />
      </LogoContainer>
      {children}
    </Box>
  </Container>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

Layout.displayName = "AuthLayout";

const enhance = compose(
  withStyles({
    "@global": {
      body: {
        height: "100%"
      },
      html: {
        height: "100%"
      },
      "#root": {
        minHeight: "100%",
        background: "linear-gradient(45.21deg, #BD0310 0%, #95093B 100%)"
      }
    }
  })
);

export default enhance(Layout);
