import React, { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import AboutUsPage from "./about-us/AboutUsPage";
import { ChangePasswordPage } from "./change-password";
import CompanyPage from "./company";
import { CompanyImport } from "./company-import";
import ConfirmOwnershipPage from "./confirm-ownership";
import { ForgotPasswordPage } from "./forgot-password";
import { HomePage } from "./home";
import InvestorPage from "./investor";
import InvitationPage from "./invitation";
import { AuthorizePage, LoginPage } from "./login";
import Logout from "./logout/Logout";
import Notifier from "./notifications/Notifier";
import PersonPage from "./person/PersonPage";
import { PrivateHomePage } from "./private-home";
import { InterestsPage, MyProfilePage, NewProfilePage, withProfile } from "./profile";
import { SearchPage } from "./search";
import PrivateRoute from "./shared/components/PrivateRoute";
import PublicRoute from "./shared/components/PublicRoute";
import { SignUpPage } from "./sign-up";

const App = () => {
  useEffect(() => {
    document.addEventListener("gesturestart", e => {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", e => {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", e => {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });
  });
  return (
    <>
      <Switch>
        <PrivateRoute path="/home" component={withProfile(PrivateHomePage)} />
        <PrivateRoute path="/profile/me" component={withProfile(MyProfilePage)} />
        <PrivateRoute path="/profile/new" component={NewProfilePage} />
        <PrivateRoute path="/profile/interests" component={withProfile(InterestsPage)} />
        <PrivateRoute path="/confirm-ownership" component={ConfirmOwnershipPage} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/companies/:id" component={withProfile(CompanyPage)} />
        <PrivateRoute path="/profile/company_import" component={withProfile(CompanyImport)} />
        <PublicRoute path="/invite-company-people" component={InvitationPage} />
        <PublicRoute path="/search" component={SearchPage} />
        <PublicRoute path="/investors/:id" component={withProfile(InvestorPage)} />
        <PublicRoute path="/people/:id" component={withProfile(PersonPage)} />
        <PublicRoute path="/authorize" component={AuthorizePage} />
        <PublicRoute path="/change-password" exact component={ChangePasswordPage} />
        <PublicRoute path="/forgot-password" exact component={ForgotPasswordPage} />
        <PublicRoute path="/sign-up" exact component={SignUpPage} />
        <PublicRoute path="/login" exact component={LoginPage} />
        <PublicRoute path="/" exact component={HomePage} />
        <PublicRoute path="/about-us" exact component={AboutUsPage} />
        <Redirect to="/" />
      </Switch>
      <Notifier />
    </>
  );
};

export default App;
