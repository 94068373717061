import { Box, Button } from "@material-ui/core";
import { Field, Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import qs from "query-string";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import IndustrySelectField from "../shared/formik/IndustrySelectField";
import TagsSelectField from "../shared/formik/TagsSelectField";
import * as Messages from "./Messages";

const AllFilterForm = () => {
  const { isValid } = useFormikContext();
  return (
    <Form>
      <Field
        component={IndustrySelectField}
        label={Messages.Industry}
        name="industryId"
        noOptionsMessage={Messages.NoOptions}
        placeholder={Messages.Industry}
      />
      <Field
        component={TagsSelectField}
        label={Messages.Tags}
        name="tags"
        closeMenuOnSelect={false}
        noOptionsMessage={Messages.NoOptions}
        placeholder={Messages.MultiPlaceholder}
        byIndustry
      />
      <Box marginTop={2}>
        <Button variant="contained" color="primary" fullWidth disabled={!isValid} type="submit">
          {Messages.Filter}
        </Button>
      </Box>
    </Form>
  );
};

const AllFilter = () => {
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(search, { arrayFormat: "bracket" });

  const handleSubmit = values => {
    history.push({
      search: qs.stringify(_.omitBy(_.assign(queryParams, values), _.isEmpty), {
        arrayFormat: "bracket"
      })
    });
  };
  return (
    <Formik
      initialValues={{ industryId: queryParams.industryId || "", tags: queryParams.tags || [] }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <AllFilterForm />
    </Formik>
  );
};

export default AllFilter;
