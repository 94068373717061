import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Title = (
  <FormattedMessage
    id="home.title"
    defaultMessage="UpBase trova il match perfetto tra startup e investitori"
  />
);
export const SubTitle = (
  <FormattedMessage
    id="home.sub-title"
    defaultMessage="UpBase è il database dell'ecosistema italiano dell'innovazione digitale."
  />
);

export const CardStartupTitle = (
  <FormattedMessage id="home.card-startup-title" defaultMessage="UpBase Startup" />
);
export const CardStartupText = (
  <FormattedMessage
    id="home.card-startup-text"
    defaultMessage="Crea la pagina della tua startup e trova il match perfetto con clienti, investitori, incubatori e acceleratori del nostro network"
  />
);
export const ComingSoon = <FormattedMessage id="home.coming-soon" defaultMessage="Coming soon" />;
export const CardStartupButtonText = (
  <FormattedMessage id="home.card-startup-button-text" defaultMessage="Trova il tuo Investitore" />
);

export const CardTalentTitle = (
  <FormattedMessage id="home.card-talen-title" defaultMessage="UpBase Talent" />
);
export const CardTalentText = (
  <FormattedMessage
    id="home.card-talent-text"
    defaultMessage="Scopri i team delle migliori startup italiane"
  />
);
export const CardTalentButtonText = (
  <FormattedMessage id="home.card-talent-button-text" defaultMessage="Trova i migliori Talenti" />
);

export const CardInvestorTitle = (
  <FormattedMessage id="home.card-investor-title" defaultMessage="UpBase Investor" />
);
export const CardInvestorText = (
  <FormattedMessage
    id="home.card-investor-text"
    defaultMessage="Trova nuove startup su cui investire grazie alle informazioni sempre aggiornate"
  />
);
export const CardInvestorButtonText = (
  <FormattedMessage
    id="home.card-investor-button-text"
    defaultMessage="Scopri il tuo prossimo Unicorno"
  />
);
export const LetYourStartUpFly = (
  <FormattedMessage id="home.LetYourStartupFly" defaultMessage="Fai decollare la tua startup" />
);
export const BestTool = (
  <FormattedMessage
    id="home.BestTool"
    defaultMessage="UpBase è lo strumento migliore per far conoscere la tua startup agli investitori. Cerca la tua azienda, diventa l'owner e completa il profilo con tutte le informazioni mancanti"
  />
);
export const StartNow = <FormattedMessage id="home.StartNow" defaultMessage="Inizia subito" />;
export const DiscoverTalent = (
  <FormattedMessage id="home.DiscoverTalent" defaultMessage="Trova il tuo investitore ideale" />
);
export const ManyTalent = (
  <FormattedMessage
    id="home.ManyTalent"
    defaultMessage="UpBase aiuta le startup a trovare l'investitore ideale con cui entrare in contatto diretto per poter crescere insieme"
  />
);
export const FindTheBestTalent = (
  <FormattedMessage id="home.FindTheBestTalent" defaultMessage="Trova investitori" />
);
export const FindYourNextUnicorn = (
  <FormattedMessage id="home.FindYourNextUnicorn" defaultMessage="Trova il tuo prossimo Unicorno" />
);
export const DiscoverNewProfiles = (
  <FormattedMessage
    id="home.DiscoverNewProfiles"
    defaultMessage="Scopri, segui e accedi ai profili delle startup italiane più innovative per settore."
  />
);
export const FindInvestments = (
  <FormattedMessage id="home.FindInvestments" defaultMessage="Trova startup" />
);
export const StartUpSubscribed = (
  <FormattedMessage
    id="home.StartUpSubscribed"
    defaultMessage="startup iscritte. Siamo il più grande database italiano"
  />
);
export const Ecosystem = <FormattedMessage id="home.Ecosystem" defaultMessage="Ecosistema" />;
export const LastNews = <FormattedMessage id="home.LastNews" defaultMessage="Ultime news" />;
export const HubSpotTitle = (
  <FormattedMessage
    id="home.HubSpotTitle"
    defaultMessage="Vuoi saperne di più su UpBase? Lascia i tuoi contatti e sarai sempre aggiornato!"
  />
);
