import {
  Button,
  Hidden,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Messages from "./Messages";
import { isCompanyOwned } from "../ducks/selectors";
import withTooltip from "../shared/components/withTooltip";
import CreateOwnerDialog from "../shared/components/CreateOwnerDialog";

const useStyles = makeStyles(theme => ({
  searchListItem: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 240
    }
  }
}));

const CompanyListItem = ({ company }) => {
  const classes = useStyles();

  const [ownershipModalOpen, setOwnershipModalOpen] = useState(false);
  const onOwnershipModalOpen = () => {
    setOwnershipModalOpen(true);
  };
  const onOwnershipModalClose = () => {
    setOwnershipModalOpen(false);
  };

  const isCompanyOwnedAccepted = useSelector(
    isCompanyOwned({ companyId: company.id, state: "accepted" })
  );
  const isCompanyOwnedPending = useSelector(
    isCompanyOwned({ companyId: company.id, state: "pending" })
  );

  return (
    <>
      <ListItem alignItems="center" className={classes.searchListItem}>
        <ListItemText
          primary={<h2>{company.project_name || company.name}</h2>}
        />
        <Hidden smDown>
          {(!isCompanyOwnedAccepted || isCompanyOwnedPending) && (       
            <ListItemSecondaryAction>
              {withTooltip({ visible: isCompanyOwnedPending, title: Messages.AcceptRequest })(
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isCompanyOwnedPending}
                  onClick={onOwnershipModalOpen}
                >
                  { Messages.RequestOwnership }
                </Button>
              )}
            </ListItemSecondaryAction>
          )}
          {isCompanyOwnedAccepted && (
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/companies/${company.id}/overview`}
              >
                { Messages.GoToStartup }
              </Button>
            </ListItemSecondaryAction>
          )}
        </Hidden>
      </ListItem>

      <Hidden mdUp>
        <ListItem alignItems="center" className={classes.searchListItem}>
          <ListItemText />
          {(!isCompanyOwnedAccepted || isCompanyOwnedPending) && (
            <ListItemSecondaryAction>
              {withTooltip({ visible: isCompanyOwnedPending, title: Messages.AcceptRequest })(
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isCompanyOwnedPending}
                  onClick={onOwnershipModalOpen}
                >
                  { Messages.RequestOwnership }
                </Button>
              )}
            </ListItemSecondaryAction>
          )}
          {isCompanyOwnedAccepted && (
            <ListItemSecondaryAction>
              <Button
                component={Link}
                to={`/companies/${company.id}/overview`}
              >
                { Messages.Show }
              </Button>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </Hidden>

      {!isCompanyOwnedAccepted && (
          <CreateOwnerDialog
            open={ownershipModalOpen}
            entity={company}
            onClose={onOwnershipModalClose}
          />
      )}
    </>
  );
};

export default CompanyListItem;
