import { compose, withProps, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getInvestorFundingRounds, createInvestorFundingRound } from "../ducks/actions";
import RoundDialog from "./RoundDialog";
import * as Messages from "./Messages";

const enhance = compose(
  connect(undefined, dispatch => ({
    actions: bindActionCreators({ getInvestorFundingRounds, createInvestorFundingRound }, dispatch)
  })),
  withProps({
    title: Messages.AddRound,
    isEdit: false
  }),
  withHandlers({
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/investors/${id}/funding`);
    },
    onSubmit: ({ actions, match, history, ok, ko }) => async values => {
      try {
        await actions.createInvestorFundingRound({
          investorId: match.params.id,
          companyFundingRound: {
            ...values,
            company_id: values.company.value
          }
        });
        await actions.getInvestorFundingRounds({ investorId: match.params.id });
        history.push(`/investors/${match.params.id}/funding`);
        ok(Messages.RoundCreateSuccess);
      } catch (err) {
        ko(Messages.RoundCreateError);
      }
    }
  })
);

export default enhance(RoundDialog);
