/* eslint-disable no-console */
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { lifecycle } from "recompose";
import { bindActionCreators, compose } from "redux";
import { socialLogin } from "../ducks/actions";
import { auth } from "../firebase";
import { withNotifications } from "../notifications";

const AuthorizePage = () => {
  return null;
};

const enhance = compose(
  withRouter,
  withNotifications,
  connect(undefined, dispatch => ({ actions: bindActionCreators({ socialLogin }, dispatch) })),
  lifecycle({
    async componentDidMount() {
      const { history, location, actions } = this.props;

      try {
        const { custom_token: customToken } = qs.parse(location.search, { arrayFormat: "bracket" });

        await auth.getAuth().signInWithCustomToken(customToken);

        const idToken = await auth.getAuth().currentUser.getIdToken(true);
        const { response } = await actions.socialLogin({ idToken });
        // modifica cristian cau 26.08.2022
        console.log("SOCIAL_LOGIN response",response)
        history.push(response.data.data.profile ? "/home" : "profile/new");
        // history.push(response.data.data.relationships.profile.data ? "/home" : "profile/new");
      } catch (err) {
        history.push("/login");
        console.warn(err);
      }
    }
  })
);

export default enhance(AuthorizePage);
