import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const YourProfile = (
  <FormattedMessage id="profile.YourProfile" defaultMessage="Il tuo profilo" />
);
export const TellAboutYou = (
  <FormattedMessage id="profile.TellAboutYou" defaultMessage="Dicci qualcosa su di te" />
);
export const WhatInterestsYou = (
  <FormattedMessage id="profile.WhatInterestsYou" defaultMessage="Cosa ti interessa?" />
);
export const Next = <FormattedMessage id="profile.Next" defaultMessage="Avanti" />;
export const Save = <FormattedMessage id="profile.Save" defaultMessage="Salva" />;
export const FirstName = <FormattedMessage id="profile.FirstName" defaultMessage="Nome*" />;
export const LastName = <FormattedMessage id="profile.LastName" defaultMessage="Cognome*" />;
export const Birthday = <FormattedMessage id="profile.Birthday" defaultMessage="Data di nascita" />;
export const Place = <FormattedMessage id="profile.Place" defaultMessage="Località*" />;
export const LastCompanyName = (
  <FormattedMessage id="profile.LastCompanyName" defaultMessage="Azienda più recente" />
);
export const LastJobTitle = (
  <FormattedMessage id="profile.LastJobTitle" defaultMessage="Qualifica più recente" />
);
export const GeolocateMyPC = (
  <FormattedMessage id="profile.GeolocateMyPC" defaultMessage="Geolocalizza il mio computer" />
);
export const DigitalEconomy = (
  <FormattedMessage id="profile.DigitalEconomy" defaultMessage="Economia digitale" />
);
export const Education = <FormattedMessage id="profile.Education" defaultMessage="Educazione" />;
export const Work = <FormattedMessage id="profile.Work" defaultMessage="Lavoro" />;
export const Lifestyle = <FormattedMessage id="profile.Lifestyle" defaultMessage="Lifestyle" />;
export const Security = <FormattedMessage id="profile.Security" defaultMessage="Sicurezza" />;
export const Technology = <FormattedMessage id="profile.Technology" defaultMessage="Tecnologia" />;
export const ErrorOnProfileCreation = (
  <FormattedMessage id="profile.ErrorOnProfileCreation" defaultMessage="Profilo non creato" />
);
export const ErrorOnProfileUpdate = (
  <FormattedMessage id="profile.ErrorOnProfileUpdate" defaultMessage="Profilo non aggiornato" />
);
export const Information = (
  <FormattedMessage id="profile.Information" defaultMessage="Informazioni" />
);
export const Interests = <FormattedMessage id="profile.Interests" defaultMessage="Interessi" />;
export const Investor = <FormattedMessage id="profile.Investor" defaultMessage="Investitore" />;
export const Following = <FormattedMessage id="profile.Following" defaultMessage="Following" />;

export const ProfileUpdated = (
  <FormattedMessage id="profile.ProfileUpdated" defaultMessage="Profilo aggiornato" />
);
export const Avatar = <FormattedMessage id="profile.avatar" defaultMessage="Avatar" />;
export const BecomeInvestor = (
  <FormattedMessage id="profile.BecomeInvestor" defaultMessage="Diventa investitore" />
);
export const DeleteInvestor = (
  <FormattedMessage id="profile.DeleteInvestor" defaultMessage="Elimina investitore" />
);
export const DeleteInvestorSuccess = (
  <FormattedMessage
    id="profile.DeleteInvestorSuccess"
    defaultMessage="Non sei più un investitore"
  />
);
export const DeleteInvestorError = (
  <FormattedMessage
    id="profile.DeleteInvestorError"
    defaultMessage="Impossibile eliminare investitore"
  />
);
export const ViewInvestorPage = (
  <FormattedMessage id="profile.ViewInvestorPage" defaultMessage="Visita profilo investitore" />
);
export const InvestorCreateSuccess = (
  <FormattedMessage
    id="profile.InvestorCreateSuccess"
    defaultMessage="Sei diventato un investitore"
  />
);
export const InvestorCreateError = (
  <FormattedMessage id="profile.InvestorCreateError" defaultMessage="Si è verificato un problema" />
);
