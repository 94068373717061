import React from "react";
import { Grid } from "@material-ui/core";
import { AuthLayout, AuthBody } from "../layouts/private";
import NewProfileForm from "./NewProfileForm";
import * as Messages from "./Messages";

const NewProfilePage = () => {
  return (
    <AuthLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <AuthBody>
            <AuthBody.Title>{Messages.TellAboutYou}</AuthBody.Title>
            <NewProfileForm />
          </AuthBody>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default NewProfilePage;
