import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  imageResponsive: {
    display: "block",
    width: "100%",
    height: 240
  },
  prettyTitle: {
    textTransform: "uppercase",
    fontSize: "12px"
  },
  category: {
    color: theme.palette.primary.main,
    fontWeight: 800
  },
  container: {
    height: "100%"
  },
  title: {
    fontSize: "20px",
    fontWeight: 800,
    lineHeight: "normal"
  }
}));

const NewsCard = ({ imgUrl, title, categories, url }) => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <CardActionArea href={url} target="_blank">
        <CardMedia
          alt={title}
          image={imgUrl === "" ? "//via.placeholder.com/300x128" : imgUrl}
          className={classes.imageResponsive}
        />
        <CardContent>
          <Typography className={clsx(classes.prettyTitle, classes.category)} gutterBottom>
            {categories.join(", ")}
          </Typography>
          <Typography gutterBottom className={classes.title}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

NewsCard.propTypes = {
  imgUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired
};

NewsCard.defaultProps = {
  imgUrl: ""
};

export default NewsCard;
