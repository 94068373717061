import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getCompany, getProfile } from "../ducks/actions";
import { getCompanyQuery, getProfileQuery, isCompanyOwned } from "../ducks/selectors";
import TabLayout from "../shared/components/TabLayout";

const CompanyTabLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const {
    data: { data: company },
    loading: companyLoading
  } = useSelector(getCompanyQuery);
  const {
    data: { data: profile },
    loading: profileLoading
  } = useSelector(getProfileQuery);
  const isCompanyOwnedAccepted = useSelector(isCompanyOwned({ companyId: id, state: "accepted" }));
  const isCompanyOwnedPending = useSelector(isCompanyOwned({ companyId: id, state: "pending" }));
  useEffect(() => {
    dispatch(getCompany({ id }));
    dispatch(getProfile());
  }, [dispatch, id]);

  const [tab, setTab] = useState(location.pathname.split("/")[3]);

  const onTabChange = (_, newTab) => {
    setTab(newTab);
    history.push(newTab);
  };

  if (companyLoading || profileLoading) {
    return <LinearProgress />;
  }

  return (
    <TabLayout
      entity={company}
      canRequestOwnership={!isCompanyOwnedAccepted}
      isCompany
      // eslint-disable-next-line camelcase
      showMatching={Boolean(profile.person?.investor_id)}
      isOwnershipPending={isCompanyOwnedPending}
      onTabChange={onTabChange}
      tab={tab}
    >
      {children}
    </TabLayout>
  );
};

export default CompanyTabLayout;
