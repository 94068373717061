/* eslint-disable react/prop-types */
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    minWidth: 160,
    height: 120,
    borderRadius: 8
  },
  prettyTitle: {
    textTransform: "uppercase",
    fontSize: "12px"
  },
  category: {
    color: "#43BDCC",
    fontWeight: 800
  },
  title: {
    fontSize: "20px",
    fontWeight: 800,
    lineHeight: "normal"
  },
  author: {
    color: "#B2B4CA",
    marginTop: 8
  }
}));

const NewsCard = ({ title, author, summary, categories, published, url, imgUrl }) => {
  const classes = useStyles();

  return (
    <Card key={url}>
      <CardActionArea href={url} target="_blank" className={classes.root}>
        <CardMedia className={classes.cover} image={imgUrl} title={title} />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Box display="flex" justifyContent="space-between">
              <Typography className={clsx(classes.prettyTitle, classes.category)}>
                {categories.slice(0, 3).join(", ")}
              </Typography>
              <Typography className={clsx(classes.prettyTitle)}>
                {moment(published)
                  .locale("it")
                  .format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Typography
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: summary }}
            />
            <Typography className={clsx(classes.prettyTitle, classes.author)}>{author}</Typography>
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default NewsCard;
