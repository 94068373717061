import { Button, Grid } from "@material-ui/core";
import { DateRange as DateRangeIcon } from "@material-ui/icons";
import { Field, Form, withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import * as yup from "yup";
import { createProfile } from "../ducks/actions";
import { withNotifications } from "../notifications";
import DatePickerField from "../shared/formik/DatePickerField";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import TextField from "../shared/formik/TextField";
import * as Messages from "./Messages";

const NewProfileForm = ({ isSubmitting, isValid, dirty }) => (
  <Form>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          label={Messages.FirstName}
          name="firstName"
          helperText={false}
          fullWidth
        />
        <Field
          component={TextField}
          label={Messages.LastName}
          name="lastName"
          helperText={false}
          fullWidth
        />
        <Field
          component={DatePickerField}
          label={Messages.Birthday}
          name="birthday"
          format="DD/MM/YYYY"
          inputVariant="outlined"
          disableFuture
          KeyboardButtonProps={{ size: "small" }}
          keyboardIcon={<DateRangeIcon fontSize="small" />}
          variant="inline"
          helperText={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          label={Messages.Place}
          helperText={false}
          name="place"
          fullWidth
        />
        <Field
          component={TextField}
          label={Messages.LastCompanyName}
          name="lastCompanyName"
          fullWidth
        />
        <Field component={TextField} label={Messages.LastJobTitle} name="lastJobTitle" fullWidth />
      </Grid>
    </Grid>
    <Grid container justify="flex-end">
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
        >
          {Messages.Next}
        </Button>
      </Grid>
    </Grid>
  </Form>
);

NewProfileForm.propTypes = FormPropTypes;

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  birthday: yup
    .date()
    .nullable()
    .max(new Date(Date.now()), "La data non può essere nel futuro"),
  place: yup.string().required(),
  lastCompanyName: yup.string(),
  lastJobTitle: yup.string()
});

const enhance = compose(
  withRouter,
  withNotifications,
  connect(undefined, dispatch => ({ actions: bindActionCreators({ createProfile }, dispatch) })),
  withFormik({
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      birthday: null,
      place: "",
      lastCompanyName: "",
      lastJobTitle: ""
    }),
    handleSubmit: async (values, { props: { actions, history, ko } }) => {
      try {
        await actions.createProfile({ profile: values });
        history.push("/profile/interests");
      } catch (err) {
        ko(Messages.ErrorOnProfileCreation);
        console.warn(err);
      }
    },
    validationSchema
  })
);

export default enhance(NewProfileForm);
