import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import * as yup from "yup";
import IndustrySelectField from "../formik/IndustrySelectField";
import TagsSelectField from "../formik/TagsSelectField";
import AutoForm from "./AutoForm";
import FullScreenDialog from "./FullScreenDialog";
import * as Messages from "./Messages";

const formGroup = {
  default: {
    fields: [
      { label: "Nome", name: "first_name", required: true },
      { label: "Cognome", name: "last_name", required: true },
      {
        label: "Settori",
        name: "industryId",
        component: IndustrySelectField
      },
      {
        label: "Lista dei tag",
        name: "tag_list",
        byIndustry: true,
        component: TagsSelectField
      },
      { label: "Email", name: "email" },
      { label: "Website", name: "website" },
      { label: "Link profilo Facebook", name: "facebook_url" },
      { label: "Link profilo Twitter", name: "twitter_url" },
      { label: "Link profilo LinkedIn", name: "linkedin_url" },
      { label: "Link profilo Instragram", name: "instagram_url" },
      { label: "Descrizione", name: "description", multiline: true, rows: 3 },
      {
        label: "Istruzione",
        name: "education",
        multiline: true,
        rows: 3
      },
      {
        label: "What I do",
        name: "what_i_do",
        multiline: true,
        rows: 3
      },
      {
        label: "Achievements",
        name: "achievements",
        multiline: true,
        rows: 3
      },
      {
        label: "Locations",
        name: "locations",
        multiline: true,
        rows: 3
      },
      {
        label: "Markets",
        name: "markets",
        multiline: true,
        rows: 3
      }
    ]
  }
};

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    flex: "0 100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const PersonEditDialog = ({ onClose, person = {}, onSubmit }) => {
  const classes = useStyles();
  const [formikProps] = useState({
    initialValues: person,
    validationSchema: yup.object({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      email: yup
        .string()
        .nullable()
        .email(),
      website: yup.string().nullable(),
      facebook_url: yup
        .string()
        .nullable()
        .matches(
          /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      twitter_url: yup
        .string()
        .nullable()
        .matches(
          /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      linkedin_url: yup
        .string()
        .nullable()
        .matches(
          /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      instagram_url: yup
        .string()
        .nullable()
        .matches(
          /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      description: yup.string().nullable(),
      education: yup.string().nullable(),
      what_i_do: yup.string().nullable(),
      achievements: yup.string().nullable(),
      locations: yup.string().nullable(),
      markets: yup.string().nullable()
    }),
    onSubmit,
    enableReinitialize: true
  });

  return (
    <FullScreenDialog title="Persona" onClose={onClose}>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={formGroup}
        label={Messages.Save}
        classes={classes}
      />
    </FullScreenDialog>
  );
};

export default PersonEditDialog;
