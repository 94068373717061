/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@material-ui/core";
import EditProfileForm from "./EditProfileForm";
import InterestsForm from "./InterestsForm";
import * as Messages from "./Messages";
import { AuthBody } from "../layouts/private";
import InvestorForm from "./InvestorForm";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box p={3} role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

const ProfileTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary">
        <Tab label={Messages.Information} />
        <Tab label={Messages.Interests} />
        <Tab label={Messages.Investor} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <AuthBody>
          <EditProfileForm />
        </AuthBody>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AuthBody>
          <InterestsForm />
        </AuthBody>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AuthBody>
          <InvestorForm />
        </AuthBody>
      </TabPanel>
    </>
  );
};

export default ProfileTabs;
