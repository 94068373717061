import { styled, Box } from "@material-ui/core";

const OutlinedDiv = styled(Box)(({ minHeight }) => ({
  border: "1px solid #ebebeb",
  borderRadius: "4px",
  marginBottom: 16,
  minHeight,
  padding: 4
}));

export default OutlinedDiv;
