/* eslint-disable react/prop-types */
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@material-ui/icons";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import MyStartupsSideMenu from "./MyStartupsSideMenu";

const sideMenuStyles = makeStyles(() => ({
  root: {
    margin: "16px 0"
  },
  title: {
    color: "#232323",
    fontFamily: "aktiv-grotesk",
    fontSize: "14px",
    letterSpacing: "-0.4px",
    lineHeight: "17px",
    textTransform: "uppercase",
    fontWeight: 800,
    margin: "8px 0"
  },
  menu: {
    borderRadius: "5px",
    border: "1px solid #EBEBEB"
  },
  menuItem: {
    height: 50
  }
}));

const SideMenu = ({ title, items, location, showStartups }) => {
  const classes = sideMenuStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {showStartups && <MyStartupsSideMenu />}
      <List dense className={classes.menu}>
        {items.map(item => (
          <ListItem
            className={classes.menuItem}
            button
            component={Link}
            to={item.to}
            selected={location.pathname === item.to}
            key={JSON.stringify(item.label)}
          >
            <ListItemText primary={item.label} />
            <ListItemSecondaryAction>
              <ArrowForwardIosIcon fontSize="small" />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const enhance = withRouter;

export default enhance(SideMenu);
