/* eslint-disable react/prop-types */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Tooltip, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import * as Messages from "./Messages";

const DeleteRoundDialog = ({ onConfirmClick, open, setOpen }) => {
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title={Messages.DeleteRound} placement="top">
        <IconButton size="small" onClick={handleOpen}>
          <Clear />
        </IconButton>
      </Tooltip>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{Messages.DeleteRound}</DialogTitle>
          <DialogContent>
            <DialogContentText>{Messages.DeleteRoundConfirm}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
              {Messages.Cancel}
            </Button>
            <Button onClick={onConfirmClick} variant="contained">
              {Messages.Confirm}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteRoundDialog;
