/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Avatar, Box, Grid, styled, Typography } from "@material-ui/core";
import { Add, BusinessCenter, Edit } from "@material-ui/icons";
import { isEmpty } from "lodash";
import numeral from "numeral";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { branch, compose, lifecycle, renderNothing, withHandlers, withState } from "recompose";
import { bindActionCreators } from "redux";
import { deleteInvestorFundingRound, getInvestorFundingRounds } from "../ducks/actions";
import {
  investorFundingRounds,
  investorFundingRoundsLoading,
  isInvestorOwned
} from "../ducks/selectors";
import { withNotifications } from "../notifications";
import DeleteRoundDialog from "../shared/components/DeleteRoundDialog";
import PowerButtonLink from "../shared/components/PowerButtonLink";
import AddRoundDialog from "./AddRoundDialog";
import EditRoundDialog from "./EditRoundDialog";
import * as Messages from "./Messages";

const NoRoundsFound = styled(Box)({
  padding: 10
});

const OutlinedDiv = styled("div")({
  border: "solid 1px #ebebeb",
  borderRadius: 4,
  padding: 16,
  margin: "16px 0"
});

const FundingTab = ({ rounds, isInvestorOwned, onConfirmClick, open, setOpen }) => {
  const { url } = useRouteMatch();

  if (isEmpty(rounds)) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <NoRoundsFound>
          <Typography variant="body1">
            <strong>{isInvestorOwned ? Messages.InsertFundingData : Messages.NoFundingData}</strong>
          </Typography>
        </NoRoundsFound>
        {isInvestorOwned && (
          <PowerButtonLink to={`${url}/create`} icon={Add} title={Messages.AddRound} />
        )}
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="button">
          <strong>{Messages.Investments}</strong>
        </Typography>
        {isInvestorOwned && (
          <PowerButtonLink to={`${url}/create`} icon={Add} title={Messages.AddRound} />
        )}
      </Box>
      <Grid container spacing={1}>
        {rounds.map(({ id, company_funding_round: round }) => (
          <Grid key={id} item md={4} sm={5} xs={12}>
            <OutlinedDiv>
              <Grid container>
                <Grid item xs={10}>
                  <Typography>{round.company.name}</Typography>
                  <Typography>
                    <strong>
                      {round.amount.cents
                        ? numeral(round.amount.cents / 100).format("$0,0[.]00")
                        : Messages.Undisclosed}
                    </strong>
                  </Typography>
                  {isInvestorOwned && (
                    <div>
                      <PowerButtonLink title={Messages.EditRound} icon={Edit} to={`${url}/${id}`} />
                      <DeleteRoundDialog
                        onConfirmClick={() => onConfirmClick({ id })}
                        open={open}
                        setOpen={setOpen}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Avatar src={round.company.logo_urls.small} alt={round.company.name}>
                    <BusinessCenter />
                  </Avatar>
                </Grid>
              </Grid>
            </OutlinedDiv>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const InvestorFundingTab = ({ rounds, isInvestorOwned, onConfirmClick, open, setOpen }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/create`} component={AddRoundDialog} />
        <Route path={`${path}/:roundId`} component={EditRoundDialog} />
      </Switch>
      <FundingTab
        rounds={rounds}
        isInvestorOwned={isInvestorOwned}
        onConfirmClick={onConfirmClick}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      rounds: investorFundingRounds(state),
      loading: investorFundingRoundsLoading(state),
      isInvestorOwned: isInvestorOwned({ investorId: id, state: "accepted" })(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        { deleteInvestorFundingRound, getInvestorFundingRounds },
        dispatch
      )
    })
  ),
  withState("open", "setOpen", false),
  withHandlers({
    onConfirmClick: ({ ok, ko, setOpen, actions }) => async ({ id }) => {
      try {
        await actions.deleteInvestorFundingRound({ id });
        ok(Messages.DeleteRoundSuccess);
        setOpen(false);
      } catch (err) {
        ko(Messages.DeleteRoundError);
      }
    }
  }),
  lifecycle({
    async componentDidMount() {
      const { actions, match } = this.props;
      await actions.getInvestorFundingRounds({ investorId: match.params.id });
    }
  }),
  branch(({ loading }) => loading, renderNothing)
);

export default enhance(InvestorFundingTab);
