import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const HowTo = (
  <FormattedMessage
    id="forgot-password.HowTo"
    defaultMessage="Se hai dimenticato i dati di login o ti è scaduta la password, digita l'indirizzo email che hai fornito al momento della registrazione. Riceverai una mail con la procedura da seguire."
  />
);
export const ForgotPassword = (
  <FormattedMessage id="forgot-password.ForgotPassword" defaultMessage="Password dimenticata?" />
);
export const Email = <FormattedMessage id="forgot-password.Email" defaultMessage="Email" />;
export const Continue = (
  <FormattedMessage id="forgot-password.Continue" defaultMessage="Prosegui" />
);
