/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Tooltip } from "@material-ui/core";

const withTooltip = ({ visible, ...props }) => Component =>
  visible ? (
    <Tooltip {...props}>
      <span>{Component}</span>
    </Tooltip>
  ) : (
    Component
  );

export default withTooltip;
