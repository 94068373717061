/* eslint-disable react/prop-types */
import { Button, Grid } from "@material-ui/core";
import { Field, Form, withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withState } from "recompose";
import { bindActionCreators } from "redux";
import * as yup from "yup";
import { searchAndImportCompany } from "../ducks/actions";
import { withNotifications } from "../notifications";
import FailedStartupImportDialog from "../shared/components/FailedStartupImportDialog";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import TextField from "../shared/formik/TextField";
import * as Messages from "./Messages";

const CompanyImportSearch = ({ isSubmitting, isValid, dirty, open, setOpen }) => (
  <>
    <Form>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <Field
            component={TextField}
            label={Messages.InsertPiva}
            name="partita_iva"
            type="text"
            fullWidth
            data-testid="company-import-form-partita_iva"
          />
        </Grid>
        <Grid item xs={5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!dirty || !isValid || isSubmitting}
            type="submit"
          >
            {Messages.SearchStartup}
          </Button>
        </Grid>
      </Grid>
    </Form>
    <FailedStartupImportDialog
      onConfirmClick={() => { /* Do nothing */}}
      open={open}
      setOpen={setOpen}/>
  </>
);

CompanyImportSearch.propTypes = FormPropTypes;

yup.setLocale({
  mixed: {
    required: "validation.FieldRequired"
  },
  string: {
    min: "validation.IvaMinLengthMessage"
  }
});

const validationSchema = yup.object().shape({
  partita_iva: yup
    .string()
    .min(10)
    .required()
});

const enhance = compose(
  withNotifications,
  withRouter,
  connect(undefined, dispatch => ({
    actions: bindActionCreators({ searchAndImportCompany }, dispatch)
  })),
  withState("open", "setOpen", false),
  withFormik({
    mapPropsToValues: () => ({ partita_iva: "" }),
    handleSubmit: async (values, { props: { actions, ko, history, setOpen } }) => {
      try {
        const { response } = await actions.searchAndImportCompany({ id: values.partita_iva });
        if (response.data.success) {
          history.push(`/profile/company_import/confirm/${values.partita_iva}`);
        } else {
          ko(Messages.MissingPiva);
          setOpen(true);
        }
      } catch (err) {
        ko(Messages.SearchStartupFailed);
        setOpen(true);
      }
    },
    validationSchema
  })
);

export default enhance(CompanyImportSearch);
