import baseConfig from "./base";

const config = {
  appEnv: "stag",
  tier: "staging",
  apiBaseUrl: "https://upbase-staging-be.upbase.eu/",
  firebase: {
    apiKey: "AIzaSyCv0vPm63PJbx5e8cm2g7qFOVpU6XyM2hc",
    authDomain: "startupitalia-a6081.firebaseapp.com",
    databaseURL: "https://startupitalia-a6081.firebaseio.com",
    projectId: "startupitalia-a6081",
    storageBucket: "startupitalia-a6081.appspot.com",
    messagingSenderId: "1051294898184",
    appId: "1:1051294898184:web:6efd3583c58ca70dc819bd",
    measurementId: "G-ZZQCXK3QT4"
  }
};

export default Object.freeze({ ...baseConfig, ...config });
