/* eslint-disable react/jsx-props-no-spreading */
import { compose, withHandlers, withProps } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withNotifications } from "../notifications";
import { roundById } from "../ducks/selectors";
import {
  getCompanyFundingRounds,
  updateCompanyFundingRound,
  getCompanyInvestors,
  getCompany
} from "../ducks/actions";
import RoundDialog from "./RoundDialog";
import * as Messages from "./Messages";

const enhance = compose(
  withNotifications,
  withRouter,
  connect(
    (
      state,
      {
        match: {
          params: { roundId }
        }
      }
    ) => ({ round: roundById(roundId)(state) }),
    dispatch => ({
      actions: bindActionCreators(
        { getCompanyFundingRounds, updateCompanyFundingRound, getCompanyInvestors, getCompany },
        dispatch
      )
    })
  ),
  withProps({
    title: Messages.EditRound
  }),
  withHandlers({
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/companies/${id}/funding`);
    },
    onSubmit: ({ actions, match, history, ok, ko }) => async values => {
      try {
        await actions.updateCompanyFundingRound({
          roundId: match.params.roundId,
          round: {
            ...values,
            investor_ids: (values.investor_ids || []).map(investor => investor.value)
          }
        });
        ok(Messages.RoundUpdateSuccess);
        await actions.getCompanyFundingRounds({ id: match.params.id });
        await actions.getCompanyInvestors({ id: match.params.id });
        await actions.getCompany({ id: match.params.id });
        history.push(`/companies/${match.params.id}/funding`);
      } catch (err) {
        ko(Messages.RoundUpdateError);
      }
    }
  })
);

export default enhance(RoundDialog);
