import React from "react";
import PropTypes from "prop-types";
import { styled, Typography } from "@material-ui/core";

const TitleContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  padding: 16,
  marginTop: 8,
  marginBottom: 16
});

const Title = styled(Typography)({
  fontFamily: "aktiv-grotesk",
  fontSize: 28,
  fontWeight: 900,
  lineHeight: "35px",
  textAlign: "center"
});

const LoginTitle = ({ children }) => (
  <TitleContainer>
    <Title>{children}</Title>
  </TitleContainer>
);

LoginTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

export default LoginTitle;
