import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Form, Field, withFormik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/auth";
import * as yup from "yup";
import { withNotifications } from "../notifications";
import { socialLogin } from "../ducks/actions";
import TextField from "../shared/formik/TextField";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import { firebaseSignUpMessageMap } from "../shared/utils";
import * as Messages from "./Messages";

const SignUpForm = ({ isSubmitting, isValid, dirty }) => (
  <Form>
    <Typography paragraph>{Messages.SignUpMessage}</Typography>
    <Grid spacing={2} container justify="center">
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.Email}
          name="email"
          fullWidth
          data-testid="signup-form-email"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.Password}
          name="password"
          type="password"
          fullWidth
          data-testid="signup-form-password"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.PasswordConfirmation}
          name="passwordConfirmation"
          type="password"
          fullWidth
          data-testid="signup-form-password"
        />
      </Grid>
      <Grid item xs={5}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
        >
          {Messages.SignUp}
        </Button>
      </Grid>
    </Grid>
  </Form>
);

SignUpForm.propTypes = FormPropTypes;

yup.setLocale({
  mixed: {
    required: "validation.FieldRequired"
  }
});

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "validation.MatchPassword")
});

const enhance = compose(
  withRouter,
  withNotifications,
  connect(undefined, dispatch => ({
    actions: bindActionCreators({ socialLogin }, dispatch)
  })),
  withFormik({
    mapPropsToValues: () => ({ email: "", password: "", passwordConfirmation: "" }),
    handleSubmit: async (values, { props: { actions, history, ko } }) => {
      try {
        await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        await actions.socialLogin({ idToken, email: values.email });
        history.push("/profile/new");
      } catch (err) {
        if (err.code && firebaseSignUpMessageMap[err.code]) ko(firebaseSignUpMessageMap[err.code]);
        else {
          ko(Messages.UserNotCreated);
          console.warn(err);
        }
      }
    },
    validationSchema
  })
);

export default enhance(SignUpForm);
