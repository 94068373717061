/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles, TextField as MUITextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  field: {
    margin: "8px 0"
  }
}));

const TextField = ({ field, form, children, ...other }) => {
  const classes = useStyles();
  const currentError = form.errors[field.name];
  const error = Boolean(currentError) && Boolean(form.touched[field.name]);
  return (
    <MUITextField
      classes={{ root: classes.field }}
      variant="outlined"
      error={error}
      helperText={error && <FormattedMessage id={currentError} />}
      margin="dense"
      {...field}
      {...other}
    >
      {children}
    </MUITextField>
  );
};

TextField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

TextField.defaultProps = {
  children: null
};

export default TextField;
