/* eslint-disable camelcase */
import {
  Grid,
  Box,
  Button,
  Divider,
  LinearProgress,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  CircularProgress,
  Hidden,
  Paper
} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { compose, bindActionCreators } from "redux";
import { withHandlers } from "recompose";
import * as Messages from "./Messages";
import * as ProfileMessages from "../profile/Messages";
import { createInvestor, getProfile } from "../ducks/actions";

import { profile, investor, profileLoading, ownedCompanies } from "../ducks/selectors";
import { withNotifications } from "../notifications";
import { AllFeedRssPreview, MyFeedRssPreview } from "./FeedRSS";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "16px 0"
  },
  menu: {
    backgroundColor: "white",
    borderRadius: "5px",
    border: "1px solid #EBEBEB"
  },
  menuItem: {
    height: 64
  },
  goToEditButton: {
    marginRight: 58
  },
  totalProfileCompletion: {
    marginRight: 64
  },
  intro: {
    padding: 16,
    display: "flex"
  },
  introText: {
    marginLeft: 16
  },
  investorBox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  goToEditProfileButton: {
    margin: 0
  },
  investorProfileCompletation: {
    flexGrow: 1
  },
  newsSectionTitle: {
    color: theme.palette.primary.main,
    borderBottom: "solid 2px #bcbcbc",
    paddingBottom: 8
  }
}));

/* eslint-disable react/destructuring-assignment */
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Logo = () => <img src="/images/logo-header/logo-header@3x.png" alt="UpBase"/>;

const MyStartups = ({ profile, profileLoading, investor, onCreateInvestor }) => {
  const classes = useStyles();
  const history = useHistory();

  const ownedCompaniesList = useSelector(ownedCompanies({ state: "accepted" }));

  if (profileLoading) {
    return <LinearProgress />;
  }

  const myCompanies = (
    <>
      {ownedCompaniesList
        .filter(c => c.startup)
        .map(company => (
          <Box dense p={2} className={classes.menu}>
            <List>
              {/* Startup */}
              <ListItem alignItems="center">
                <ListItemText
                  primary={
                    <h2>
                      <strong>{company.project_name || company.name}</strong>
                    </h2>
                  }
                />
                <Hidden smDown>
                  <ListItemSecondaryAction className={classes.totalProfileCompletion}>
                    <h4>{Messages.YourProfileCompletedAt}</h4>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.overview_completion_percent}
                    />
                  </ListItemAvatar>
                </Hidden>
              </ListItem>
              <Hidden mdUp>
                <ListItem alignItems="center">
                  <ListItemText />
                  <ListItemSecondaryAction className={classes.totalProfileCompletion}>
                    <h4>{Messages.YourProfileCompletedAt}</h4>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.overview_completion_percent}
                    />
                  </ListItemAvatar>
                </ListItem>
              </Hidden>
              <Divider />

              {/* Overview */}
              <ListItem alignItems="center" className={classes.menuItem}>
                <ListItemText primary={<h3>Overview</h3>} />
                <Hidden smDown>
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/overview/edit`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.overview_completion_percent}
                    />
                  </ListItemAvatar>
                </Hidden>
              </ListItem>
              <Hidden mdUp>
                <ListItem alignItems="center">
                  <ListItemText />
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/overview/edit`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.overview_completion_percent}
                    />
                  </ListItemAvatar>
                </ListItem>
              </Hidden>
              <Divider />

              {/* Culture */}
              <ListItem alignItems="center" className={classes.menuItem}>
                <ListItemText primary={<h3>Culture</h3>} />
                <Hidden smDown>
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/culture/edit`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.culture_completion_percent}
                    />
                  </ListItemAvatar>
                </Hidden>
              </ListItem>
              <Hidden mdUp>
                <ListItem alignItems="center">
                  <ListItemText />
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/culture/edit`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.culture_completion_percent}
                    />
                  </ListItemAvatar>
                </ListItem>
              </Hidden>
              <Divider />

              {/* Team */}
              <ListItem alignItems="center" className={classes.menuItem}>
                <ListItemText primary={<h3>Team</h3>} />
                <Hidden smDown>
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/team`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.team_completion_percent}
                    />
                  </ListItemAvatar>
                </Hidden>
              </ListItem>
              <Hidden mdUp>
                <ListItem>
                  <ListItemText />
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/team`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.team_completion_percent}
                    />
                  </ListItemAvatar>
                </ListItem>
              </Hidden>
              <Divider />

              {/* Funding */}
              <ListItem alignItems="center" className={classes.menuItem}>
                <ListItemText primary={<h3>Funding</h3>} />
                <Hidden smDown>
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/funding`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.funding_completion_percent}
                    />
                  </ListItemAvatar>
                </Hidden>
              </ListItem>
              <Hidden mdUp>
                <ListItem alignItems="center">
                  <ListItemText />
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToEditButton}
                      component={Link}
                      to={`/companies/${company.id}/funding`}
                    >
                      {Messages.GoToEdit}
                    </Button>
                  </ListItemSecondaryAction>
                  <ListItemAvatar>
                    <CircularProgressWithLabel
                      variant="static"
                      value={company.funding_completion_percent}
                    />
                  </ListItemAvatar>
                </ListItem>
              </Hidden>
              <Divider />
            </List>
          </Box>
        ))}
      <br />
      <Paper variant="outlined" className={classes.investorBox}>
        <List className={classes.investorProfileCompletation}>
          {/* Startup */}
          <ListItem alignItems="center">
            <ListItemText primary="Possiedi altre startup?" />
            <ListItemSecondaryAction>
              <Button
                className={classes.goToEditProfileButton}
                component={Link}
                to="/home/search_startup"
              >
                Richiedi proprietà
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>

      </Paper>
      <br />
    </>
  );

  const imInvestor = (
    <>
      <Paper variant="outlined" className={classes.investorBox}>
        <List className={classes.investorProfileCompletation}>
          <ListItem alignItems="center">
            <ListItemText primary={Messages.PublicInvestorProfile} />
            <Hidden smDown>
              <ListItemSecondaryAction className={classes.totalProfileCompletion}>
                <h4>{Messages.YourProfileCompletedAt}</h4>
              </ListItemSecondaryAction>
              <ListItemAvatar>
                <CircularProgressWithLabel
                  variant="static"
                  value={profile.person?.profile_completion_percent}
                />
              </ListItemAvatar>
            </Hidden>
          </ListItem>
          <Hidden mdUp>
            <ListItem alignItems="center">
              <ListItemText />
              <ListItemSecondaryAction className={classes.totalProfileCompletion}>
                <h4>{Messages.YourProfileCompletedAt}</h4>
              </ListItemSecondaryAction>
              <ListItemAvatar>
                <CircularProgressWithLabel
                  variant="static"
                  value={profile.person?.profile_completion_percent}
                />
              </ListItemAvatar>
            </ListItem>
          </Hidden>
        </List>
        <Button
          className={classes.goToEditProfileButton}
          component={Link}
          to={`/people/${investor.id}`}
        >
          {Messages.GoToEdit}
        </Button>
      </Paper>
    </>
  );

  const onBoardingStart = (
    <>
      <Paper variant="outlined" className={classes.intro}>
        <div>
          <Logo />
        </div>
        <div>
          <Typography variant="body1" className={classes.introText}>
            {Messages.WhatsYourJobWelcomeText}
          </Typography>
        </div>
      </Paper>

      <br />
      <Typography variant="h5">{Messages.WhatsYourJob}</Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Button 
            variant="outlined" 
            color="primary" 
            fullWidth 
            component={Link} 
            to="/home/search_startup">
            <Typography variant="body1">{Messages.ImStartupper}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button 
            variant="outlined" 
            color="primary" 
            fullWidth 
            onClick={onCreateInvestor}>
            <Typography variant="body1">{Messages.ImInvestor}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button 
            variant="outlined" 
            color="primary" 
            fullWidth 
            onClick={()=>{ 
              onCreateInvestor();
              history.push(`/home/search_startup`);
            }}>
            <Typography variant="body1">{Messages.ImBothStartupperAndInvestor}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {_.isEmpty(ownedCompaniesList) && profile.person?.investor_id == null
        ? onBoardingStart
        : null}
      {!_.isEmpty(ownedCompaniesList) ? myCompanies : null}
      {profile.person?.investor_id != null ? imInvestor : null}

      <br/>
      <br/>

      {/* News previews */}
      <h3 className={classes.newsSectionTitle}>NOTIZIE BY STARTUPITALIA</h3>
      <AllFeedRssPreview />

      <h3 className={classes.newsSectionTitle}>I TUOI FAVORITI</h3>
      <MyFeedRssPreview />

    </>
  );
};

const enhance = compose(
  withNotifications,
  connect(
    state => ({
      profile: profile(state),
      investor: investor(state),
      profileLoading: profileLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ createInvestor, getProfile }, dispatch)
    })
  ),
  withHandlers({
    onCreateInvestor: ({ actions, ok, ko, profile }) => async () => {
      try {
        await actions.createInvestor({
          investor: {
            investorable_type: "Person",
            investorable_attributes: {
              profile_id: profile.id,
              first_name: profile.first_name,
              last_name: profile.last_name
            },
            name: `${profile.first_name} ${profile.last_name}`
          }
        });
        await actions.getProfile();
        // const res = await actions.getProfile();
        // history.push(`/investors/${res.response.data.data.person.investor_id}/funding/create`);
        ok(ProfileMessages.InvestorCreateSuccess);
      } catch {
        ko(ProfileMessages.InvestorCreateError);
      }
    }
  })
);

export default enhance(MyStartups);
