import React from "react";
import { Form, Field, withFormik } from "formik";
import { Button, Grid } from "@material-ui/core";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import * as yup from "yup";

import { withNotifications } from "../notifications";
import { auth } from "../firebase";
import TextField from "../shared/formik/TextField";
import * as Messages from "./Messages";
import { FormPropTypes } from "../shared/formik/prop-types/Form";

const ForgotPasswordForm = ({ isSubmitting, isValid, dirty }) => {
  return (
    <Form>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12}>
          <Field
            component={TextField}
            label={Messages.Email}
            fullWidth
            name="email"
            data-testid="forgot-password-email"
          />
        </Grid>
        <Grid item xs={5}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!dirty || !isValid || isSubmitting}
            type="submit"
          >
            {Messages.Continue}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

ForgotPasswordForm.propTypes = FormPropTypes;

yup.setLocale({
  string: {
    required: "validation.FieldRequired",
    email: "validation.InvalidEmail"
  }
});

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
});

const enhance = compose(
  withNotifications,
  withRouter,
  withFormik({
    mapPropsToValues: () => ({ email: "" }),
    handleSubmit: async (values, { props: { history, ko } }) => {
      try {
        await auth.getAuth().sendPasswordResetEmail(values.email);
        history.push("login?cm=ForgotPassword");
      } catch (err) {
        ko(err.message);
      }
    },
    validationSchema
  })
);

export default enhance(ForgotPasswordForm);
