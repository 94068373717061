/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const FullNameDataProvider = props => (
  <DataTypeProvider
    {...props}
    formatterComponent={({ row }) => {
      return `${row.first_name} ${row.last_name}`;
    }}
  />
);

export default FullNameDataProvider;
