/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  makeStyles,
  Box,
  FormGroup,
  FormLabel,
  Avatar,
  ListItemText,
  ListItemAvatar,
  Divider,
  Typography
} from "@material-ui/core";
import * as yup from "yup";
import AsyncCreatable from "react-select/async-creatable";
import ReactSelectField from "../formik/ReactSelectField";
import FullScreenDialog from "./FullScreenDialog";
import AutoForm from "./AutoForm";
import * as Messages from "./Messages";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    flex: "0 100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const TeamAddDialog = ({ onClose, onSubmit, onLoadPeople }) => {
  const classes = useStyles();
  const [formikProps, setFormikProps] = useState({
    initialValues: {
      first_name: "",
      last_name: "",
      invitation_email: "",
      job_title: "",
      is_founder: false
    },
    onSubmit: values => onSubmit({ values, personId: null }),
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      invitation_email: yup
        .string()
        .email()
        .required(),
      job_title: yup.string().required()
    })
  });

  const [formGroup, setFormGroup] = useState({
    default: {
      fields: [
        { label: "First Name", name: "first_name" },
        { label: "Last Name", name: "last_name" },
        { label: "Job Title", name: "job_title" },
        { label: "Invitation Email", name: "invitation_email" },
        {
          label: "Founder",
          name: "is_founder",
          component: ReactSelectField,
          options: [
            { value: true, label: "Si" },
            { value: false, label: "No" }
          ]
        }
      ]
    }
  });

  const handlePersonSelect = option => {
    if (option && !option.value.id) {
      const [firstName, ...rest] = option.value.split(" ");
      const lastName = rest.join(" ");

      setFormikProps({
        ...formikProps,
        initialValues: {
          ...formikProps.initialValues,
          first_name: firstName,
          last_name: lastName || ""
        },
        onSubmit: values => onSubmit({ values })
      });
    } else {
      setFormikProps({
        ...formikProps,
        initialValues: {
          ...formikProps.initialValues,
          first_name: option ? option.value.first_name : "",
          last_name: option ? option.value.last_name : "",
          invitation_email: option ? option.value.email : ""
        },
        onSubmit: values => onSubmit({ values, personId: option.value.id })
      });
      setFormGroup({
        ...formGroup,
        default: {
          fields: formGroup.default.fields.map(field =>
            ["first_name", "last_name"].includes(field.name)
              ? { ...field, disabled: Boolean(option) }
              : field
          )
        }
      });
    }
  };

  return (
    <FullScreenDialog title="Team" onClose={onClose}>
      <FormGroup>
        <FormLabel>{Messages.SearchForPeople}</FormLabel>
        <Box margin="16px 0px">
          <AsyncCreatable
            loadOptions={onLoadPeople}
            isClearable
            defaultOptions
            formatOptionLabel={({ label, avatarUrl, code }) => (
              <Box display="flex" alignItems="center">
                <ListItemAvatar>
                  <Avatar src={avatarUrl} alt={label}>
                    {code}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={label} />
              </Box>
            )}
            onChange={handlePersonSelect}
          />
        </Box>
      </FormGroup>
      <Divider />
      <Typography paragraph>{Messages.CreatePeople}</Typography>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={formGroup}
        label="Aggiungi"
        classes={classes}
      />
    </FullScreenDialog>
  );
};

export default TeamAddDialog;
