import { DataTypeProvider } from "@devexpress/dx-react-grid";
import React from "react";
import MatchingChip from "../components/MatchingChip";

const MatchingDataProvider = ({ ...props }) => {
  return (
    <DataTypeProvider
      {...props}
      formatterComponent={({ value }) => {
        return <MatchingChip value={value} />;
      }}
    />
  );
};

export default MatchingDataProvider;
