import { styled, Typography } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl.macro";
import ReactSelect from "../components/ReactSelect";

const SelectContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const ReactSelectField = ({ field, form, options, isMulti = false, label, hasNone, ...other }) => {
  const onChange = option => {
    form.setFieldValue(field.name, isMulti ? (option || []).map(item => item.value) : option.value);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            option => (_.isEmpty(field.value) ? [] : field.value).indexOf(option.value) >= 0
          )
        : options.find(option => option.value === field.value);
    }
    return isMulti ? [] : "";
  };

  return (
    <SelectContainer>
      {label && (
        <Typography variant="body2" gutterBottom>
          {label}
        </Typography>
      )}
      <ReactSelect
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={
          hasNone
            ? [
                {
                  label: <FormattedMessage id="formik.None" defaultMessage="Nessuno" />,
                  value: ""
                },
                ...options
              ]
            : options
        }
        isMulti={isMulti}
        {...other}
      />
    </SelectContainer>
  );
};

export default ReactSelectField;
