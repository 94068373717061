import { Menu, MenuItem, styled, Typography } from "@material-ui/core";
import {
  AccountBox as AccountBoxIcon,
  ExitToApp as LogoutIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getProfileQuery } from "../ducks/selectors";
import * as Messages from "./Messages";

const MenuTypography = styled(Typography)({
  marginLeft: 8
});

const ContextMenu = ({ anchorEl, onClose }) => {
  const location = useLocation();

  const {
    data: { data: profile },
    loading: profileLoading
  } = useSelector(getProfileQuery);

  const hasPerson = !_.isEmpty(profile.person);

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
      {!location.pathname.includes("/profile/new") && (
        <MenuItem onClick={onClose} component={Link} to="/home">
          <HomeIcon />
          <MenuTypography>{Messages.Home}</MenuTypography>
        </MenuItem>
      )}
      {!location.pathname.includes("/profile/new") && (
        <MenuItem onClick={onClose} component={Link} to="/profile/me">
          <PersonIcon />
          <MenuTypography>{Messages.PrivateProfile}</MenuTypography>
        </MenuItem>
      )}
      {!location.pathname.includes("/profile/new") && !profileLoading && hasPerson && (
        <MenuItem onClick={onClose} component={Link} to={`/people/${profile.person.id}`}>
          <AccountBoxIcon />
          <MenuTypography>{Messages.PublicProfile}</MenuTypography>
        </MenuItem>
      )}
      {!location.pathname.includes("/profile/new") && (
        <MenuItem onClick={onClose} component={Link} to="/search?type=all">
          <SearchIcon />
          <MenuTypography>{Messages.Search}</MenuTypography>
        </MenuItem>
      )}
      <MenuItem onClick={onClose} component={Link} to="/logout">
        <LogoutIcon />
        <MenuTypography>{Messages.Logout}</MenuTypography>
      </MenuItem>
    </Menu>
  );
};

export default ContextMenu;
