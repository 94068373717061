/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const PlaceDataProvider = props => (
  <DataTypeProvider
    {...props}
    formatterComponent={({ value, row }) => {
      if (value && value.municipality && value.pv) {
        return `${value.municipality} (${value.pv})`;
      }

      if (row.municipality && row.pv) {
        return `${row.municipality} (${row.pv})`;
      }

      return "";
    }}
  />
);

export default PlaceDataProvider;
