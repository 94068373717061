import { Link, styled, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { isAuthenticated } from "../ducks/selectors";
import * as Messages from "./Messages";

const SeeMoreContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2)
}));

const SeeMore = () => {
  const isAuth = useSelector(isAuthenticated);

  if (isAuth) {
    return null;
  }

  return (
    <SeeMoreContainer>
      <Typography align="center">
        {Messages.WannaSeeMore}&nbsp;
        <Link component={RouterLink} to="/login">
          {Messages.Register}
        </Link>
      </Typography>
    </SeeMoreContainer>
  );
};

export default SeeMore;
