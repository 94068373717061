import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const PrivateProfile = (
  <FormattedMessage id="layout.PrivateProfile" defaultMessage="Profilo privato" />
);
export const PublicProfile = (
  <FormattedMessage id="layout.PublicProfile" defaultMessage="Profilo pubblico" />
);
export const Home = <FormattedMessage id="layout.Home" defaultMessage="Home" />;
export const Logout = <FormattedMessage id="layout.logout" defaultMessage="Logout" />;

export const PrivacyPolicy = (
  <FormattedMessage id="layout.PrivacyPolicy" defaultMessage="Privacy Policy" />
);
export const CookiePolicy = (
  <FormattedMessage id="layout.CookiePolicy" defaultMessage="Cookie Policy" />
);
export const SignUp = <FormattedMessage id="layout.SignUp" defaultMessage="Sign up" />;
export const Search = <FormattedMessage id="layout.Search" defaultMessage="Cerca" />;
