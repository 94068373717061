import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import * as Messages from "./Messages";

const useNoResultStyle = makeStyles(theme => ({
  img: {
    height: 320,
    width: 320,
    marginTop: theme.spacing(5)
  },
  text: {
    fontSize: 22
  }
}));
const NoResultsAskForImport = ({ withImage }) => {
  const classes = useNoResultStyle();
  return (
    <Box flexDirection="column" display="flex" px={20} py={2} alignItems="center">
      <Typography variant="h5" align="center" gutterBottom className={!withImage && classes.text}>
        {Messages.noSearchResultsAskForImport}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/profile/company_import">
        {Messages.ImportCompany}
      </Button>
      {withImage && <img className={classes.img} src="images/no-results.svg" alt="no-results" />}
    </Box>
  );
};

export default NoResultsAskForImport;
