/* eslint-disable import/prefer-default-export */
import _, { isEmpty, isNull } from "lodash";
import moment from "moment";
import * as Messages from "./components/Messages";

export const dateTimeFormatter = value => {
  if (isNull(value)) return "";
  const date = moment(value);
  let format = "";
  if (moment().diff(date, "days") === 0) {
    format = "HH:mm";
  } else if (moment().year() === date.year()) {
    format = "DD MMM";
  } else {
    format = "DD/MM/YY";
  }
  return date.format(format);
};

export const adaptNullValues = (src, opts) => {
  let dest = src;

  if (isEmpty(dest)) {
    dest = {};
  }
  opts.forEach(([value, fields]) => {
    fields.forEach(field => {
      if (isEmpty(dest[field])) dest[field] = value;
    });
  });
  return dest;
};

export const firebaseSignUpMessageMap = {
  "auth/email-already-in-use": Messages.EmailAlreadyInUse,
  "auth/invalid-email": Messages.EmailInvalid,
  "auth/operation-not-allowed": Messages.OperationNotAllowed,
  "auth/weak-password": Messages.WeakPassword
};
export const firebaseSignInMessageMap = {
  "auth/invalid-email": Messages.EmailInvalid,
  "auth/user-disabled": Messages.UserDisabled,
  "auth/user-not-found": Messages.UserNotFound,
  "auth/wrong-password": Messages.WrongPassword
};

export const regions = [
  "Abruzzo",
  "Basilicata",
  "Calabria",
  "Campania",
  "Emilia-Romagna",
  "Friuli-Venezia Giulia",
  "Lazio",
  "Liguria",
  "Lombardia",
  "Marche",
  "Molise",
  "Piemonte",
  "Puglia",
  "Sardegna",
  "Sicilia",
  "Toscana",
  "Trentino-Alto Adige",
  "Umbria",
  "Valle d'Aosta",
  "Veneto"
];

export const regionOptions = regions.map(region => ({ label: region, value: region }));

export const provinces = {
  Abruzzo: [
    {
      label: "Chieti",
      value: "CH"
    },
    {
      label: "L'Aquila",
      value: "AQ"
    },
    {
      label: "Pescara",
      value: "PE"
    },
    {
      label: "Teramo",
      value: "TE"
    }
  ],
  Basilicata: [
    {
      label: "Matera",
      value: "MT"
    },
    {
      label: "Potenza",
      value: "PZ"
    }
  ],
  Calabria: [
    {
      label: "Catanzaro",
      value: "CZ"
    },
    {
      label: "Cosenza",
      value: "CS"
    },
    {
      label: "Crotone",
      value: "KR"
    },
    {
      label: "Reggio Calabria",
      value: "RC"
    },
    {
      label: "Vibo Valentia",
      value: "VV"
    }
  ],
  Campania: [
    {
      label: "Avellino",
      value: "AV"
    },
    {
      label: "Benevento",
      value: "BN"
    },
    {
      label: "Caserta",
      value: "CE"
    },
    {
      label: "Napoli",
      value: "NA"
    },
    {
      label: "Salerno",
      value: "SA"
    }
  ],
  "Emilia-Romagna": [
    {
      label: "Bologna",
      value: "BO"
    },
    {
      label: "Ferrara",
      value: "FE"
    },
    {
      label: "Forlì-Cesena",
      value: "FC"
    },
    {
      label: "Modena",
      value: "MO"
    },
    {
      label: "Parma",
      value: "PR"
    },
    {
      label: "Piacenza",
      value: "PC"
    },
    {
      label: "Ravenna",
      value: "RA"
    },
    {
      label: "Reggio Emilia",
      value: "RE"
    },
    {
      label: "Rimini",
      value: "RN"
    }
  ],
  "Friuli-Venezia Giulia": [
    {
      label: "Gorizia",
      value: "GO"
    },
    {
      label: "Pordenone",
      value: "PN"
    },
    {
      label: "Trieste",
      value: "TS"
    },
    {
      label: "Udine",
      value: "UD"
    }
  ],
  Lazio: [
    {
      label: "Frosinone",
      value: "FR"
    },
    {
      label: "Latina",
      value: "LT"
    },
    {
      label: "Rieti",
      value: "RI"
    },
    {
      label: "Roma",
      value: "RM"
    },
    {
      label: "Viterbo",
      value: "VT"
    }
  ],
  Liguria: [
    {
      label: "Genova",
      value: "GE"
    },
    {
      label: "Imperia",
      value: "IM"
    },
    {
      label: "La Spezia",
      value: "SP"
    },
    {
      label: "Savona",
      value: "SV"
    }
  ],
  Lombardia: [
    {
      label: "Bergamo",
      value: "BG"
    },
    {
      label: "Brescia",
      value: "BS"
    },
    {
      label: "Como",
      value: "CO"
    },
    {
      label: "Cremona",
      value: "CR"
    },
    {
      label: "Lecco",
      value: "LC"
    },
    {
      label: "Lodi",

      value: "LO"
    },
    {
      label: "Mantova",

      value: "MN"
    },
    {
      label: "Milano",
      value: "MI"
    },
    {
      label: "Monza e Brianza",

      value: "MB"
    },
    {
      label: "Pavia",

      value: "PV"
    },
    {
      label: "Sondrio",
      value: "SO"
    },
    {
      label: "Varese",
      value: "VA"
    }
  ],
  Marche: [
    {
      label: "Ancona",
      value: "AN"
    },
    {
      label: "Ascoli Piceno",
      value: "AP"
    },
    {
      label: "Fermo",
      value: "FM"
    },
    {
      label: "Macerata",
      value: "MC"
    },
    {
      label: "Pesaro e Urbino",
      value: "PU"
    }
  ],
  Molise: [
    {
      label: "Campobasso",
      value: "CB"
    },
    {
      label: "Isernia",
      value: "IS"
    }
  ],
  Piemonte: [
    {
      label: "Alessandria",
      value: "AL"
    },
    {
      label: "Asti",
      value: "AT"
    },
    {
      label: "Biella",
      value: "BI"
    },
    {
      label: "Cuneo",
      value: "CN"
    },
    {
      label: "Novara",
      value: "NO"
    },
    {
      label: "Torino",
      value: "TO"
    },
    {
      label: "Verbano Cusio Ossola",
      value: "VB"
    },
    {
      label: "Vercelli",
      value: "VB"
    }
  ],
  Puglia: [
    {
      label: "Bari",
      value: "BA"
    },
    {
      label: "Barletta-Andria-Trani",
      value: "BT"
    },
    {
      label: "Brindisi",
      value: "BA"
    },
    {
      label: "Lecce",
      value: "LE"
    },
    {
      label: "Foggia",
      value: "FG"
    },
    {
      label: "Taranto",
      value: "TA"
    }
  ],
  Sardegna: [
    {
      label: "Cagliari",
      value: "CA"
    },
    {
      label: "Carbonia-Iglesias",
      value: "CI"
    },
    {
      label: "Medio Campidano",
      value: "VS"
    },
    {
      label: "Nuoro",

      value: "NU"
    },
    {
      label: "Ogliastra",

      value: "OG"
    },
    {
      label: "Olbia-Tempio",
      value: "OT"
    },
    {
      label: "Oristano",
      value: "OR"
    },
    {
      label: "Sassari",
      value: "SS"
    }
  ],
  Sicilia: [
    {
      label: "Agrigento",
      value: "AG"
    },
    {
      label: "Caltanissetta",
      value: "CL"
    },
    {
      label: "Catania",
      value: "CT"
    },
    {
      label: "Enna",
      value: "EN"
    },
    {
      label: "Messina",
      value: "ME"
    },
    {
      label: "Palermo",
      value: "PA"
    },
    {
      label: "Ragusa",
      value: "RG"
    },
    {
      label: "Siracusa",
      value: "SR"
    },
    {
      label: "Siracusa",
      value: "TP"
    }
  ],
  Toscana: [
    {
      label: "Arezzo",
      value: "AR"
    },
    {
      label: "Firenze",
      value: "FI"
    },
    {
      label: "Grosseto",
      value: "GR"
    },
    {
      label: "Livorno",
      value: "LI"
    },
    {
      label: "Lucca",
      value: "LU"
    },
    {
      label: "Massa e Carrara",
      value: "MS"
    },
    {
      label: "Pisa",
      value: "PI"
    },
    {
      label: "Pistoia",
      value: "PT"
    },
    {
      label: "Prato",
      value: "PO"
    },
    {
      label: "Siena",
      value: "SI"
    }
  ],
  "Trentino-Alto Adige": [
    {
      label: "Bolzano",
      value: "BZ"
    },
    {
      label: "Trento",
      value: "TN"
    }
  ],
  Umbria: [
    {
      label: "Perugia",
      value: "PG"
    },
    {
      label: "Terni",
      value: "TR"
    }
  ],
  "Valle d'Aosta": [
    {
      label: "Aosta",
      value: "AO"
    }
  ],
  Veneto: [
    {
      label: "Belluno",
      value: "BL"
    },
    {
      label: "Padova",
      value: "PD"
    },
    {
      label: "Rovigo",
      value: "RO"
    },
    {
      label: "Treviso",
      value: "TV"
    },
    {
      label: "Venezia",
      value: "VE"
    },
    {
      label: "Verona",
      value: "VR"
    },
    {
      label: "Vicenza",
      value: "VI"
    }
  ]
};

export const fundingStatusMap = {
  "pre-seed": "Pre-seed",
  seed: "Seed",
  round_a: "Round A",
  round_b: "Round B",
  round_c: "Round C",
  round_d: "Round D",
  round_e: "Round E",
  round_f: "Round F",
  round_g: "Round G",
  round_h: "Round H",
  round_i: "Round I",
  round_j: "Round J"
};

export const fundingStatusOptions = Object.keys(fundingStatusMap).map(key => ({
  label: fundingStatusMap[key],
  value: key
}));

export const yearOptions = _.range(moment().year(), 1969).map(year => ({
  label: year.toString(),
  value: year.toString()
}));

export const capitalClassMap = {
  "1": "1 €",
  "2": "1-10k €",
  "3": "10k-50k €",
  "4": "50k-250k €",
  "5": "oltre 250k €"
};

export const capitalClassOptions = Object.keys(capitalClassMap).map(key => ({
  label: capitalClassMap[key],
  value: key
}));
