import React from "react";
import { compose } from "recompose";
import { Form, Field, withFormik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Grid, styled } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import * as yup from "yup";

import TextField from "../shared/formik/TextField";
import * as Messages from "./Messages";
import { socialLogin } from "../ducks/actions";
import { withNotifications } from "../notifications";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import { firebaseSignInMessageMap } from "../shared/utils";

const SignInButton = styled(Button)({
  marginTop: 27
});

const LoginForm = ({ isSubmitting, isValid, dirty }) => (
  <Form>
    <Grid container>
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.Email}
          name="email"
          fullWidth
          variant="outlined"
          data-testid="login-form-email"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.Password}
          name="password"
          fullWidth
          variant="outlined"
          type="password"
          data-testid="login-form-password"
        />
      </Grid>
      <Grid item xs={12}>
        <SignInButton
          variant="contained"
          color="primary"
          fullWidth
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
        >
          {Messages.Login}
        </SignInButton>
      </Grid>
    </Grid>
  </Form>
);

LoginForm.propTypes = FormPropTypes;

yup.setLocale({
  mixed: {
    required: "validation.FieldRequired"
  }
});

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required()
});

const enhance = compose(
  withNotifications,
  withRouter,
  connect(undefined, dispatch => ({ actions: bindActionCreators({ socialLogin }, dispatch) })),
  withFormik({
    mapPropsToValues: () => ({ email: "", password: "" }),
    handleSubmit: async (values, { props: { actions, ko, history } }) => {
      try {
        await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        const { response } = await actions.socialLogin({ idToken, email: values.email });
        // modifica cristian cau 26.08.2022
        history.push(response.data.data.profile ? "/home" : "profile/new");
      } catch (err) {
        if (err.code && firebaseSignInMessageMap[err.code]) ko(firebaseSignInMessageMap[err.code]);
        else {
          console.warn(err);
        }
      }
    },
    validationSchema
  })
);

export default enhance(LoginForm);
