import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { IntlProvider } from "react-intl";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { HashRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { configureStore } from "./ducks";
import { translations } from "./i18n";
import config from "./config";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import DismissButton from "./shared/components/DismissButton";

if (config.appEnv === "dev" && Boolean(config.apiMock)) {
  localStorage.setItem("UPBASE_AUTH_TOKEN", "Test Token (not usable for production)");
}
const INITIAL_STATE = {
  auth: { isAuthenticated: Boolean(localStorage.getItem("UPBASE_AUTH_TOKEN")) }
};

const store = configureStore(INITIAL_STATE);

const language = "en";

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};


Sentry.init({
  dsn: "https://509023984f004d7ab19bca1c68d1b3c9@sentry.startupitalialliance.eu/5",
  integrations: [new BrowserTracing()],
  environment: config.tier,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ReduxProvider store={store}>
      <IntlProvider locale={language} key={language} messages={translations[language]}>
        <SnackbarProvider
          ref={notistackRef}
          action={key => <DismissButton onClick={onClickDismiss(key)} />}
        >
          <Router>
            <App />
          </Router>
        </SnackbarProvider>
      </IntlProvider>
    </ReduxProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
