/* eslint-disable react/jsx-props-no-spreading */
import { compose, withHandlers, withProps } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withNotifications } from "../notifications";
import { investorRoundById } from "../ducks/selectors";
import { getInvestorFundingRounds, updateInvestorFundingRound } from "../ducks/actions";
import RoundDialog from "./RoundDialog";
import * as Messages from "./Messages";

const enhance = compose(
  withNotifications,
  withRouter,
  connect(
    (
      state,
      {
        match: {
          params: { roundId }
        }
      }
    ) => ({ round: investorRoundById(roundId)(state) }),
    dispatch => ({
      actions: bindActionCreators(
        { getInvestorFundingRounds, updateInvestorFundingRound },
        dispatch
      )
    })
  ),
  withProps(({ round }) => ({
    title: Messages.EditRound,
    isEdit: true,
    round: round.company_funding_round
  })),
  withHandlers({
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/investors/${id}/funding`);
    },
    onSubmit: ({ actions, round, match, history, ok, ko }) => async values => {
      try {
        await actions.updateInvestorFundingRound({
          id: match.params.roundId,
          companyFundingRound: {
            ...values,
            id: round.id
          }
        });
        await actions.getInvestorFundingRounds({ investorId: match.params.id });
        ok(Messages.RoundUpdateSuccess);
        history.push(`/investors/${match.params.id}/funding`);
      } catch (err) {
        ko(Messages.RoundUpdateError);
      }
    }
  })
);

export default enhance(RoundDialog);
