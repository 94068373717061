/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Table } from "@devexpress/dx-react-grid-material-ui";

const useStyles = makeStyles(() => ({
  tableRoot: {
    "& td": {
      height: "35px",
      padding: "0px 24px 0px 8px"
    }
  }
}));

const DenseRow = ({ className, ...props }) => {
  const classes = useStyles();
  return <Table.Row {...props} className={clsx(classes.tableRoot, className)} />;
};

export default DenseRow;
