/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { LinearProgress } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { branch, lifecycle, renderComponent, withHandlers } from "recompose";
import { bindActionCreators, compose } from "redux";
import { deleteCompanyPerson, getCompanyPeople } from "../ducks/actions";
import {
  company,
  companyPeopleLoading,
  companyPeopleMember,
  isAuthenticated,
  isCompanyPersonProposed,
  isInvestorOwned,
  profile
} from "../ducks/selectors";
import { withNotifications } from "../notifications";
import TeamTab from "../shared/components/TeamTab";
import * as Messages from "./Messages";
import TeamAddDialog from "./TeamAddDialog";
import TeamEditDialog from "./TeamEditDialog";

const InvestorTeamTab = props => {
  const { path } = useRouteMatch();
  return (
    <>
      <TeamTab {...props} />
      <Switch>
        <Route path={`${path}/create`} component={TeamAddDialog} />
        <Route path={`${path}/:personId`} component={TeamEditDialog} />
      </Switch>
    </>
  );
};

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      isAuthenticated: isAuthenticated(state),
      isInvestorOwned: isInvestorOwned({ investorId: id, state: "accepted" })(state),
      companyPeopleMember: companyPeopleMember(state),
      companyPeopleLoading: companyPeopleLoading(state),
      company: company(state),
      profile: profile(state),
      isCompanyPersonProposed: isCompanyPersonProposed(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getCompanyPeople, deleteCompanyPerson }, dispatch)
    })
  ),
  withHandlers({
    onCompanyPersonCreate: ({
      actions,
      match: {
        params: { id }
      },
      profile,
      ok,
      ko
    }) => async () => {
      try {
        await actions.createCompanyPerson({
          id,
          companyPerson: profile.person
            ? { person_id: profile.person.id }
            : {
                person_attributes: {
                  first_name: profile.first_name,
                  last_name: profile.last_name,
                  profile_id: profile.id
                }
              }
        });
        ok(Messages.InvestorUpdateSuccess);
      } catch (err) {
        ko(Messages.InvestorUpdateError);
      }
      await actions.getCompanyPeople({ id });
    },
    onCompanyPersonDelete: ({ actions, company, ok, ko }) => ({
      person: companyPerson
    }) => async () => {
      try {
        await actions.deleteCompanyPerson({ id: companyPerson.id });
        ok(Messages.InvestorUpdateSuccess);
      } catch (err) {
        ko(Messages.InvestorUpdateError);
      }
      await actions.getCompanyPeople({ id: company.id });
    }
  }),
  lifecycle({
    async componentDidMount() {
      const { actions, company } = this.props;
      await actions.getCompanyPeople({ id: company.id });
    }
  }),
  branch(
    ({ companyPeopleLoading }) => companyPeopleLoading,
    renderComponent(() => <LinearProgress />)
  )
);

export default enhance(InvestorTeamTab);
