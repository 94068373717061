import { CustomPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../../ducks/selectors";
import AvatarDataProvider from "../react-grid/AvatarDataProvider";
import FavouriteDataProvider from "../react-grid/FavouriteDataProvider";
import FullNameDataProvider from "../react-grid/FullNameDataProvider";
import LinkRow from "../react-grid/LinkRow";
import PlaceDataProvider from "../react-grid/PlaceDataProvider";
import * as Messages from "./Messages";

const PersonTable = ({ people, isPaginated, page, perPage, total, onSetPage, onSetPerPage }) => {
  const [columns] = useState([
    { name: "avatar_urls", title: Messages.Logo },
    { name: "name", title: Messages.Name },
    {
      name: "company_name",
      title: Messages.Company,
      getCellValue: row => (row.companies ? row.companies[0].name : undefined)
    },
    {
      name: "role",
      title: Messages.Role,
      getCellValue: row => (row.company_people ? row.company_people[0].job_title : undefined)
    },
    {
      name: "place",
      title: Messages.Place,
      getCellValue: row =>
        row.companies
          ? { municipality: row.companies[0].municipality, pv: row.companies[0].pv }
          : undefined
    }
  ]);

  const isAuth = useSelector(isAuthenticated);

  if (isAuth) {
    columns.push({
      name: "favourite_id",
      title: Messages.Favourite
    });
  }

  const showPagination = isPaginated && isAuth;

  return (
    <Grid rows={people} columns={columns}>
      {showPagination && (
        <PagingState
          currentPage={page || 0}
          onCurrentPageChange={onSetPage}
          pageSize={perPage || 10}
          onPageSizeChange={onSetPerPage}
        />
      )}
      {showPagination && <CustomPaging totalCount={total} />}
      <FullNameDataProvider for={["name"]} />
      <AvatarDataProvider for={["avatar_urls"]} />
      <PlaceDataProvider for={["place"]} />
      <FavouriteDataProvider type="person" for={["favourite_id"]} />
      <Table
        rowComponent={props => <LinkRow {...props} linkTo={({ row }) => `/people/${row.id}`} />}
      />
      <TableHeaderRow />
      {showPagination && <PagingPanel pageSizes={[5, 10, 15]} />}
    </Grid>
  );
};

export default PersonTable;
