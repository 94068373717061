/* eslint-disable react/prop-types */
import { makeStyles, Button, Grid, LinearProgress, List, Box } from "@material-ui/core";
import { Field, Form, withFormik } from "formik";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withState } from "recompose";
import { bindActionCreators } from "redux";
import * as yup from "yup";
import { withNotifications } from "../notifications";
import { getCompanies, searchAndImportCompany } from "../ducks/actions";
import { FormPropTypes } from "../shared/formik/prop-types/Form";
import TextField from "../shared/formik/TextField";
import * as Messages from "./Messages";
import { getCompaniesQuery } from "../ducks/selectors";
import CompanyListItem from "./CompanyListItem";
import FailedStartupImportDialog from "../shared/components/FailedStartupImportDialog";

const useStyles = makeStyles(() => ({
  searchList: {
    backgroundColor: "white",
    borderRadius: "5px",
    border: "1px solid #EBEBEB"
  }
}))

const CompanySearch = ({ isSubmitting, isValid, dirty, open, setOpen }) => {
  
  const classes = useStyles();

  const {
    data: { data: companies },
    loading: companiesLoading
  } = useSelector(getCompaniesQuery);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies({ taxCode: "0000000000" }));
  }, [dispatch]);
  

  if (companiesLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Form>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              label={Messages.InsertPiva}
              name="partita_iva"
              type="text"
              fullWidth
              data-testid="company-search-form-partita_iva"
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!dirty || !isValid || isSubmitting}
              type="submit"
            >
              {Messages.SearchStartup}
            </Button>
          </Grid>
        </Grid>
      </Form>
      {companies.length > 0 && <Box dense mt={2} p={2} className={classes.searchList}>
        <List>
          {companies.map(company => (
            <CompanyListItem company={company} />
          ))}
        </List>
      </Box>}

      <FailedStartupImportDialog
            onConfirmClick={() => { /* Do nothing */}}
            open={open}
            setOpen={setOpen}/>
    </>
  );
};

CompanySearch.propTypes = FormPropTypes;

yup.setLocale({
  mixed: {
    required: "validation.FieldRequired"
  },
  string: {
    min: "validation.IvaMinLengthMessage"
  }
});

const validationSchema = yup.object().shape({
  partita_iva: yup
    .string()
    .min(10)
    .required()
});

const enhance = compose(
  withNotifications,
  withRouter,
  connect(undefined, dispatch => ({
    actions: bindActionCreators({ getCompanies, searchAndImportCompany }, dispatch)
  })),
  withState("open", "setOpen", false),
  withFormik({
    mapPropsToValues: () => ({ partita_iva: "" }),
    handleSubmit: async (values, { props: { actions, ko, history, setOpen } }) => {
      try {
        const { response } = await actions.getCompanies({ taxCode: values.partita_iva });
        if (response.data.meta.total === 0) {
          const { response } = await actions.searchAndImportCompany({ id: values.partita_iva });
          if (response.data.success) {
            history.push(`/profile/company_import/confirm/${values.partita_iva}`);
          } else {
            ko(Messages.MissingPiva);
            setOpen(true);
          }
        }
      } catch (err) {
        ko(Messages.SearchStartupFailed);
        setOpen(true);
      }
    },
    validationSchema
  })
);

export default enhance(CompanySearch);
