import qs from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { bindActionCreators } from "redux";
import { confirmCompanyOwner } from "../ducks/actions";
import { withNotifications } from "../notifications";
import * as Messages from "./Messages";

const InvitationPage = () => {
  return null;
};

const enhance = compose(
  withRouter,
  withNotifications,
  connect(undefined, dispatch => ({
    actions: bindActionCreators({ confirmCompanyOwner }, dispatch)
  })),
  lifecycle({
    async componentDidMount() {
      const { actions, location, history, ok, ko } = this.props;
      try {
        await actions.confirmCompanyOwner({
          token: qs.parse(location.search, { arrayFormat: "bracket" }).owner_token
        });
        ok(Messages.ConfirmationSuccess);
      } catch {
        ko(Messages.ConfirmationError);
      }
      history.push("/home");
    }
  })
);

export default enhance(InvitationPage);
