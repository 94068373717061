import { IconButton, Input, InputAdornment, styled } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import qs from "query-string";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

const OutlinedInput = styled(Input)(({ theme }) => ({
  margin: "0px 8px",
  border: "solid 2px",
  borderColor: theme.palette.primary.main,
  borderRadius: 32,
  padding: "8px 4px 8px 24px",
  height: 32
}));

const OutlinedTextField = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  const error = Boolean(currentError) && Boolean(form.touched[field.name]);
  return (
    <FormattedMessage id="search-form.SearchOnUpBase" defaultMessage="Cerca su UpBase">
      {placeholder => (
        <OutlinedInput
          placeholder={placeholder}
          variant="outlined"
          disableUnderline
          error={error}
          {...field}
          {...other}
          endAdornment={
            <InputAdornment>
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      )}
    </FormattedMessage>
  );
};

const SearchForm = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const ref = useRef();

  const queryParams = qs.parse(search, { arrayFormat: "bracket" });

  const handleSubmit = async ({ name }) => {
    ref.current.children[0].blur();
    history.push({
      pathname: "/search",
      search: qs.stringify(
        {
          ...queryParams,
          name,
          page: 1,
          per_page: 10
        },
        { arrayFormat: "bracket" }
      )
    });
  };

  const handleRedirect = () => {
    if (pathname !== "/search") {
      history.push({
        pathname: "/search",
        search: qs.stringify(
          {
            ...queryParams,
            type: "all"
          },
          { arrayFormat: "bracket" }
        )
      });
    }
  };

  return (
    <Formik initialValues={{ name: queryParams.name || "" }} onSubmit={handleSubmit}>
      <Form onClick={handleRedirect}>
        <Field
          component={OutlinedTextField}
          name="name"
          autoFocus={pathname === "/search"}
          innerRef={ref}
          data-testid="search-form"
        />
      </Form>
    </Formik>
  );
};

export default SearchForm;
