import React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";
import Title from "./Title";

const StyledDiv = styled("div")({
  border: "1px solid #EBEBEB",
  borderRadius: 5,
  padding: 24
});

const Body = ({ children }) => <StyledDiv>{children}</StyledDiv>;

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

Body.displayName = "PrivateBody";

Body.Title = Title;
export default Body;
