import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withHandlers } from "recompose";
import { companyCulture, companyCultureLoading, company } from "../ducks/selectors";
import { getCompanyCulture, updateCompanyCulture } from "../ducks/actions";
import { withNotifications } from "../notifications";
import * as Messages from "./Messages";
import CultureEditDialog from "../shared/components/CultureEditDialog";

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    state => ({
      company: company(state),
      companyCulture: companyCulture(state),
      companyCultureLoading: companyCultureLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getCompanyCulture, updateCompanyCulture }, dispatch)
    })
  ),
  withHandlers({
    onCultureEdit: ({
      actions,
      match: {
        params: { id }
      },
      company,
      history,
      ok,
      ko
    }) => async companyCulture => {
      try {
        await actions.updateCompanyCulture({ id: company.id, companyCulture });
        await actions.getCompanyCulture({ id: company.id });
        history.push(`/investors/${id}/culture`);
        ok(Messages.InvestorUpdateSuccess);
      } catch (err) {
        ko(Messages.InvestorUpdateError);
      }
    },
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/investors/${id}/culture`);
    }
  })
);

export default enhance(CultureEditDialog);
