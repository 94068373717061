import { styled, Typography } from "@material-ui/core";
import React from "react";
import MatchingChip from "./MatchingChip";
import * as Messages from "./Messages";

const MatchingTitle = styled(Typography)({
  fontSize: 14,
  textTransform: "uppercase",
  fontWeight: "700"
});

const MatchingContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2)
}));

const MatchingScoreContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const MatchingBox = ({ entity }) => {
  if (!entity.company_investor_matches) {
    return (
      <MatchingContainer>
        <MatchingTitle variant="body2">{Messages.Matching("")}</MatchingTitle>
        <MatchingChip value={0} />
      </MatchingContainer>
    );
  }

  return (
    <MatchingContainer>
      {entity.company_investor_matches.map(el => (
        <MatchingScoreContainer>
          <MatchingTitle variant="body2">{Messages.Matching(el.company?.name)}</MatchingTitle>
          <MatchingChip value={el.score} />
        </MatchingScoreContainer>
      ))}
    </MatchingContainer>
  );
};

export default MatchingBox;
