import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

const CurrencyField = ({ field, form, children, ...other }) => {
  const currentError = form.errors[field.name];
  const error = Boolean(currentError) && Boolean(form.touched[field.name]);
  return (
    <CurrencyTextField
      variant="outlined"
      error={error}
      helperText={error && <FormattedMessage id={currentError} />}
      margin="dense"
      {...field}
      onChange={useCallback((e, value) => form.setFieldValue(field.name, value), [
        field.name,
        form
      ])}
      {...other}
    />
  );
};

CurrencyField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({})
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

CurrencyField.defaultProps = {
  children: null
};

export default CurrencyField;
