/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { Button, styled, Typography } from "@material-ui/core";

const IconImg = styled("img")({
  width: "20%",
  height: 26
});

const LabelTypography = styled(Typography)({
  fontWeight: "normal",
  fontFamily: "urw-din",
  textAlign: "left",
  fontSize: 16,
  lineHeight: "26px",
  width: "80%"
});

const SocialButton = styled(Button)({
  background: "#f9f9f9",
  color: "black",
  borderRadius: 5,
  border: "1px solid #EBEBEB",
  marginBottom: 32
});
const SocialLoginButton = ({ onClick, icon, text, ...other }) => {
  return (
    <SocialButton fullWidth onClick={onClick} {...other}>
      <IconImg src={`images/${icon}-icon.svg`} />
      <LabelTypography>{text}</LabelTypography>
    </SocialButton>
  );
};

export default SocialLoginButton;
