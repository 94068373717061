import { IconButton, Input, InputAdornment, makeStyles, withStyles } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Field, Form, withFormik } from "formik";
import qs from "query-string";
import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    justifyContent: "center"
  },
  searchField: {
    margin: 0
  }
}));

const OutlinedTextField = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  const error = Boolean(currentError) && Boolean(form.touched[field.name]);
  return (
    <FormattedMessage id="search-upbase.findonupbase" defaultMessage="Cerca su UpBase">
      {placeholder => (
        <Input
          placeholder={placeholder}
          variant="outlined"
          disableUnderline
          error={error}
          helperText={error && <FormattedMessage id={currentError} />}
          {...field}
          {...other}
          endAdornment={
            <InputAdornment>
              <IconButton onClick={form.handleSubmit}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      )}
    </FormattedMessage>
  );
};

const SearchField = withStyles(theme => ({
  root: {
    border: "solid 2px",
    borderColor: theme.palette.primary.main,
    borderRadius: "32px",
    padding: "8px 4px 8px 24px",
    width: "65%"
  }
}))(OutlinedTextField);

const SearchUpBaseForm = () => {
  const styles = useStyles();

  return (
    <Form className={styles.form}>
      <Field
        className={styles.searchField}
        component={SearchField}
        name="name"
        data-testid="search-upbase"
      />
    </Form>
  );
};

const enhance = compose(
  withRouter,
  withFormik({
    mapPropsToValues: () => ({ name: "" }),
    handleSubmit: async ({ name }, { props: { history } }) => {
      history.push({
        pathname: "/search",
        search: qs.stringify({ type: "all", name }, { arrayFormat: "bracket" })
      });
    }
  })
);

export default enhance(SearchUpBaseForm);
