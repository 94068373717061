import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Team = <FormattedMessage id="company.Team" defaultMessage="Team" />;
export const Funding = <FormattedMessage id="company.Funding" defaultMessage="Funding" />;
export const ShowProfile = (
  <FormattedMessage id="company.ShowProfile" defaultMessage="Visita profilo" />
);
export const CompanyUpdateSuccess = (
  <FormattedMessage id="company.CompanyUpdateSuccess" defaultMessage="Startup updated" />
);
export const CompanyUpdateError = (
  <FormattedMessage id="company.CompanyUpdateError" defaultMessage="Cannot update startup" />
);
export const AddRound = <FormattedMessage id="funding.AddRound" defaultMessage="Aggiungi" />;
export const EditRound = <FormattedMessage id="funding.EditRound" defaultMessage="Modifica" />;
export const RoundUpdateSuccess = (
  <FormattedMessage id="funding.RoundUpdateSuccess" defaultMessage="Round updated" />
);
export const RoundUpdateError = (
  <FormattedMessage id="funding.RoundUpdateError" defaultMessage="Cannot update round" />
);
export const RoundCreateSuccess = (
  <FormattedMessage id="funding.RoundCreateSuccess" defaultMessage="Round created" />
);
export const RoundCreateError = (
  <FormattedMessage id="funding.RoundCreateError" defaultMessage="Cannot create round" />
);
export const DeleteRoundSuccess = (
  <FormattedMessage id="funding.DeleteRoundSuccess" defaultMessage="Round deleted" />
);
export const DeleteRoundError = (
  <FormattedMessage id="funding.DeleteRoundError" defaultMessage="Cannot delete round" />
);

export const NoFundingData = (
  <FormattedMessage id="funding.NoFundingData" defaultMessage="Non ci sono raccolte disponibili" />
);
export const InsertFundingData = (
  <FormattedMessage
    id="funding.InsertFundingData"
    defaultMessage="Inserisci i dati sulle tue raccolte fondi"
  />
);
export const FundingAmount = (
  <FormattedMessage id="funding.FundingAmount" defaultMessage="Funding Amount" />
);
export const NumberOfFundingRounds = (
  <FormattedMessage id="funding.NumberOfFundingRounds" defaultMessage="Number of Funding Rounds" />
);
export const Round = <FormattedMessage id="funding.Round" defaultMessage="Round" />;
export const Investor = <FormattedMessage id="funding.Investor" defaultMessage="Investor" />;
export const People = <FormattedMessage id="funding.People" defaultMessage="Persone" />;
export const IncubatorsAndAccelerators = (
  <FormattedMessage
    id="funding.IncubatorsAndAccelerators"
    defaultMessage="Incubatori e Acceleratori"
  />
);
export const InvestorsPlaceholder = (
  <FormattedMessage
    id="funding.InvestorsPlaceholder"
    defaultMessage="Cerca e aggiungi gli investitori"
  />
);
export const Save = <FormattedMessage id="funding.Save" defaultMessage="Salva" />;
export const Undisclosed = (
  <FormattedMessage id="funding.Undisclosed" defaultMessage="Undisclosed" />
);
