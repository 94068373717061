import React from "react";
import SocialLoginButton from "../shared/components/SocialLoginButton";
import * as Messages from "./Messages";
import config from "../config";

const baseUrl = config.apiBaseUrl;

const LinkedInLogin = () => {
  return (
    <SocialLoginButton
      icon="linkedIn"
      text={Messages.SignInWithLinkedIn}
      href={`${baseUrl}/auth/linkedin`}
    />
  );
};

export default LinkedInLogin;
