import React from "react";
import PropTypes from "prop-types";
import { compose, lifecycle, renderComponent, branch } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, LinearProgress, Box } from "@material-ui/core";

import { getNews } from "../ducks/actions";
import { news, newsLoading } from "../ducks/selectors";
import NewsCard from "./NewsCard";

const NewsSection = ({ news }) => {
  return (
    <Grid container justify="center" spacing={4}>
      {news.map(el => (
        <Grid item xs={12} sm={4}>
          <NewsCard imgUrl={el.img_url} title={el.title} categories={el.categories} url={el.url} />
        </Grid>
      ))}
    </Grid>
  );
};

NewsSection.propTypes = {
  news: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const enhance = compose(
  connect(
    state => ({
      news: news(state),
      newsLoading: newsLoading(state)
    }),
    dispatch => ({ actions: bindActionCreators({ getNews }, dispatch) })
  ),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getNews({ source: "all", page: 1, perPage: 3 });
    }
  }),
  branch(
    ({ newsLoading }) => newsLoading,
    renderComponent(() => (
      <Box margin="16px 0">
        <LinearProgress />
      </Box>
    ))
  )
);

export default enhance(NewsSection);
