/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: "solid 1px",
    borderColor: theme.palette.secondary.dark,
    borderRadius: 4,
    margin: "16px 0",
    padding: 8
  },
  editor: {
    minHeight: 120
  }
}));

const MarkdownField = ({ field, form, ...other }) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    setEditorState(EditorState.createWithContent(convertFromRaw(markdownToDraft(field.value))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditorChange = useCallback(
    editorState => {
      setEditorState(editorState);
      form.setFieldValue(
        field.name,
        draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
      );
    },
    [field.name, form]
  );
  return (
    <Editor
      wrapperClassName={classes.wrapper}
      editorClassName={classes.editor}
      editorState={editorState}
      onEditorStateChange={handleEditorChange}
      {...other}
    />
  );
};

MarkdownField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({})
  }).isRequired
};

MarkdownField.defaultProp = {
  value: ""
};
export default MarkdownField;
