/* eslint-disable no-shadow */
import { isEmpty, reduce, sortBy } from "lodash";
import { getMutationSelector, getQuerySelector } from "redux-saga-requests";
import { createSelector } from "reselect";
import * as types from "./types";

export const isAuthenticated = state => state.auth.isAuthenticated;

const interestQuery = getQuerySelector({
  type: types.GET_INTERESTS,
  defaultData: { data: [], meta: {} }
});

export const interests = createSelector(interestQuery, query => {
  return query.data.data;
});

export const interestsLoading = createSelector(interestQuery, query => {
  return query.loading;
});

export const getProfileQuery = getQuerySelector({
  type: types.GET_PROFILE,
  defaultData: { data: {}, meta: {} }
});

export const profile = createSelector(getProfileQuery, query => {
  return query.data.data;
});

export const profileLoading = createSelector(getProfileQuery, query => {
  return query.loading;
});

export const avatarUrl = createSelector(profile, data => {
  return data.avatar_urls ? data.avatar_urls.small : "";
});

export const avatarMutation = getMutationSelector({
  type: types.UPDATE_AVATAR
});

export const avatarLoading = createSelector(avatarMutation, ({ loading }) => loading);

export const myNewsQuery = getQuerySelector({
  type: types.GET_MY_NEWS,
  defaultData: { data: [], meta: {} }
});

export const myNews = createSelector(myNewsQuery, query => {
  return query.data.data;
});

export const myNewsTotal = createSelector(myNewsQuery, query => {
  return query.data.meta.total;
});

export const myNewsLoading = createSelector(myNewsQuery, query => {
  return query.loading;
});

export const newsQuery = getQuerySelector({
  type: types.GET_NEWS,
  defaultData: { data: [], meta: {} }
});

export const news = createSelector(newsQuery, query => {
  return query.data.data;
});

export const newsTotal = createSelector(newsQuery, query => {
  return query.data.meta.total;
});

export const newsLoading = createSelector(newsQuery, query => {
  return query.loading;
});

export const searchResultsQuery = getQuerySelector({
  type: types.SEARCH,
  defaultData: { data: [], meta: {} }
});

export const searchResults = createSelector(searchResultsQuery, query => query.data.data);

export const searchResultsLoading = createSelector(searchResultsQuery, query => query.loading);

export const searchResultsTotal = createSelector(
  searchResultsQuery,
  query => ((query.data || {}).meta || {}).total || 0
);

export const getCompanyQuery = getQuerySelector({
  type: types.GET_COMPANY,
  defaultData: { data: {}, meta: {} }
});

export const company = createSelector(getCompanyQuery, query => query.data.data);

export const companyLoading = createSelector(getCompanyQuery, query => query.loading);

export const ownedCompanies = ({ state }) =>
  createSelector(profile, p => {
    return ((p.user || {}).company_owners || [])
      .filter(co => co.aasm_state === state)
      .map(({ company }) => company);
  });

export const ownedCompanyIds = ({ state }) =>
  createSelector(ownedCompanies({ state }), companies => {
    return companies.map(x => x.id);
  });

export const getCompanyCultureQuery = getQuerySelector({
  type: types.GET_COMPANY_CULTURE,
  defaultData: { data: {}, meta: {} }
});

export const companyCulture = createSelector(getCompanyCultureQuery, query => query.data.data);

export const companyCultureLoading = createSelector(getCompanyCultureQuery, query => query.loading);

export const getCompanyFundingRoundsQuery = getQuerySelector({
  type: types.GET_COMPANY_FUNDING_ROUNDS,
  defaultData: { data: [], meta: {} }
});

export const companyFundingRoundsLoading = createSelector(
  getCompanyFundingRoundsQuery,
  query => query.loading
);

export const companyFundingRounds = createSelector(getCompanyFundingRoundsQuery, query => {
  return query.data.data;
});

export const totalFunding = createSelector(companyFundingRounds, rounds => {
  if (isEmpty(rounds)) return "";

  const amount = rounds.map(p => p.amount.cents).reduce((p, c) => p + c, 0) / 100;
  return amount;
});

export const isCompanyOwned = ({ companyId, state }) =>
  createSelector(ownedCompanyIds({ state }), company, (ids, company) => {
    if (!Number.isNaN(companyId)) {
      return ids.includes(companyId);
    }
    return ids.includes(company.id);
  });

export const roundById = roundId =>
  createSelector(companyFundingRounds, rounds => {
    const round = rounds.find(x => x.id === roundId);
    if (!round) {
      return {
        status_description: "",
        date: null,
        amount: "0"
      };
    }

    const { amount, ...rest } = round;

    return { ...rest, amount: amount.cents / 100 };
  });

export const getCompanyPeopleQuery = getQuerySelector({
  type: types.GET_COMPANY_PEOPLE,
  defaultData: { data: [], meta: {} }
});

export const companyPeople = createSelector(getCompanyPeopleQuery, query => query.data.data);

export const companyPersonById = id =>
  createSelector(getCompanyPeopleQuery, query => query.data.data.find(person => person.id === id));

export const companyPeopleMember = createSelector(
  getCompanyPeopleQuery,
  getProfileQuery,
  ownedCompanyIds({ state: "accepted" }),
  ({ data: { data: companyPeople } }, { data: { data: profile } }, companyIds) =>
    companyPeople.filter(
      companyPerson =>
        companyIds.includes(companyPerson.company.id) ||
        ["accepted", "pending"].includes(companyPerson.aasm_state) ||
        companyPerson.person.id === profile.person?.id
    )
);

export const companyPeopleLoading = createSelector(getCompanyPeopleQuery, query => query.loading);
export const getPersonQuery = getQuerySelector({
  type: types.GET_PERSON,
  defaultData: { data: {}, meta: {} }
});

export const person = createSelector(getPersonQuery, query => query.data.data);

export const personLoading = createSelector(getPersonQuery, query => query.loading);

export const getCompanyInvestorsQuery = getQuerySelector({
  type: types.GET_COMPANY_INVESTORS,
  defaultData: { data: [], meta: {} }
});

export const companyInvestors = createSelector(getCompanyInvestorsQuery, query => query.data.data);

export const companyInvestorsLoading = createSelector(
  getCompanyInvestorsQuery,
  query => query.loading
);

export const isPersonClaimed = id =>
  createSelector(profile, data => {
    return data.person && data.person.id === id;
  });

export const isMyPerson = createSelector(profile, person, (profileObj, personObj) => {
  return profileObj.person && profileObj.person.id === personObj.id;
});

export const getInvestorQuery = getQuerySelector({
  type: types.GET_INVESTOR,
  defaultData: { data: {}, meta: {} }
});

export const investor = createSelector(getInvestorQuery, query => query.data.data);

export const investorLoading = createSelector(getInvestorQuery, query => query.loading);

export const isInvestorOwned = ({ investorId, state }) =>
  createSelector(
    ownedCompanyIds({ state }),
    profile,
    investor,
    (ids, profile, investor) =>
      investor.id === investorId &&
      (ids.includes(investor.investorable?.id) ||
        (profile.person && profile.person.id === investor.investorable?.id))
  );

export const getInvestorFundingRoundsQuery = getQuerySelector({
  type: types.GET_INVESTOR_FUNDING_ROUNDS,
  defaultData: { data: [], meta: {} }
});

export const investorFundingRoundsLoading = createSelector(
  getInvestorFundingRoundsQuery,
  query => query.loading
);

export const investorFundingRounds = createSelector(getInvestorFundingRoundsQuery, query => {
  const rounds = sortBy(query.data.data || [], x => x.company_funding_round.date).reverse();

  return reduce(
    rounds,
    (result, value) => {
      if (
        !result.find(
          res => value.company_funding_round.company.id === res.company_funding_round.company.id
        )
      ) {
        result.push(value);
      }
      return result;
    },
    []
  );
});

export const investorRoundById = roundId =>
  createSelector(investorFundingRounds, rounds => {
    const round = rounds.find(x => x.id === roundId);
    if (!round) return {};

    const { amount, ...rest } = round.company_funding_round;

    return { ...round, company_funding_round: { ...rest, amount: amount.cents / 100 } };
  });

export const isCompanyPersonProposed = createSelector(
  getCompanyPeopleQuery,
  getProfileQuery,
  ({ data: { data: companyPeople } }, { data: { data: profile } }) => {
    const myCompanyPerson = companyPeople.find(
      cp => profile.person && cp.person.id === profile.person.id
    );
    return myCompanyPerson && myCompanyPerson.aasm_state === "proposed";
  }
);

export const getCompanyImportQuery = getQuerySelector({
  type: types.GET_COMPANY_IMPORT,
  defaultData: { data: {}, meta: {} }
});

export const companyImport = createSelector(getCompanyImportQuery, query => query.data.data);

export const getInterestsTagsQuery = getQuerySelector({
  type: types.GET_INTERESTS_TAGS,
  defaultData: { data: [], meta: {} }
});

export const interestsTags = createSelector(getInterestsTagsQuery, query => query.data.data);

export const getCompaniesQuery = getQuerySelector({
  type: types.GET_COMPANIES,
  defaultData: { data: [], meta: {} }
});

export const getPeopleQuery = getQuerySelector({
  type: types.GET_PEOPLE,
  defaultData: { data: [], meta: {} }
});

export const getInvestorsQuery = getQuerySelector({
  type: types.GET_INVESTORS,
  defaultData: { data: [], meta: {} }
});

export const getIndustriesQuery = getQuerySelector({
  type: types.GET_INDUSTRIES,
  defaultData: { data: [], meta: {} }
});

export const industries = createSelector(getIndustriesQuery, query => query.data.data);

export const getIndustriesTagsQuery = getQuerySelector({
  type: types.GET_INDUSTRIES_TAGS,
  defaultData: { data: [], meta: {} }
});

export const industriesTags = createSelector(getIndustriesTagsQuery, query => query.data.data);

export const getIndustryQuery = getQuerySelector({
  type: types.GET_INDUSTRY,
  defaultData: { data: {}, meta: {} }
});

export const getFavouritesQuery = getQuerySelector({
  type: types.GET_FAVOURITES,
  defaultData: { data: [], meta: {} }
});
