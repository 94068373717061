import PropTypes from "prop-types";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { auth } from "../firebase";
import { withNotifications } from "../notifications";
import { logout } from "../ducks/actions";
import * as Messages from "./Messages";

const Logout = () => {
  return null;
};

Logout.propTypes = {
  actions: PropTypes.shape({
    logout: PropTypes.func
  }).isRequired
};

const enhance = compose(
  withNotifications,
  connect(undefined, dispatch => ({ actions: bindActionCreators({ logout }, dispatch) })),
  lifecycle({
    async componentDidMount() {
      const { ok, actions } = this.props;
      try {
        await auth.getAuth().signOut();
        await actions.logout();
        ok(Messages.LoggedOut);
        window.location.href = "/";
      } catch (err) {
        console.warn(err);
      }
    }
  })
);

export default enhance(Logout);
