import React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core";

const StyledDiv = styled("div")({
  padding: 16
});

const Content = ({ children }) => <StyledDiv>{children}</StyledDiv>;

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

Content.displayName = "BodyContent";

export default Content;
