import React from "react";
import { compose } from "recompose";
import { Form, Field, withFormik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import * as yup from "yup";

import TextField from "../shared/formik/TextField";
import * as Messages from "./Messages";
import { withNotifications } from "../notifications";
import { FormPropTypes } from "../shared/formik/prop-types/Form";

const ChangePasswordForm = ({ isSubmitting, isValid, dirty }) => (
  <Form>
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.Password}
          name="password"
          type="password"
          fullWidth
          data-testid="change-password-form-password"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          label={Messages.PasswordConfirmation}
          name="passwordConfirmation"
          type="password"
          fullWidth
          data-testid="change-password-form-password"
        />
      </Grid>
      <Grid item xs={5}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
        >
          {Messages.Continue}
        </Button>
      </Grid>
    </Grid>
  </Form>
);

ChangePasswordForm.propTypes = FormPropTypes;

yup.setLocale({
  mixed: {
    required: "validation.FieldRequired"
  }
});

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "validation.MatchPassword")
});

const enhance = compose(
  withNotifications,
  withRouter,
  connect(undefined, dispatch => bindActionCreators({}, dispatch)),
  withFormik({
    mapPropsToValues: () => ({ password: "", passwordConfirmation: "" }),
    handleSubmit: async (values, { props: { changePassword, ko, history } }) => {
      try {
        await changePassword(values);
        history.push("/login?cm=ChangePassword");
      } catch (err) {
        ko("<unknown>");
      }
    },
    validationSchema
  })
);

export default enhance(ChangePasswordForm);
