import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import * as Messages from "./Messages";

const useNoResultStyle = makeStyles(theme => ({
  img: {
    height: 320,
    width: 320,
    marginTop: theme.spacing(5)
  }
}));
const NoResults = () => {
  const classes = useNoResultStyle();
  return (
    <Box flexDirection="column" display="flex" px={20} py={8} alignItems="center">
      <Typography variant="h5" align="center">
        {Messages.noSearchResults}
      </Typography>
      <img className={classes.img} src="images/no-results.svg" alt="no-results" />
    </Box>
  );
};

export default NoResults;
