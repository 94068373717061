import { Grid, LinearProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPerson } from "../ducks/actions";
import { getPersonQuery } from "../ducks/selectors";
import { SearchLayout } from "../layouts/search";
import ContactBox from "../shared/components/ContactBox";
import Header from "../shared/components/Header";
import OutlinedDiv from "../shared/components/OutlinedDiv";
import Overview from "./Overview";

const PersonPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPerson({ id }));
  }, [id, dispatch]);

  const {
    data: { data: person },
    loading
  } = useSelector(getPersonQuery);

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <SearchLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <OutlinedDiv>
            <Header entity={person} isCompany={false} />
            <Overview />
          </OutlinedDiv>
        </Grid>
        <Grid item xs={12} md={3}>
          <OutlinedDiv>
            <ContactBox entity={person} />
          </OutlinedDiv>
        </Grid>
      </Grid>
    </SearchLayout>
  );
};

export default PersonPage;
