/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { company, companyLoading, isCompanyOwned } from "../ducks/selectors";
import OverviewTab from "../shared/components/OverviewTab";
import OverviewEdit from "./OverviewEdit";

const CompanyOverviewTab = ({ company, isCompanyOwned }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/edit`} component={OverviewEdit} />
      </Switch>
      <OverviewTab company={company} isCompanyOwned={isCompanyOwned} isStartup />
    </>
  );
};

const enhance = connect((state, { match: { params: { id } } }) => ({
  company: company(state),
  companyLoading: companyLoading(state),
  isCompanyOwned: isCompanyOwned({ companyId: id, state: "accepted" })(state)
}));

export default enhance(CompanyOverviewTab);
