import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Link as MUILink,
  styled,
  Typography,
  withStyles
} from "@material-ui/core";
import { BusinessCenter as BusinessCenterIcon, Star, StarBorder } from "@material-ui/icons";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { createFavourite, deleteFavourite } from "../../ducks/actions";
import { isAuthenticated } from "../../ducks/selectors";
import { useNotifications } from "../../notifications";
import CreateOwnerDialog from "./CreateOwnerDialog";
import * as Messages from "./Messages";
import withTooltip from "./withTooltip";

const Logo = styled(BusinessCenterIcon)({
  height: 48,
  width: 48
});

const LogoAvatar = styled(Avatar)({
  height: 64,
  width: 64,
  margin: "8px 16px 8px 8px"
});

const Title = styled(Typography)({
  fontFamily: "aktiv-grotesk",
  fontWeight: 900,
  lineHeight: "1.2",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
});

const GreenTypography = styled(Typography)(() => ({
  color: "#03BD30",
  fontWeight: 700,
  margin: "0 8px"
}));

const TagChip = styled(Chip)({
  margin: 2,
  borderRadius: 8,
  backgroundColor: "#EBF5FF",
  color: "#43BDCC",
  textTransform: "uppercase"
});

const SmallButton = withStyles({
  label: {
    padding: 0
  }
})(Button);

const IndustryContainer = styled("div")({
  display: "flex",
  "& > *": {
    marginRight: 16
  }
});

const TagListContainer = styled("div")({
  marginTop: 8,
  padding: 8
});

const Header = ({
  canRequestOwnership,
  isOwnershipPending,
  entity,
  isCompany,
  isInvestor,
  investor
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ok, ko } = useNotifications();

  const [ownershipModalOpen, setOwnershipModalOpen] = useState(false);
  const onOwnershipModalOpen = () => {
    setOwnershipModalOpen(true);
  };
  const onOwnershipModalClose = () => {
    setOwnershipModalOpen(false);
  };

  const onCreateFavourite = async () => {
    const entityType = isCompany ? "company" : "person";
    try {
      await dispatch(createFavourite({ id, type: isInvestor ? "investor" : entityType }));
      ok(Messages.CreateFavouriteSuccess);
    } catch {
      ko(Messages.CreateFavouriteError);
    }
  };

  const onDeleteFavourite = async () => {
    try {
      await dispatch(
        deleteFavourite({ id: isInvestor ? investor.favourite_id : entity.favourite_id })
      );
      ok(Messages.DeleteFavouriteSuccess);
    } catch {
      ko(Messages.DeleteFavouriteError);
    }
  };

  const isAuth = useSelector(isAuthenticated);

  return (
    <>
      <Grid container alignItems="center" justify="center" spacing={2}>
        <Grid item xs={12} md={9}>
          <Box display="flex" flexDirection="row" alignItems="flex-top">
            {isCompany ? (
              <LogoAvatar src={entity.logo_urls?.small} alt={entity.name} variant="rounded">
                <Logo />
              </LogoAvatar>
            ) : (
              <LogoAvatar
                src={entity.avatar_urls?.small}
                alt={`${entity.first_name} ${entity.last_name}`}
                variant="rounded"
              />
            )}
            <Box display="flex" flexDirection="column" minWidth={0}>
              <Title variant="h6">
                {isCompany
                  ? entity.project_name || entity.name
                  : `${entity.first_name} ${entity.last_name}`}
              </Title>
              <IndustryContainer>
                {_.chain(entity.industry_industriables)
                  .sortBy("industry.name")
                  .concat(
                    !isCompany
                      ? _.chain(entity.company_people)
                          .map(({ company }) => company.industry_industriables || [])
                          .flatten()
                          .value()
                      : []
                  )
                  .uniqBy(industriable => industriable.industry.id)
                  .map(({ industry }) => (
                    <Typography key={industry.id} color="primary">
                      {industry.name}
                    </Typography>
                  ))
                  .value()}
              </IndustryContainer>
              {!isCompany && (
                <>
                  <Typography>
                    {entity.company_person && entity.company_person.job_title}
                  </Typography>
                  {_.chain(entity.company_people)
                    .filter(({ aasm_state: aasmState }) => aasmState !== "proposed")
                    .map(({ company }) => (
                      <Typography key={company.id}>
                        <MUILink component={Link} to={`/companies/${company.id}/overview`}>
                          {`@${_.truncate(company.project_name || company.name, { length: 40 })}`}
                        </MUILink>
                      </Typography>
                    ))
                    .value()}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <GreenTypography variant="body2">
            {entity.profile_completion_percent}
            {isCompany
              ? Messages.StartupProfileCompletePercentage
              : Messages.PersonProfileCompletePercentage}
          </GreenTypography>
        </Grid>
        {isAuth && (
          <Grid item xs={12} container justify="flex-end" spacing={1}>
            {isCompany && (canRequestOwnership || isOwnershipPending) && (
              <Grid item xs={12} md={3}>
                {withTooltip({ visible: isOwnershipPending, title: Messages.AcceptRequest })(
                  <SmallButton
                    color="primary"
                    variant="outlined"
                    disabled={isOwnershipPending}
                    onClick={onOwnershipModalOpen}
                    fullWidth
                  >
                    <Typography>{Messages.RequestOwnership}</Typography>
                  </SmallButton>
                )}
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <SmallButton
                color="primary"
                variant="outlined"
                onClick={entity.favourite_id ? onDeleteFavourite : onCreateFavourite}
                fullWidth
              >
                {entity.favourite_id ? <Star /> : <StarBorder />}
                <Typography>{Messages.Favourite}</Typography>
              </SmallButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <TagListContainer>
        {isCompany
          ? _.chain(entity.tag_list)
              .sort()
              .uniq()
              .map(tag => <TagChip key={tag} label={tag} color="primary" size="small" m={4} />)
              .value()
          : _.chain(entity.company_people)
              .map(({ company }) => company.tag_list || [])
              .flatten()
              .concat(entity.tag_list)
              .uniq()
              .sort()
              .map(tag => <TagChip key={tag} label={tag} color="primary" size="small" m={4} />)
              .value()}
      </TagListContainer>
      {isAuth && isCompany && canRequestOwnership && (
        <CreateOwnerDialog
          open={ownershipModalOpen}
          entity={entity}
          onClose={onOwnershipModalClose}
        />
      )}
    </>
  );
};

export default Header;
