/* eslint-disable react/prop-types */
import React from "react";
import { withRouter, Link as RRDLink } from "react-router-dom";
import { Button, Grid, LinearProgress, Link } from "@material-ui/core";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withHandlers, branch, renderComponent, lifecycle } from "recompose";
import { withNotifications } from "../notifications";
import { profile, investor, profileLoading } from "../ducks/selectors";
import { createInvestor, deleteInvestor, getInvestor, getProfile } from "../ducks/actions";
import * as Messages from "./Messages";

const InvestorForm = ({ profile, investor, onCreateInvestor, onDeleteInvestor }) => {
  return (profile.person || {}).investor_id ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Link component={RRDLink} to={`/investors/${profile.person.investor_id}`}>
          {Messages.ViewInvestorPage}
        </Link>
      </Grid>
      {!investor.company_funding_rounds && (
        <Grid item xs={12}>
          <Button onClick={onDeleteInvestor} variant="outlined" color="primary">
            {Messages.DeleteInvestor}
          </Button>
        </Grid>
      )}
    </Grid>
  ) : (
    <Button onClick={onCreateInvestor} variant="outlined" color="primary">
      {Messages.BecomeInvestor}
    </Button>
  );
};

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    state => ({
      profile: profile(state),
      investor: investor(state),
      profileLoading: profileLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        { createInvestor, deleteInvestor, getInvestor, getProfile },
        dispatch
      )
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions, profile } = this.props;
      await actions.getInvestor({ id: profile.person?.investor_id });
    }
  }),
  withHandlers({
    onCreateInvestor: ({ actions, history, ok, ko, profile }) => async () => {
      try {
        await actions.createInvestor({
          investor: {
            investorable_type: "Person",
            investorable_attributes: {
              profile_id: profile.id,
              first_name: profile.first_name,
              last_name: profile.last_name
            },
            name: `${profile.first_name} ${profile.last_name}`
          }
        });
        const res = await actions.getProfile();
        history.push(`/investors/${res.response.data.data.person.investor_id}/funding/create`);
        ok(Messages.InvestorCreateSuccess);
      } catch {
        ko(Messages.InvestorCreateError);
      }
    },
    onDeleteInvestor: ({ actions, ok, ko, profile }) => async () => {
      try {
        await actions.deleteInvestor({ id: profile.person.investor_id });
        await actions.getProfile();
        ok(Messages.DeleteInvestorSuccess);
      } catch (err) {
        ko(Messages.DeleteInvestorError);
      }
    }
  }),
  branch(
    ({ profileLoading }) => profileLoading,
    renderComponent(() => <LinearProgress />)
  )
);

export default enhance(InvestorForm);
