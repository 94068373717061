/* eslint-disable react/prop-types */
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { compose, withHandlers, withState } from "recompose";
import { investor, isInvestorOwned, isMyPerson, person, personLoading } from "../ducks/selectors";
import { withNotifications } from "../notifications";
import { deleteInvestor } from "../ducks/actions";
import PersonOverview from "../shared/components/PersonOverview";
import PersonOverviewEdit from "./PersonOverviewEdit";
import * as Messages from "./Messages";

const PersonOverviewTab = ({
  person,
  isPersonClaimed,
  isInvestorOwned,
  investor,
  onDelete,
  open,
  setOpen
}) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/edit`} component={PersonOverviewEdit} />
      </Switch>
      <PersonOverview
        person={person}
        isPersonClaimed={isPersonClaimed}
        isInvestor={isInvestorOwned}
        investor={investor}
        onDelete={onDelete}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

const enhance = compose(
  withNotifications,
  withRouter,
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      person: person(state),
      personLoading: personLoading(state),
      isPersonClaimed: isMyPerson(state),
      isInvestorOwned: isInvestorOwned({ investorId: id, state: "accepted" })(state),
      investor: investor(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ deleteInvestor }, dispatch)
    })
  ),
  withState("open", "setOpen", false),
  withHandlers({
    onDelete: ({ ok, ko, setOpen, actions, history }) => async ({ id }) => {
      try {
        await actions.deleteInvestor({ id });
        ok(Messages.DeleteInvestorSuccess);
        setOpen(false);
        history.push("/home");
      } catch (err) {
        ko(Messages.DeleteInvestorError);
      }
    }
  })
);

export default enhance(PersonOverviewTab);
