import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { getInvestorQuery } from "../ducks/selectors";
import CompanyOverviewTab from "./CompanyOverviewTab";
import CultureTab from "./CultureTab";
import FundingTab from "./FundingTab";
import PersonOverviewTab from "./PersonOverviewTab";
import TabLayout from "./TabLayout";
import TeamTab from "./TeamTab";

const InvestorPage = () => {
  const { path, url } = useRouteMatch();
  const {
    data: { data: investor }
  } = useSelector(getInvestorQuery);

  const isCompany = investor.investorable?.type === "company";

  return (
    <TabLayout>
      <Switch>
        <Route
          path={`${path}/overview`}
          component={isCompany ? CompanyOverviewTab : PersonOverviewTab}
        />
        <Route path={`${path}/team`} component={TeamTab} />
        <Route path={`${path}/culture`} component={CultureTab} />
        <Route path={`${path}/funding`} component={FundingTab} />
        <Redirect to={`${url}/overview`} />
      </Switch>
    </TabLayout>
  );
};

export default InvestorPage;
