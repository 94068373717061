import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const ConfirmationSuccess = (
  <FormattedMessage
    id="confirm-ownership.ConfirmationSuccess"
    defaultMessage="Proprietà confermata"
  />
);
export const ConfirmationError = (
  <FormattedMessage
    id="confirm-ownership.ConfirmationError"
    defaultMessage="Si è verificato un problema"
  />
);
