import { Box, styled, Typography } from "@material-ui/core";
import React from "react";
import ReactPlayer from 'react-player/lazy';
import { useRouteMatch } from "react-router-dom";
import FabButton from "./FabButton";
import * as Messages from "./Messages";

const PlayerDiv = styled("div")({
  marginTop: 64,
  position: "relative",
  paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
});

const ResponsiveReactPlayer = styled(ReactPlayer)({
  position: "absolute",
  top: 0,
  left: 0,
  controls: true
});

const CultureTab = ({ companyCulture, isCompanyOwned }) => {
  const { url } = useRouteMatch();
  return (
    <Box p={2}>
      <FabButton show={isCompanyOwned} to={`${url}/edit`} />
      {companyCulture.title || companyCulture.description || companyCulture.video_url ? (
        <>
          <Typography variant="h6">
            <strong>
              {companyCulture.title || (!companyCulture.video_url && Messages.NotDescribed)}
            </strong>
          </Typography>
          <Typography variant="body2">
            {companyCulture.description || (!companyCulture.video_url && Messages.NotDescribed)}
          </Typography>
          <PlayerDiv>
            <ResponsiveReactPlayer url={companyCulture.video_url} width="100%" height="100%" />
          </PlayerDiv>
        </>
      ) : (
        <Typography variant="body1">
          <strong>{Messages.EmptyCulture}</strong>
        </Typography>
      )}
    </Box>
  );
};

export default CultureTab;
