import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { IconButton } from "@material-ui/core";
import { Star as StarIcon, StarBorder as StarBorderIcon } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { createFavourite, deleteFavourite } from "../../ducks/actions";
import { useNotifications } from "../../notifications";
import * as Messages from "../components/Messages";

const FavouriteDataProvider = ({ type, ...props }) => {
  const dispatch = useDispatch();
  const { ok, ko } = useNotifications();
  const onCreateFavourite = ({ id }) => async e => {
    e.stopPropagation();
    try {
      await dispatch(createFavourite({ id, type }));
      ok(Messages.CreateFavouriteSuccess);
    } catch {
      ko(Messages.CreateFavouriteError);
    }
  };

  const onDeleteFavourite = ({ id }) => async e => {
    e.stopPropagation();
    try {
      await dispatch(deleteFavourite({ id }));
      ok(Messages.DeleteFavouriteSuccess);
    } catch {
      ko(Messages.DeleteFavouriteError);
    }
  };
  return (
    <DataTypeProvider
      {...props}
      formatterComponent={({ value, row }) => {
        return (
          <IconButton
            color="primary"
            onClick={value ? onDeleteFavourite({ id: value }) : onCreateFavourite({ id: row.id })}
          >
            {value ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>
        );
      }}
    />
  );
};
export default FavouriteDataProvider;
