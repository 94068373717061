import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const SearchIn = <FormattedMessage id="search.SearchIn" defaultMessage="Cerca in" />;
export const StartUp = <FormattedMessage id="search.StartUp" defaultMessage="Startup" />;
export const People = <FormattedMessage id="search.People" defaultMessage="Persone" />;
export const Investors = <FormattedMessage id="search.Investors" defaultMessage="Investitori" />;
export const Description = (
  <FormattedMessage id="search.Description" defaultMessage="Descrizione" />
);
export const Place = <FormattedMessage id="search.Place" defaultMessage="Sede" />;
export const PIVA = <FormattedMessage id="search.PIVA" defaultMessage="Partita IVA" />;
export const Sector = <FormattedMessage id="search.Sector" defaultMessage="Settore" />;
export const Filter = <FormattedMessage id="search.Filter" defaultMessage="Applica filtri" />;
export const Region = <FormattedMessage id="search.Region" defaultMessage="Regione" />;
export const Province = <FormattedMessage id="search.Province" defaultMessage="Provincia" />;
export const ProductionValues = (
  <FormattedMessage id="search.ProductionValues" defaultMessage="Valori produzione" />
);
export const Employees = <FormattedMessage id="search.Employees" defaultMessage="Addetti" />;
export const Capital = <FormattedMessage id="search.Capital" defaultMessage="Capitale" />;
export const ConstructionDate = (
  <FormattedMessage id="search.ConstructionDate" defaultMessage="Data costruzione" />
);
export const From = <FormattedMessage id="search.From" defaultMessage="Da" />;
export const To = <FormattedMessage id="search.To" defaultMessage="a" />;
export const None = <FormattedMessage id="search.None" defaultMessage="Nessuna" />;
export const Financing = (
  <FormattedMessage id="search.Financing" defaultMessage="Finanziamento in €" />
);
export const SeekingFinancing = (
  <FormattedMessage
    id="search.SeekingFinancing"
    defaultMessage="Attualmente in cerca di finanziamento"
  />
);
export const CompiledProfile = (
  <FormattedMessage id="search.CompiledProfile" defaultMessage="Profilo Compilato" />
);
export const NoOptions = () => (
  <FormattedMessage id="search.NoOptions" defaultMessage="Nessun'altra opzione" />
);
export const Tags = <FormattedMessage id="search.Tags" defaultMessage="Tags" />;
export const MultiPlaceholder = (
  <FormattedMessage id="search.Placeholder" defaultMessage="Seleziona una o più opzioni..." />
);
export const CurrentCompany = (
  <FormattedMessage id="search.CurrentCompany" defaultMessage="Azienda attuale" />
);
export const PreviousCompany = (
  <FormattedMessage id="search.PreviousCompany" defaultMessage="Ha lavorato per" />
);
export const Formation = <FormattedMessage id="search.Formation" defaultMessage="Formazione" />;
export const Residence = <FormattedMessage id="search.Residence" defaultMessage="Residenza" />;
export const SpokeTo = <FormattedMessage id="search.SpokeTo" defaultMessage="Ha parlato a" />;
export const InvestmentsNumber = (
  <FormattedMessage id="search.InvestmentsNumber" defaultMessage="Numero investimenti" />
);
export const ExitsNumber = (
  <FormattedMessage id="search.ExitsNumber" defaultMessage="Numero exit" />
);
export const StartupsNumber = (
  <FormattedMessage id="search.StartupsNumber" defaultMessage="Numero di startup nel portfolio" />
);
export const City = <FormattedMessage id="search.City" defaultMessage="Sede" />;
export const InvestorType = (
  <FormattedMessage id="search.InvestorType" defaultMessage="Tipologia di investitore" />
);
export const InvestmentSector = (
  <FormattedMessage id="search.InvestmentSector" defaultMessage="Investe nel settore" />
);
export const InvestmentStage = (
  <FormattedMessage id="search.InvestmentStage" defaultMessage="Stage di investimento" />
);
export const Invests = <FormattedMessage id="search.Invests" defaultMessage="Investe" />;
export const noSearchResults = (
  <FormattedMessage
    id="search.noSearchResults"
    defaultMessage="Applica i filtri per iniziare a vedere i risultati"
  />
);
export const noSearchResultsAskForImport = (
  <FormattedMessage
    id="search.noSearchResultsAskForImport"
    defaultMessage="Non trovi la tua startup?"
  />
);
export const ImportCompany = (
  <FormattedMessage id="search.ImportCompany" defaultMessage="Clicca qui" />
);
export const All = <FormattedMessage id="search.All" defaultMessage="All" />;
export const ConstitutionYear = (
  <FormattedMessage id="search.ConstitutionYear" defaultMessage="Anno di costituzione" />
);
export const ShareCapital = (
  <FormattedMessage id="search.ShareCapital" defaultMessage="Capitale sociale" />
);
export const Funding = <FormattedMessage id="search.Funding" defaultMessage="Funding" />;
export const WannaSeeMore = (
  <FormattedMessage id="search.WannaSeeMore" defaultMessage="Vuoi vedere tutti i risultati," />
);
export const Register = <FormattedMessage id="search.Register" defaultMessage="Registrati!" />;
export const NotLoggedDialogTitle = (
  <FormattedMessage
    id="search.NotLoggedDialogTitle"
    defaultMessage="Per vedere tutti i risultati fai login o registrati"
  />
);
export const NoThanks = <FormattedMessage id="search.NoThanks" defaultMessage="No grazie" />;
export const Login = <FormattedMessage id="search.Login" defaultMessage="Login" />;
export const NotAMember = (
  <FormattedMessage id="search.NotAMember" defaultMessage="Non sei ancora un membro?" />
);
export const CreateAccount = (
  <FormattedMessage id="search.CreateAccount" defaultMessage="crea un account" />
);
export const Industry = <FormattedMessage id="search.Industry" defaultMessage="Settore" />;

export const SortBy = <FormattedMessage id="search.SortBy" defaultMessage="Ordina per" />;

export const Alphabetical = (
  <FormattedMessage id="search.Alphabetical" defaultMessage="Alfabetico" />
);

export const Matching = companyName => (
  <FormattedMessage
    id="shared.Matching"
    values={{ companyName }}
    defaultMessage="Matching {companyName}"
  />
);

export const ProfileCompletion = (
  <FormattedMessage id="search.ProfileCompletion" defaultMessage="Completamento profilo" />
);
