import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const ChangePassword = (
  <FormattedMessage id="change-password.ChangePassword" defaultMessage="Cambio password" />
);
export const Password = (
  <FormattedMessage id="change-password.Password" defaultMessage="Password" />
);
export const PasswordConfirmation = (
  <FormattedMessage id="change-password.PasswordConfirmation" defaultMessage="Conferma password" />
);
export const Continue = (
  <FormattedMessage id="change-password.Continue" defaultMessage="Prosegui" />
);
