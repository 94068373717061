/* eslint-disable import/no-extraneous-dependencies */
import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { handleRequests } from "redux-saga-requests";
import { all } from "redux-saga/effects";
import { createDriver as createMockDriver } from "redux-saga-requests-mock";
import { createDriver as createAxiosDriver } from "redux-saga-requests-axios";
import rootReducer from "./reducers";
import config from "../config";
import mocks from "./mocks";
import axiosInstance from "./axios";
import interceptors from "./interceptors";

export default function configureStore(preloadedState) {
  const { requestsReducer, requestsSagas, requestsMiddleware } = handleRequests({
    ...interceptors,
    driver:
      config.appEnv === "dev" && Boolean(config.apiMock)
        ? createMockDriver(mocks, {
            timeout: config.mockTimeout // optional, in ms, defining how much time mock request would take, useful for testing spinners
          })
        : createAxiosDriver(axiosInstance),
    promisify: true,
    autoPromisify: true
  });
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [...requestsMiddleware, sagaMiddleware];
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const composedEnhancers = composeEnhancers(...enhancers);

  const store = createStore(
    combineReducers({ ...rootReducer, requests: requestsReducer }),
    preloadedState,
    composedEnhancers
  );

  function* rootSaga() {
    yield all([...requestsSagas]);
  }
  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}
