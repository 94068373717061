import React from "react";
import PropTypes from "prop-types";
import {
  CssBaseline,
  Toolbar,
  AppBar,
  Box,
  Button,
  withStyles,
  Container,
  styled
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const AboutUsButton = withStyles(() => ({ label: { fontWeight: 700 } }))(Button);
const SignUpButton = withStyles(() => ({ label: { fontWeight: 400 } }))(Button);

const PublicAppBar = styled(AppBar)({
  border: "none"
});

const Layout = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <PublicAppBar position="static">
        <Container>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <img src="/images/upbase/logo-home.svg" alt="UpBase" height="36px" />
              <div>
              <AboutUsButton component={Link} to="about-us">
                Chi Siamo
              </AboutUsButton>
              <SignUpButton component={Link} to="login">
                Sign in
              </SignUpButton>
              </div>
            </Box>
          </Toolbar>
        </Container>
      </PublicAppBar>
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default Layout;
