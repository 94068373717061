import React from "react";
import { AuthLayout, AuthBody } from "../layouts/auth";
import ChangePasswordForm from "./ChangePasswordForm";
import * as Messages from "./Messages";

const ChangePasswordPage = () => (
  <AuthLayout>
    <AuthBody>
      <AuthBody.Title>{Messages.ChangePassword}</AuthBody.Title>
      <AuthBody.Content>
        <ChangePasswordForm />
      </AuthBody.Content>
    </AuthBody>
  </AuthLayout>
);

export default ChangePasswordPage;
