/* eslint-disable camelcase */
import { Divider, Grid, styled, Tab, Tabs, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies, getInvestors, getPeople, getProfile } from "../ducks/actions";
import { getProfileQuery, isAuthenticated } from "../ducks/selectors";
import { SearchLayout } from "../layouts/search";
import { useQueryParams } from "../shared/hooks";
import AllFilter from "./AllFilter";
import AllList from "./AllList";
import InvestorFilter from "./InvestorFilter";
import InvestorList from "./InvestorList";
import * as Messages from "./Messages";
import NotLoggedDialog from "./NotLoggedDialog";
import PersonFilter from "./PersonFilter";
import PersonList from "./PersonList";
import SortingSelector from "./SortingSelector";
import StartupFilter from "./StartupFilter";
import StartupList from "./StartupList";

const Title = styled(Typography)({
  fontFamily: "aktiv-grotesk",
  fontSize: 28,
  fontWeight: 900,
  lineHeight: "35px"
});

const FilterGrid = styled(Grid)({
  borderRight: "solid 2px #ebebeb",
  padding: 16
});

const ListGrid = styled(Grid)({
  padding: 16
});

const SearchPage = () => {
  const dispatch = useDispatch();

  const {
    queryParams: { type = "all", ...queryParams },
    mergeParams
  } = useQueryParams();

  const isAuth = useSelector(isAuthenticated);

  const {
    data: { data: profile }
  } = useSelector(getProfileQuery);

  useEffect(() => {
    if (isAuth) {
      dispatch(getProfile());
    }
  }, [isAuth, dispatch]);

  const isInvestor = Boolean(profile.person?.investor_id);
  const investorId = profile.person?.investor_id;
  const isCompanyOwner = !_.isEmpty(profile.user?.company_owners);
  const companies = profile.user?.company_owners?.map(el => el.company);

  useEffect(() => {
    if (!isAuth || !_.isEmpty(profile)) {
      dispatch(
        getCompanies({
          page: queryParams.page,
          perPage: isAuth ? queryParams.per_page : 5,
          name: queryParams.name,
          industryId: queryParams.industryId,
          tags: queryParams.tags,
          constitutionYear: queryParams.constitutionYear,
          region: queryParams.region,
          shareCapital: queryParams.shareCapital,
          funding: queryParams.funding,
          sortBy: queryParams.sortBy,
          investorId,
          startups: true
        })
      ).catch(err => console.log(err));
      dispatch(
        getPeople({
          page: queryParams.page,
          perPage: isAuth ? queryParams.per_page : 5,
          name: queryParams.name,
          industryId: queryParams.industryId,
          tags: queryParams.tags
        })
      ).catch(err => console.log(err));
      dispatch(
        getInvestors({
          page: queryParams.page,
          perPage: isAuth ? queryParams.per_page : 5,
          name: queryParams.name,
          industryId: queryParams.industryId,
          tags: queryParams.tags,
          funding: queryParams.funding,
          sortBy: queryParams.sortBy
        })
      ).catch(err => console.log(err));
    }
  }, [
    dispatch,
    queryParams.page,
    queryParams.per_page,
    queryParams.name,
    queryParams.industryId,
    queryParams.tags,
    queryParams.constitutionYear,
    queryParams.region,
    queryParams.shareCapital,
    queryParams.funding,
    queryParams.sortBy,
    isAuth,
    profile,
    investorId
  ]);

  const onTabChange = (e, newType) => {
    if (type !== newType) {
      mergeParams({ type: newType, page: 1, per_page: 10 });
    }
  };

  const [filterComponentMap] = useState({
    all: AllFilter,
    startups: StartupFilter,
    people: PersonFilter,
    investors: InvestorFilter
  });

  const [listComponentMap] = useState({
    all: AllList,
    startups: StartupList,
    people: PersonList,
    investors: InvestorList
  });

  const FilterComponent = filterComponentMap[type];
  const ListComponent = listComponentMap[type];

  const [notLoggedDialogOpen, setNotLoggedDialogOpen] = useState(!isAuth);

  const onNotLoggedDialogClose = () => {
    setNotLoggedDialogOpen(false);
  };

  return (
    <SearchLayout>
      <Grid container>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9}>
          <Title>{Messages.SearchIn}</Title>
          <Tabs value={type} onChange={onTabChange} indicatorColor="primary">
            <Tab value="all" label={Messages.All} />
            <Tab value="startups" label={Messages.StartUp} />
            <Tab value="people" label={Messages.People} />
            <Tab value="investors" label={Messages.Investors} />
          </Tabs>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <FilterGrid item xs={12} md={3}>
          {type === "startups" && <SortingSelector withMatching={isInvestor} />}
          {isCompanyOwner && type === "investors" && <SortingSelector withMatching={isCompanyOwner} companies={companies} />}
          <FilterComponent />
        </FilterGrid>
        <ListGrid item xs={12} md={9}>
          <ListComponent isInvestor={isInvestor} isCompanyOwner={isCompanyOwner} />
        </ListGrid>
      </Grid>
      <NotLoggedDialog open={notLoggedDialogOpen} onClose={onNotLoggedDialogClose} />
    </SearchLayout>
  );
};

export default SearchPage;
