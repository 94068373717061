import { Grid, styled, Typography } from "@material-ui/core";
import React from "react";
import { SearchLayout } from "../layouts/search";
import * as Messages from "./Messages";
import ProfileTabs from "./ProfileTabs";

const Title = styled(Typography)({
  fontFamily: "aktiv-grotesk",
  fontSize: 28,
  fontWeight: 900,
  lineHeight: "35px",
  marginBottom: 32
});

const MyProfilePage = () => {
  return (
    <SearchLayout>
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <Title>{Messages.YourProfile}</Title>
          <ProfileTabs />
        </Grid>
      </Grid>
    </SearchLayout>
  );
};

export default MyProfilePage;
