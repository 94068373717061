import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const RequestOwnership = (
  <FormattedMessage id="person.RequestOwnership" defaultMessage="Richiedi proprietà" />
);
export const Contacts = <FormattedMessage id="person.Contacts" defaultMessage="Contatti" />;
export const Follow = <FormattedMessage id="person.Follow" defaultMessage="Follow" />;
export const OwnershipConfirmationMessage = (
  <FormattedMessage
    id="person.OwnershipConfirmationMessage"
    defaultMessage="Stai rivendicando questo profilo."
  />
);
export const ConfirmOwnership = (
  <FormattedMessage id="person.ConfirmOwnership" defaultMessage="Conferma" />
);
export const PersonUpdateSuccess = (
  <FormattedMessage id="person.PersonUpdateSuccess" defaultMessage="Profilo aggiornato" />
);
export const PersonUpdateError = (
  <FormattedMessage id="person.PersonUpdateError" defaultMessage="Profilo non aggiornato" />
);
