import { Grid, LinearProgress, Tab, Tabs } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavourites } from "../ducks/actions";
import { getFavouritesQuery } from "../ducks/selectors";
import InvestorTable from "../shared/components/InvestorTable";
import PersonTable from "../shared/components/PersonTable";
import StartupTable from "../shared/components/StartupTable";
import * as Messages from "./Messages";

const Favourites = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavourites());
  }, [dispatch]);

  const {
    data: { data: favourites },
    loading
  } = useSelector(getFavouritesQuery);

  const favouritesByType = _.chain(favourites)
    .map(({ id, favouritable }) => ({ ...favouritable, favourite_id: id }))
    .groupBy("type")
    .value();

  const companies = favouritesByType.company || [];
  const people = favouritesByType.person || [];
  const investors = favouritesByType.investor || [];

  const [tab, setTab] = useState("startups");
  const onTabChange = (e, newTab) => {
    if (tab !== newTab) {
      setTab(newTab);
    }
  };
  if (loading) {
    return <LinearProgress />;
  }
  return (
    <>
      <Grid container>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={9}>
          <Tabs value={tab} onChange={onTabChange} indicatorColor="primary">
            <Tab value="startups" label={Messages.Startups} />
            <Tab value="people" label={Messages.People} />
            <Tab value="investors" label={Messages.Investors} />
          </Tabs>
        </Grid>
      </Grid>
      {tab === "startups" && <StartupTable startups={companies} />}
      {tab === "people" && <PersonTable people={people} />}
      {tab === "investors" && <InvestorTable investors={investors} />}
    </>
  );
};

export default Favourites;
