import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const palette = {
  primary: {
    main: "#BD0310", // "#95093B",
    light: "#EE3D49",
    dark: "#95093B" // "#CB356B"
  },
  secondary: {
    main: "#fafafa",
    light: "white",
    dark: "#aaaaaa"
  }
};

const theme = {
  typography: {
    fontFamily: `urw-din,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    // fontSize: '1rem',
    h1: { fontFamily: "aktiv-grotesk", fontWeight: 900, fontSize: "4.5rem" },
    h2: { fontFamily: "aktiv-grotesk", fontWeight: 900, fontSize: "4rem" },
    h3: { fontFamily: "aktiv-grotesk", fontWeight: 900, fontSize: "3.5rem" },
    h4: { fontFamily: "aktiv-grotesk", fontWeight: 900, fontSize: "3rem" },
    h5: { fontFamily: "aktiv-grotesk", fontWeight: 900, fontSize: "2.5rem" },
    h6: { fontFamily: "aktiv-grotesk", fontWeight: 900, fontSize: "2rem" },
    subtitle1: { fontSize: "1.4rem", fontWeight: 300 },
    subtitle2: { fontSize: "1.2rem", fontWeight: 300 }
  },
  palette,
  overrides: {
    MuiAppBar: {
      root: {
        marginBottom: 32
      },
      colorPrimary: {
        backgroundColor: palette.secondary.main,
        boxShadow: "none",
        borderBottom: `2px ${palette.secondary.dark} solid`
      }
    },
    MuiTab: {
      root: {
        textTransform: "none",
        "&$selected": {
          color: palette.primary.main
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: "8px"
      },
      contained: {
        background: `linear-gradient(45deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%)`,
        boxShadow: "none",
        "&$disabled": {
          background: `linear-gradient(45deg, ${palette.secondary.main} 0%, ${palette.secondary.dark} 100%)`
        }
      },
      textPrimary: {
        color: palette.secondary.main
      },
      label: {
        padding: "8px",
        fontFamily: `aktiv-grotesk`,
        fontWeight: "800",
        fontSize: "1.2rem",
        lineHeight: "1.4rem",
        textTransform: "none"
      }
    },
    MuiCard: {
      root: {
        boxShadow: "none",
        backgroundColor: palette.secondary.main
      }
    },
    MuiOutlinedInput: {
      input: {
        backgroundColor: palette.secondary.main,
        borderRadius: "8px"
      }
    }
  }
};

const muiTheme = createMuiTheme(theme);
export default responsiveFontSizes(muiTheme);
