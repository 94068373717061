import { Container, Divider, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import React from "react";
// import InstagramIcon from "@material-ui/icons/Instagram";
// import YouTubeIcon from "@material-ui/icons/YouTube";
import * as Messages from "../Messages";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#000051",
    padding: 16
  },
  row: {
    display: "flex",
    justifyContent: "center"
  },
  lowerFooter: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#0e032b",
    padding: 8
  },
  divider: {
    margin: "0px 8px",
    backgroundColor: theme.palette.secondary.main,
    height: 24
  }
}));

const SocialLink = ({ icon: Icon, url }) => {
  const classes = makeStyles(theme => ({
    link: {
      marginRight: theme.spacing(1),
      color: theme.palette.secondary.main
    }
  }))();
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer" className={classes.link}>
      <Icon />
    </a>
  ) : (
    <Icon className={classes.link} />
  );
};

// const SmallButton = withStyles({
//   label: {
//     padding: 4,
//     fontSize: "unset"
//   }
// })(Button);

const socials = [
  // {
  //   icon: FacebookIcon,
  //   url: ""
  // },
  // {
  //   icon: InstagramIcon,
  //   url: ""
  // },
  {
    icon: LinkedInIcon,
    url: "https://www.linkedin.com/company/up-base/"
  }
  // {
  // icon: TwitterIcon,
  // url: ""
  // },
  // {
  // icon: YouTubeIcon,
  // url: ""
  // }
];

const Footer = (/* { isSubmitting, isValid, dirty } */) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={2} className={classes.row}>
            <img src="/images/upbase/upbase-logo-white.svg" alt="UpBase" height="36px" />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.row}>
            <Typography variant="body2" color="secondary" align="center">
              StartupItalia! è una testata registrata presso il tribunale di Roma n. 167/2012
              <br />
              StartupItalia! SRL, Cagliari, Via San Lucifero 65, 09125, PIVA 13733231008, REA - CA -
              352408, Capitale Sociale: € 28.924,60, PEC startupitalia@legalmail.it
            </Typography>
          </Grid>
          {/* <MarginGrid item xs={12} md={4}>
                <Typography gutterBottom color="secondary">
                <strong>Iscriviti alla newsletter</strong>
                </Typography>
                <Form>
                <Field component={TextField} placeholder="Email" name="email" fullWidth />
                <SmallButton
                variant="contained"
                color="primary"
                disabled={!dirty || !isValid || isSubmitting}
                type="submit"
                fullWidth
                >
                {Messages.SignUp}
                </SmallButton>
                </Form>
              </MarginGrid> */}
          <Grid item xs={12} md={2}>
            <Typography gutterBottom color="secondary">
              <strong>Seguici sui social</strong>
            </Typography>
            {socials.map(({ icon, url }) => (
              <SocialLink icon={icon} url={url} />
            ))}
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.lowerFooter}>
        <Link href="https://startupitalia.eu/privacy" target="_blank">
          <Typography variant="body2" color="secondary">
            <strong>{Messages.PrivacyPolicy}</strong>
          </Typography>
        </Link>
        <Divider orientation="vertical" className={classes.divider} />
        <Link href="https://startupitalia.eu/cookie-declaration" target="_blank">
          <Typography variant="body2" color="secondary">
            <strong>{Messages.CookiePolicy}</strong>
          </Typography>
        </Link>
      </Container>
    </>
  );
};

// const enhance = compose(
//   withFormik({
//     mapPropsToValues: () => ({ email: "" }),
//     handleSubmit: async values => {
//       console.log(values);
//     },
//     validationSchema: yup.object().shape({
//       email: yup.string().email()
//     })
//   })
// );

export default Footer;
