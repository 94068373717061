import { success } from "redux-saga-requests";

import { reducer as notificationReducer } from "./notifications";
import { SOCIAL_LOGIN, LOGOUT } from "./types";
// import { reducer as authReducer } from "./auth";

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case success(SOCIAL_LOGIN):
      return { isAuthenticated: true };
    case LOGOUT:
      localStorage.removeItem("UPBASE_AUTH_TOKEN");
      return { isAuthenticated: false };
    default:
      return state;
  }
};

export default {
  // common
  notifications: notificationReducer,
  auth: authReducer
};
