import React from "react";
import { Switch, useRouteMatch, Redirect, Route } from "react-router-dom";
import { Grid, Typography, styled, Box } from "@material-ui/core";
import { AuthLayout } from "../layouts/private";
import CompanyImportSearch from "./CompanyImportSearch";
import CompanyImportConfirm from "./CompanyImportConfirm";

const Title = styled(Typography)({
  fontFamily: "aktiv-grotesk",
  fontSize: 28,
  fontWeight: 900,
  lineHeight: "35px",
  marginBottom: 32
});

const CompanyImport = () => {
  const { path, url } = useRouteMatch();
  return (
    <AuthLayout>
      <Box alignItems="center" align="center">
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <Title>Importa la tua startup</Title>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Switch>
            <Route path={`${path}/search`} component={CompanyImportSearch} />
            <Route path={`${path}/confirm/:id`} component={CompanyImportConfirm} />
            <Redirect to={`${url}/search`}/>
          </Switch>
        </Grid>
      </Box>
    </AuthLayout>
  );
};

export default CompanyImport;
