import { LinearProgress } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getPerson, updatePerson } from "../ducks/actions";
import { getPersonQuery } from "../ducks/selectors";
import { useNotifications } from "../notifications";
import PersonEditDialog from "../shared/components/PersonEditDialog";
import * as Messages from "./Messages";

const PersonEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { ok, ko } = useNotifications();

  const {
    data: { data: person },
    loading
  } = useSelector(getPersonQuery);
  const onClose = () => {
    history.push(`/people/${id}`);
  };
  const onSubmit = async ({ industryId, ...values }) => {
    const industryIds = industryId ? [industryId] : [];
    try {
      await dispatch(
        updatePerson({
          id,
          person: {
            ...values,
            industry_industriables_attributes: _.concat(
              _.chain(person.industry_industriables)
                .filter(({ industry }) => !industryIds.includes(industry.id))
                .map(({ id }) => ({ id, _destroy: true }))
                .value(),
              _.chain(industryIds)
                .filter(id => !_.map(person.industry_industriables, "industry.id").includes(id))
                .map(id => ({ industry_id: id }))
                .value()
            )
          }
        })
      );
      await dispatch(getPerson({ id }));
      ok(Messages.PersonUpdateSuccess);
      history.push(`/people/${id}`);
    } catch (err) {
      ko(Messages.PersonUpdateError);
    }
  };
  if (loading) {
    return <LinearProgress />;
  }

  return (
    <PersonEditDialog
      onClose={onClose}
      onSubmit={onSubmit}
      person={{
        ...person,
        industryId: person.industry_industriables?.[0]?.industry.id
      }}
    />
  );
};

export default PersonEdit;
