import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { createCompanyPerson, getCompanyPeople, search, getCompany } from "../ducks/actions";
import { withNotifications } from "../notifications";
import * as Messages from "./Messages";
import TeamAddDialog from "../shared/components/TeamAddDialog";

const enhance = compose(
  withNotifications,
  connect(undefined, dispatch => ({
    actions: bindActionCreators(
      { createCompanyPerson, getCompanyPeople, search, getCompany },
      dispatch
    )
  })),
  withHandlers({
    onSubmit: ({
      ok,
      ko,
      match: {
        params: { id }
      },
      history,
      actions
    }) => async ({ values, personId }) => {
      const companyPerson = personId
        ? {
            invitation_email: values.invitation_email,
            job_title: values.job_title,
            is_founder: values.is_founder,
            person_id: personId
          }
        : {
            invitation_email: values.invitation_email,
            job_title: values.job_title,
            is_founder: values.is_founder,
            person_attributes: { first_name: values.first_name, last_name: values.last_name }
          };
      try {
        await actions.createCompanyPerson({
          id,
          companyPerson
        });
        await actions.getCompanyPeople({ id });
        await actions.getCompany({ id });
        history.push(`/companies/${id}/team`);
        ok(Messages.CompanyUpdateSuccess);
      } catch (err) {
        ko(Messages.CompanyUpdateError);
      }
    },
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/companies/${id}/team`);
    },
    onLoadPeople: ({ actions }) => async (inputValue, callback) => {
      const { response } = await actions.search({ type: "people", name: inputValue });
      const people = response.data.data.map(person => ({
        label: `${person.first_name} ${person.last_name}`,
        avatarUrl: person.avatar_urls.small,
        code: `${person.first_name[0]}${person.last_name[0]}`,
        value: person
      }));
      callback(people);
    }
  })
);

export default enhance(TeamAddDialog);
