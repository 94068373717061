import { Grid, styled, Typography } from "@material-ui/core";
import qs from "query-string";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import { isAuthenticated } from "../ducks/selectors";
import { AuthBody, AuthLayout } from "../layouts/auth";
import { withNotifications } from "../notifications";
import FacebookLogin from "./FacebookLogin";
import GoogleLogin from "./GoogleLogin";
import LinkedInLogin from "./LinkedInLogin";
import LoginForm from "./LoginForm";
import * as Messages from "./Messages";

const PrivacyDisclaimerTypography = styled(Typography)({
  color: "white",
  textAlign: "center",
  fontFamily: "aktiv-grotesk",
  fontSize: 14
});

const NotAMemberContainer = styled("div")({
  textAlign: "center",
  lineHeight: 1
});

const SmallText = styled(Typography)({
  fontSize: 12
});
const RedSmallText = styled(Typography)({
  color: "#95093B",
  fontSize: 12,
  textDecoration: "none"
});

const ForgotPasswordContainer = styled("div")({
  marginTop: 3,
  textAlign: "center",
  lineHeight: "26px"
});

// eslint-disable-next-line react/prop-types
const ForgotPasswordLink = ({ to }) => (
  <RedSmallText component={Link} to={to}>
    {Messages.ForgotPassword}
  </RedSmallText>
);

const LoginPage = () => (
  <AuthLayout>
    <AuthBody>
      <AuthBody.Title>{Messages.Login}</AuthBody.Title>
      <AuthBody.Content>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FacebookLogin />
            <LinkedInLogin />
            <GoogleLogin />
            <NotAMemberContainer>
              <SmallText>{Messages.NotAMember}</SmallText>
              <RedSmallText component={Link} to="/sign-up">
                {Messages.CreateAccount}
              </RedSmallText>
            </NotAMemberContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoginForm />
            <ForgotPasswordContainer>
              <ForgotPasswordLink to="/forgot-password" />
            </ForgotPasswordContainer>
          </Grid>
        </Grid>
      </AuthBody.Content>
    </AuthBody>
    <PrivacyDisclaimerTypography>{Messages.Disclaimer}</PrivacyDisclaimerTypography>
  </AuthLayout>
);

const enhance = compose(
  withNotifications,
  withRouter,
  connect(state => ({
    isAuthenticated: isAuthenticated(state)
  })),
  lifecycle({
    componentDidMount() {
      const { isAuthenticated, history, location, ok } = this.props;
      if (isAuthenticated) {
        history.push("/home");
        return;
      }

      const { cm } = qs.parse(location.search, { arrayFormat: "bracket" });
      if (cm) {
        ok(Messages[`CheckEmail${cm}`]);
      }
    }
  })
);

export default enhance(LoginPage);
