import qs from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { confirmCompanyPerson } from "../ducks/actions";
import { isAuthenticated } from "../ducks/selectors";
import { useNotifications } from "../notifications";
import * as Messages from "./Messages";

const InvitationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ok, ko } = useNotifications();
  const { search } = useLocation();

  const { invitation_token: invitationToken } = qs.parse(search, { arrayFormat: "bracket" });

  const isAuth = useSelector(isAuthenticated);

  useEffect(() => {
    const confirm = async () => {
      if (isAuth) {
        const { response, error } = await dispatch(
          confirmCompanyPerson({
            token: invitationToken
          })
        );
        if (response) {
          ok(Messages.ConfirmationSuccess);
          history.push(`/companies/${response.data.data.company.id}/team`);
        }
        if (error) {
          ko(Messages.ConfirmationError);
          history.push("/home");
        }
      } else {
        localStorage.setItem("INVITATION_TOKEN", invitationToken);
        history.push("/login");
      }
    };
    confirm();
  }, [dispatch, history, isAuth, ko, ok, invitationToken]);

  return null;
};

export default InvitationPage;
