import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustries } from "../../ducks/actions";
import { getIndustriesQuery } from "../../ducks/selectors";
import ReactSelectField from "./ReactSelectField";

const IndustrySelectField = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustries()).catch(err => console.log(err));
  }, [dispatch]);

  const {
    data: { data: industries }
  } = useSelector(getIndustriesQuery);

  const industryOptions = _.chain(industries)
    .sortBy("name")
    .map(({ id, name }) => ({ value: id, label: name }))
    .value();

  return <ReactSelectField options={industryOptions} hasNone {...props} />;
};

export default IndustrySelectField;
