import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const SignUpWith = <FormattedMessage id="login.SignUpWith" defaultMessage="Sign up with" />;
export const NotAMember = (
  <FormattedMessage id="login.NotAMember" defaultMessage="Non sei ancora un membro?" />
);
export const CreateAccount = (
  <FormattedMessage id="login.CreateAccount" defaultMessage="crea un account" />
);
export const ForgotPassword = (
  <FormattedMessage id="login.ForgotPassword" defaultMessage="Hai dimenticato la tua password?" />
);
export const Disclaimer = (
  <FormattedMessage
    id="login.Disclaimer"
    defaultMessage="Accedendo e utilizzando questa piattaforma accetti i termini e condizioni descritte da UpBase nel proprio documento di Privacy Policy &amp; termini di utilizzo"
  />
);

export const Email = <FormattedMessage id="login.Email" defaultMessage="Email" />;
export const Password = <FormattedMessage id="login.Password" defaultMessage="Password" />;
export const Login = <FormattedMessage id="login.Login" defaultMessage="Sign in" />;
export const InvalidUser = (
  <FormattedMessage id="login.InvalidUser" defaultMessage="Email o password non valide" />
);
export const CheckEmailSignUp = (
  <FormattedMessage
    id="login.CheckEmailSignUp"
    defaultMessage="Registrazione effettuata. Controlla la tua email"
  />
);
export const CheckEmailForgotPassword = (
  <FormattedMessage
    id="login.CheckEmailForgotPassword"
    defaultMessage="Richiesta di cambio password inoltrata. Controlla la tua email"
  />
);

export const SignInWithLinkedIn = (
  <FormattedMessage id="login.SignInWithLinkedIn" defaultMessage="Sign in with LinkedIn" />
);
export const SignInWithGoogle = (
  <FormattedMessage id="login.SignInWithGoogle" defaultMessage="Sign in with Google" />
);
export const SignInWithFacebook = (
  <FormattedMessage id="login.SignInWithFacebook" defaultMessage="Sign in with Facebook" />
);
