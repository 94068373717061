/* eslint-disable import/prefer-default-export */
import firebase from "./firebase";
import "firebase/auth";

export const getAuth = () => {
  return firebase.auth();
};

// export const githubOAuth = () => {
//   return new firebase.firebase_.auth.GithubAuthProvider();
// };

// export const twitterOAuth = () => {
//   return new firebase.firebase_.auth.TwitterAuthProvider();
// };

// export const facebookOAuth = () => {
//   return new firebase.firebase_.auth.FacebookAuthProvider();
// };
