import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Team = <FormattedMessage id="investor.Team" defaultMessage="Team" />;
export const ShowProfile = (
  <FormattedMessage id="investor.ShowProfile" defaultMessage="Visita profilo" />
);
export const InvestorUpdateSuccess = (
  <FormattedMessage id="investor.InvestorUpdateSuccess" defaultMessage="Investor updated" />
);
export const InvestorUpdateError = (
  <FormattedMessage id="investor.InvestorUpdateError" defaultMessage="Cannot update investor" />
);
export const AddRound = <FormattedMessage id="investor.AddRound" defaultMessage="Aggiungi" />;
export const EditRound = <FormattedMessage id="investor.EditRound" defaultMessage="Modifica" />;
export const RoundUpdateSuccess = (
  <FormattedMessage id="investor.RoundUpdateSuccess" defaultMessage="Round updated" />
);
export const RoundUpdateError = (
  <FormattedMessage id="investor.RoundUpdateError" defaultMessage="Cannot update round" />
);
export const RoundCreateSuccess = (
  <FormattedMessage id="investor.RoundCreateSuccess" defaultMessage="Round created" />
);
export const RoundCreateError = (
  <FormattedMessage id="investor.RoundCreateError" defaultMessage="Cannot create round" />
);
export const DeleteRoundSuccess = (
  <FormattedMessage id="investor.DeleteRoundSuccess" defaultMessage="Round deleted" />
);
export const DeleteRoundError = (
  <FormattedMessage id="investor.DeleteRoundError" defaultMessage="Cannot delete round" />
);
export const DeleteInvestorSuccess = (
  <FormattedMessage
    id="investor.DeleteInvestorSuccess"
    defaultMessage="Non sei più un investitore"
  />
);
export const DeleteInvestorError = (
  <FormattedMessage
    id="investor.DeleteInvestorError"
    defaultMessage="Impossibile eliminare investitore"
  />
);

export const CompanyPlaceholder = (
  <FormattedMessage id="investor.CompanyPlaceholder" defaultMessage="Cerca e aggiungi la company" />
);
export const NoFundingData = (
  <FormattedMessage id="investor.NoFundingData" defaultMessage="Non ci sono raccolte disponibili" />
);
export const InsertFundingData = (
  <FormattedMessage
    id="investor.InsertFundingData"
    defaultMessage="Inserisci i dati sulle tue raccolte fondi"
  />
);
export const Investments = (
  <FormattedMessage id="investor.Investments" defaultMessage="Investments" />
);
export const Undisclosed = (
  <FormattedMessage id="investor.Undisclosed" defaultMessage="Undisclosed" />
);
