import { LinearProgress } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getCompany, updateCompany, updateCompanyLogo } from "../ducks/actions";
import { getCompanyQuery } from "../ducks/selectors";
import { useNotifications } from "../notifications";
import CompanyOverviewEditDialog from "../shared/components/CompanyOverviewEditDialog";
import * as Messages from "./Messages";

const CompanyOverviewEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ok, ko } = useNotifications();
  const { id } = useParams();
  const {
    data: { data: company },
    loading
  } = useSelector(getCompanyQuery);

  const onClose = () => {
    history.push(`/investors/${id}/overview`);
  };

  const onLogoUpload = async ({ file }) => {
    try {
      await dispatch(updateCompanyLogo({ id: company.id, logo: file }));
      ok(Messages.InvestorUpdateSuccess);
    } catch (err) {
      ko(Messages.InvestorUpdateError);
    }
  };

  const onSubmit = async ({ industryId, ...values }) => {
    const industryIds = [industryId];
    try {
      await dispatch(
        updateCompany({
          id: company.id,
          company: {
            ...values,
            industry_industriables_attributes: _.concat(
              _.chain(company.industry_industriables)
                .filter(({ industry }) => !industryIds.includes(industry.id))
                .map(({ id }) => ({ id, _destroy: true }))
                .value(),
              _.chain(industryIds)
                .filter(id => !_.map(company.industry_industriables, "industry.id").includes(id))
                .map(id => ({ industry_id: id }))
                .value()
            )
          }
        })
      );
      await dispatch(getCompany({ id: company.id }));
      ok(Messages.InvestorUpdateSuccess);
      history.push(`/investors/${id}/overview`);
    } catch (err) {
      ko(Messages.InvestorUpdateError);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <CompanyOverviewEditDialog
      onClose={onClose}
      onLogoUpload={onLogoUpload}
      onSubmit={onSubmit}
      company={{
        ...company,
        industryId: company.industry_industriables?.[0]?.industry.id
      }}
    />
  );
};

export default CompanyOverviewEdit;
