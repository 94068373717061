import {
  Avatar,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  BusinessCenter as BusinessCenterIcon
} from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProfileQuery, ownedCompanies } from "../ducks/selectors";

const useStyles = makeStyles(() => ({
  root: {
    margin: "16px 0"
  },
  menu: {
    borderRadius: "5px",
    border: "1px solid #EBEBEB"
  },
  menuItem: {
    height: 50
  }
}));

const MyStartupsSideMenu = () => {
  const classes = useStyles();

  const ownedCompaniesList = useSelector(ownedCompanies({ state: "accepted" }));
  const { loading: profileLoading } = useSelector(getProfileQuery);

  if (profileLoading) {
    return <LinearProgress />;
  }

  if (_.isEmpty(ownedCompaniesList)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <List dense className={classes.menu}>
        {ownedCompaniesList
          .filter(c => c.startup)
          .map(company => (
            <ListItem
              className={classes.menuItem}
              button
              component={Link}
              to={`/companies/${company.id}/overview`}
              key={company.id}
            >
              <ListItemIcon>
                <Avatar src={company.logo_urls.small} alt={company.name}>
                  <BusinessCenterIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={company.project_name || company.name}
                primaryTypographyProps={{ noWrap: true }}
              />
              <ListItemSecondaryAction>
                <ArrowForwardIosIcon fontSize="small" />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default MyStartupsSideMenu;
