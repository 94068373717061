import { Box, Button, Link } from "@material-ui/core";
import { Field, Form, Formik, useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { isAuthenticated } from "../ducks/selectors";
import IndustrySelectField from "../shared/formik/IndustrySelectField";
import ReactSelectField from "../shared/formik/ReactSelectField";
import TagsSelectField from "../shared/formik/TagsSelectField";
import { useQueryParams } from "../shared/hooks";
import {
  capitalClassOptions,
  fundingStatusOptions,
  regionOptions,
  yearOptions
} from "../shared/utils";
import * as Messages from "./Messages";

const StartupFilterForm = () => {
  const isAuth = useSelector(isAuthenticated);
  const { isValid } = useFormikContext();

  return (
    <Form>
      <Field
        component={IndustrySelectField}
        label={Messages.Industry}
        name="industryId"
        noOptionsMessage={Messages.NoOptions}
        placeholder={Messages.Industry}
      />
      <Field
        component={TagsSelectField}
        label={Messages.Tags}
        name="tags"
        closeMenuOnSelect={false}
        noOptionsMessage={Messages.NoOptions}
        placeholder={Messages.MultiPlaceholder}
        byIndustry
      />
      <Field
        component={ReactSelectField}
        label={Messages.ConstitutionYear}
        name="constitutionYear"
        options={yearOptions}
        hasNone
      />
      <Field
        component={ReactSelectField}
        label={Messages.Region}
        name="region"
        options={regionOptions}
        hasNone
      />
      <Field
        component={ReactSelectField}
        label={Messages.ShareCapital}
        name="shareCapital"
        options={capitalClassOptions}
        hasNone
      />
      <Field
        component={ReactSelectField}
        label={Messages.Funding}
        name="funding"
        options={fundingStatusOptions}
        hasNone
      />
      <Box marginTop={2}>
        <Button variant="contained" color="primary" fullWidth disabled={!isValid} type="submit">
          {Messages.Filter}
        </Button>
      </Box>
      {isAuth && (
        <Box marginTop={2}>
          <RouterLink component={Link} to="/profile/company_import">
            Non trovi la tua startup? Clicca qui
          </RouterLink>
        </Box>
      )}
    </Form>
  );
};

const StartupFilter = () => {
  const { queryParams, mergeParams } = useQueryParams();

  const handleSubmit = values => {
    mergeParams(values);
  };

  return (
    <Formik
      initialValues={{
        industryId: queryParams.industryId || "",
        tags: queryParams.tags || [],
        constitutionYear: queryParams.constitutionYear || "",
        region: queryParams.region || "",
        shareCapital: queryParams.shareCapital || "",
        funding: queryParams.funding || ""
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <StartupFilterForm />
    </Formik>
  );
};

export default StartupFilter;
