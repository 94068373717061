import React from "react";
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { isPersonClaimed, person, personLoading } from "../ducks/selectors";
import PersonOverview from "../shared/components/PersonOverview";
import PersonEdit from "./PersonEdit";

const Overview = ({ person, isPersonClaimed }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/edit`} component={PersonEdit} />
      </Switch>
      <PersonOverview person={person} isPersonClaimed={isPersonClaimed} />
    </>
  );
};

const enhance = compose(
  withRouter,
  connect((state, { match: { params: { id } } }) => ({
    person: person(state),
    personLoading: personLoading(state),
    isPersonClaimed: isPersonClaimed(id)(state)
  }))
);

export default enhance(Overview);
