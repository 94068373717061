import React from "react";
import { Typography, styled } from "@material-ui/core";
import { AuthLayout, AuthBody } from "../layouts/auth";
import ForgotPasswordForm from "./ForgotPasswordForm";
import * as Messages from "./Messages";

const HowToTypography = styled(Typography)({ fontSize: 16 });

const ForgotPasswordPage = () => (
  <AuthLayout>
    <AuthBody>
      <AuthBody.Title>{Messages.ForgotPassword}</AuthBody.Title>
      <AuthBody.Content>
        <HowToTypography>{Messages.HowTo}</HowToTypography>
        <ForgotPasswordForm />
      </AuthBody.Content>
    </AuthBody>
  </AuthLayout>
);

export default ForgotPasswordPage;
