import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl.macro";

export const EmailAlreadyInUse = (
  <FormattedMessage id="shared.EmailAlreadyInUse" defaultMessage="Email già utilizzata" />
);
export const EmailInvalid = (
  <FormattedMessage id="shared.EmailInvalid" defaultMessage="Email non valida" />
);
export const OperationNotAllowed = (
  <FormattedMessage id="shared.OperationNotAllowed" defaultMessage="Oparazione non pemessa" />
);
export const WeakPassword = (
  <FormattedMessage id="shared.WeakPassword" defaultMessage="Password debole" />
);
export const UserDisabled = (
  <FormattedMessage id="shared.UserDisabled" defaultMessage="Utente disabilitato" />
);
export const UserNotFound = (
  <FormattedMessage id="shared.UserNotFound" defaultMessage="Utente non trovato" />
);
export const WrongPassword = (
  <FormattedMessage id="shared.WrongPassword" defaultMessage="Password sbagliata" />
);
export const Save = <FormattedMessage id="profile.Save" defaultMessage="Salva" />;
export const Confirm = <FormattedMessage id="shared.Confirm" defaultMessage="Conferma" />;
export const Cancel = <FormattedMessage id="shared.Cancel" defaultMessage="Annulla" />;
export const NotDescribed = (
  <FormattedMessage id="shared.ND" defaultMessage="Informazione non disponibile" />
);
export const Contacts = <FormattedMessage id="shared.Contacts" defaultMessage="Contatti" />;
export const OpenWebsite = (
  <FormattedMessage id="shared.OpenWebsite" defaultMessage="Apri il sito web" />
);
export const About = <FormattedMessage id="shared.About" defaultMessage="About" />;
export const Overview = <FormattedMessage id="shared.Overview" defaultMessage="Overview" />;
export const Team = <FormattedMessage id="shared.Team" defaultMessage="Team" />;
export const Culture = <FormattedMessage id="shared.Culture" defaultMessage="Culture" />;
export const Funding = <FormattedMessage id="shared.Funding" defaultMessage="Funding" />;
export const Info = <FormattedMessage id="shared.Info" defaultMessage="Info" />;
export const RequestOwnership = (
  <FormattedMessage id="shared.RequestOwnership" defaultMessage="Richiedi proprietà" />
);
export const Follow = <FormattedMessage id="shared.Follow" defaultMessage="Follow" />;
export const CompanyBuild = (
  <FormattedMessage id="shared.CompanyBuild" defaultMessage="Costituzione impresa" />
);
export const StartupSection = (
  <FormattedMessage id="shared.StartupSection" defaultMessage="Sezione startup" />
);
export const Name = <FormattedMessage id="shared.Name" defaultMessage="Denominazione" />;
export const Municipality = <FormattedMessage id="shared.Municipality" defaultMessage="Comune" />;
export const FiscalCode = (
  <FormattedMessage id="shared.FiscalCode" defaultMessage="Codice fiscale" />
);
export const LegalNature = (
  <FormattedMessage id="shared.LegalNature" defaultMessage="Forma giuridica" />
);
export const CompanyEstablished = (
  <FormattedMessage id="shared.CompanyEstablished" defaultMessage="Impresa costituita" />
);
export const AtecoCode = <FormattedMessage id="shared.AtecoCode" defaultMessage="Codice ateco" />;
export const Sector = <FormattedMessage id="shared.Sector" defaultMessage="Settore" />;
export const CompanyOwnershipConfirmation = (
  <FormattedMessage
    id="shared.CompanyOwnershipConfirmation"
    defaultMessage="Stai diventando proprietario della tua startup."
  />
);
export const ConfirmOwnership = (
  <FormattedMessage id="shared.ConfirmOwnership" defaultMessage="Conferma" />
);
export const StartupProfileCompletePercentage = (
  <FormattedMessage
    id="shared.StartupProfileCompletePercentage"
    defaultMessage="% del profilo completato"
  />
);
export const PersonProfileCompletePercentage = (
  <FormattedMessage
    id="shared.PersonProfileCompletePercentage"
    defaultMessage="% del profilo completato"
  />
);
export const Add = <FormattedMessage id="shared.Add" defaultMessage="Aggiungi" />;
export const Edit = <FormattedMessage id="shared.Edit" defaultMessage="Modifica" />;
export const Delete = <FormattedMessage id="shared.Delete" defaultMessage="Elimina" />;
export const Accept = <FormattedMessage id="shared.Accept" defaultMessage="Accetta" />;
export const Discard = <FormattedMessage id="shared.Discard" defaultMessage="Rifiuta" />;
export const MissingMember = (
  <FormattedMessage id="shared.MissingMember" defaultMessage="Nessuna persona presente" />
);
export const NoSectionData = (
  <FormattedMessage
    id="shared.NoSectionData"
    defaultMessage="I dati per questa sezione non sono ancora disponibili"
  />
);
export const SearchForPeople = (
  <FormattedMessage id="shared.SearchForPeople" defaultMessage="Cerca tra gli utenti di UpBase" />
);
export const CreatePeople = (
  <FormattedMessage id="shared.CreatePeople" defaultMessage="Oppure invita un nuovo utente" />
);
export const ShowProfile = (
  <FormattedMessage id="shared.ShowProfile" defaultMessage="Visita profilo" />
);
export const AddRound = <FormattedMessage id="shared.AddRound" defaultMessage="Aggiungi" />;
export const EditRound = <FormattedMessage id="shared.EditRound" defaultMessage="Modifica" />;
export const DeleteRound = <FormattedMessage id="shared.DeleteRound" defaultMessage="Elimina" />;
export const DeleteRoundConfirm = (
  <FormattedMessage
    id="shared.DeleteRoundConfirm"
    defaultMessage="Cancellare il round selezionato?"
  />
);
export const DeleteInvestor = (
  <FormattedMessage id="shared.DeleteInvestor" defaultMessage="Elimina" />
);
export const DeleteInvestorTooltip = (
  <FormattedMessage
    id="shared.DeleteInvestorTooltip"
    defaultMessage="Elimina il tuo profilo di Investitore"
  />
);
export const DeleteInvestorConfirm = (
  <FormattedMessage
    id="shared.DeleteInvestorConfirm"
    defaultMessage="Cancellare l'investitore selezionato?"
  />
);
export const InvestorsPlaceholder = (
  <FormattedMessage
    id="shared.InvestorsPlaceholder"
    defaultMessage="Cerca e aggiungi gli investitori"
  />
);
export const InvitationEmailHelperText = (
  <FormattedMessage
    id="shared.InvitationEmailHelperText"
    defaultMessage="Se questo campo viene compilato sarà inviata una nuova email di invito"
  />
);
export const NoAvailableContact = (
  <FormattedMessage id="shared.NoAvailableContact" defaultMessage="Nessun contatto disponibile" />
);
export const IsAMember = (
  <FormattedMessage id="shared.IsAMember" defaultMessage="Sono un membro del team" />
);
export const RequestValidation = (
  <FormattedMessage
    id="shared.RequestValidation"
    defaultMessage="La tua richiesta è ancora soggetta a valutazione"
  />
);
export const Education = <FormattedMessage id="shared.Education" defaultMessage="Istruzione" />;
export const WhatIDo = <FormattedMessage id="shared.WhatIDo" defaultMessage="What I do" />;
export const Achievements = (
  <FormattedMessage id="shared.Achievements" defaultMessage="Achievements" />
);
export const Locations = <FormattedMessage id="shared.Locations" defaultMessage="Locations" />;
export const Markets = <FormattedMessage id="shared.Markets" defaultMessage="Markets" />;
export const AcceptRequest = (
  <FormattedMessage
    id="shared.AcceptRequest"
    defaultMessage="Conferma la tua proprietà attraverso l'email che ti è stata inviata"
  />
);
export const Email = <FormattedMessage id="shared.Email" defaultMessage="Email" />;
export const LogoUploadHelper = (
  <FormattedMessage
    id="shared.LogoUploadHelper"
    defaultMessage="Usare un immagine di proporzioni quadrate (1:1) per ottenere un miglior adatattamento."
  />
);
export const EmptyCulture = (
  <FormattedMessage
    id="shared.EmptyCulture"
    defaultMessage="Carica qui il video del tuo prodotto o servizio"
  />
);
export const MissingDomainTitle = (
  <FormattedMessage
    id="shared.MissingDomainTitle"
    defaultMessage="Hai chiesto la proprietà della pagina"
  />
);
export const MissingDomainContent = (
  <FormattedMessage
    id="shared.MissingDomainContent"
    defaultMessage="Il dominio mail della tua azienda non è presente nel nostro database. Scrivici a"
  />
);
export const InsertDomainEmail = domain => (
  <FormattedMessage
    id="shared.InsertDomainEmail"
    defaultMessage="Inserisci la email associata al dominio della tua azienda: {domain}"
    values={{ domain }}
  />
);
export const LegalName = <FormattedMessage id="shared.LegalName" defaultMessage="Nome legale" />;
export const Place = <FormattedMessage id="shared.Place" defaultMessage="Sede" />;
export const Logo = <FormattedMessage id="shared.Logo" defaultMessage="Logo" />;
export const Company = <FormattedMessage id="shared.Company" defaultMessage="Società" />;
export const Role = <FormattedMessage id="shared.Role" defaultMessage="Ruolo" />;
export const Matching = companyName => (
  <FormattedMessage
    id="shared.Matching"
    values={{ companyName }}
    defaultMessage="Matching {companyName}"
  />
);
export const VeryHigh = <FormattedMessage id="shared.VeryHigh" defaultMessage="MOLTO ALTO" />;
export const High = <FormattedMessage id="shared.High" defaultMessage="ALTO" />;
export const Medium = <FormattedMessage id="shared.Medium" defaultMessage="MEDIO" />;
export const Low = <FormattedMessage id="shared.Low" defaultMessage="BASSO" />;
export const CompanyDisabledFields = (
  <FormattedMessage
    id="shared.CompanyDisabledFields"
    defaultMessage="Registro impresa (non modificabile)"
  />
);
export const OwnershipRequestSuccess = (
  <FormattedMessage id="shared.OwnershipRequestSuccess" defaultMessage="Proprietà richiesta" />
);
export const OwnershipRequestError = (
  <FormattedMessage id="shared.OwnershipRequestError" defaultMessage="Proprietà non richiesta" />
);
export const Profile = <FormattedMessage id="shared.Profile" defaultMessage="Profilo" />;
export const Website = <FormattedMessage id="shared.Website" defaultMessage="Sito web" />;
export const WebsiteHelperText = (
  <FormattedMessage
    id="shared.WebsiteHelperText"
    defaultMessage="Inserisci il sito web per la verifica manuale"
  />
);
export const Message = <FormattedMessage id="shared.Message" defaultMessage="Messaggio" />;
export const CreatePropertyRequestSuccess = (
  <FormattedMessage id="shared.CreatePropertyRequestSuccess" defaultMessage="Proprietà richiesta" />
);
export const CreatePropertyRequestError = (
  <FormattedMessage
    id="shared.CreatePropertyRequestError"
    defaultMessage="Proprietà non richiesta, se hai già fatto una richiesta per questa società potrebbe essere ancora in fase di elaborazione"
  />
);
export const Favourite = <FormattedMessage id="shared.Favourite" defaultMessage="Preferito" />;
export const CreateFavouriteSuccess = (
  <FormattedMessage id="shared.CreateFavouriteSuccess" defaultMessage="Aggiunto ai preferiti" />
);
export const CreateFavouriteError = (
  <FormattedMessage id="shared.CreateFavouriteError" defaultMessage="Non aggiunto ai preferiti" />
);
export const DeleteFavouriteSuccess = (
  <FormattedMessage id="shared.DeleteFavouriteSuccess" defaultMessage="Rimosso dai preferiti" />
);
export const DeleteFavouriteError = (
  <FormattedMessage id="shared.DeleteFavouriteError" defaultMessage="Non rimosso dai preferiti" />
);
export const FailedStartupImportTitle = (
  <FormattedMessage id="shared.FailedStartupImportTitle" defaultMessage="Non trovi la tua startup?" />
);
export const FailedStartupImportContactUs = (
  <FormattedHTMLMessage id="shared.FailedStartupImportContactUs" defaultMessage={`Non è stato possibile trovare la startup in maniera automatica.<br/><br/>Contattaci inviando un'email a <b><a href="mailto:info@upbase.eu">info@upbase.eu</a></b> comunicando i dati dell'azienda da importare e ti faremo sapere il prima possibile.`} />
);
export const Close = (
  <FormattedMessage id="shared.Close" defaultMessage="Chiudi" />
);