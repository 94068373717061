/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { compose, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import * as yup from "yup";
import { search } from "../ducks/actions";
import AutoForm from "../shared/components/AutoForm";
import FullScreenDialog from "../shared/components/FullScreenDialog";
import * as SharedMessages from "../shared/components/Messages";
import CurrencyField from "../shared/formik/CurrencyField";
import DatePickerField from "../shared/formik/DatePickerField";
import ReactSelectField from "../shared/formik/ReactSelectField";
import { fundingStatusOptions } from "../shared/utils";
import * as Messages from "./Messages";

const enhanceCompany = compose(
  connect(undefined, dispatch => ({ actions: bindActionCreators({ search }, dispatch) })),
  withHandlers({
    onLoadCompany: ({ actions }) => async (inputValue, callback) => {
      const { response } = await actions.search({
        mode: "light",
        type: "startups",
        name: inputValue
      });
      const companies = response.data.data.map(company => ({
        label: company.name,
        value: company.id
      }));
      callback(companies);
    }
  })
);

const useStyles = makeStyles(() => ({
  field: {
    marginTop: 8
  }
}));

const CompanyField = enhanceCompany(({ form, field, onLoadCompany, ...other }) => {
  const handleChange = option => {
    form.setFieldValue(field.name, option, true);
  };

  const classes = useStyles();

  return (
    <AsyncSelect
      {...field}
      loadOptions={onLoadCompany}
      placeholder={Messages.CompanyPlaceholder}
      onChange={handleChange}
      className={classes.field}
      {...other}
    />
  );
});

const RoundDialog = ({ title, onClose, onSubmit, round, isEdit }) => {
  const [fieldConfiguration] = useState({
    default: {
      fields: [
        {
          name: "status_description",
          label: "Status*",
          component: ReactSelectField,
          options: fundingStatusOptions
        },
        {
          name: "amount",
          label: "Importo",
          component: CurrencyField,
          outputFormat: "number",
          currencySymbol: "€",
          minimumValue: "0",
          decimalCharacter: ",",
          digitGroupSeparator: "."
        },
        {
          name: "date",
          label: "Data dell'investimento",
          component: DatePickerField,
          format: "DD/MM/YYYY",
          inputVariant: "outlined",
          disableFuture: true,
          variant: "inline"
        },
        {
          name: "company",
          label: "Company",
          component: CompanyField,
          hidden: isEdit
        }
      ]
    }
  });

  const [validationSchema] = useState(
    yup.object().shape({
      date: yup
        .mixed()
        .required()
        .typeError("Errore data"),
      status_description: yup.string().required(),
      amount: yup
        .string()
        // .matches(
        //   /^[0-9]+(.[0-9]{1,2})?$/,
        //   "Importo non valido (usare il punto come virgola decimale)"
        // )
        .required(),
      company: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
        .required()
    })
  );

  const [formikProps] = useState({
    enableReinitialize: true,
    initialValues: {
      ...round,
      date: (round || {}).date ? round.date : null,
      company: {
        label: ((round || {}).company || {}).name,
        value: ((round || {}).company || {}).id
      }
    },
    onSubmit,
    validationSchema
  });

  return (
    <FullScreenDialog title={title} onClose={onClose}>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={fieldConfiguration}
        label={SharedMessages.Save}
      />
    </FullScreenDialog>
  );
};

const RoundShape = PropTypes.shape({
  status_description: PropTypes.string,
  amount: PropTypes.string,
  date: PropTypes.string
});

RoundDialog.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  round: RoundShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RoundDialog;
