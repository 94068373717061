/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PowerButton from "./PowerButton";

const PowerButtonLink = ({ to, ...props }) => (
  <Link to={to}>
    <PowerButton {...props} />
  </Link>
);

PowerButtonLink.propTypes = {
  to: PropTypes.string
};

PowerButtonLink.defaultProps = {
  to: ""
};

export default PowerButtonLink;
