import { Fab, styled } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

const RightFab = styled(Fab)({
  float: "right"
});

const FabButton = ({ show, to, icon: Icon = EditIcon }) => {
  if (!show) {
    return null;
  }
  return (
    <Link to={to}>
      <RightFab color="primary">
        <Icon />
      </RightFab>
    </Link>
  );
};

export default FabButton;
