import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import * as Messages from "./Messages";

const useStyles = makeStyles(theme => ({
  veryHigh: {
    backgroundColor: theme.palette.success.dark,
    borderRadius: "8px"
  },
  high: {
    backgroundColor: theme.palette.success.main,
    borderRadius: "8px"
  },
  medium: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: "8px"
  },
  low: {
    backgroundColor: theme.palette.error.main,
    borderRadius: "8px"
  }
}));

const MatchingChip = ({ value }) => {
  const classes = useStyles();

  if (value > 0.7) {
    return <Chip label={Messages.VeryHigh} className={classes.veryHigh} />;
  }
  if (value > 0.5) {
    return <Chip label={Messages.High} className={classes.high} />;
  }
  if (value > 0.3) {
    return <Chip label={Messages.Medium} className={classes.medium} />;
  }
  return <Chip label={Messages.Low} className={classes.low} />;
};

export default MatchingChip;
