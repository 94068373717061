import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import CompanyTabLayout from "./CompanyTabLayout";
import CultureTab from "./CultureTab";
import FundingTab from "./FundingTab";
import OverviewTab from "./OverviewTab";
import TeamTab from "./TeamTab";

const CompanyPage = () => {
  const { path, url } = useRouteMatch();
  return (
    <CompanyTabLayout>
      <Switch>
        <Route path={`${path}/overview`} component={OverviewTab} />
        <Route path={`${path}/team`} component={TeamTab} />
        <Route path={`${path}/culture`} component={CultureTab} />
        <Route path={`${path}/funding`} component={FundingTab} />
        <Redirect to={`${url}/overview`} />
      </Switch>
    </CompanyTabLayout>
  );
};

export default CompanyPage;
