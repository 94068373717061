import { LinearProgress } from "@material-ui/core";
import _, { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  withHandlers,
  withProps,
  withState
} from "recompose";
import { bindActionCreators } from "redux";
import {
  createCompanyOwner,
  getCompany,
  getInvestor,
  getPerson,
  getProfile
} from "../ducks/actions";
import {
  company,
  companyLoading,
  investor,
  investorLoading,
  isInvestorOwned,
  person,
  personLoading,
  profile,
  profileLoading
} from "../ducks/selectors";
import { withNotifications } from "../notifications";
import TabLayout from "../shared/components/TabLayout";

const enhancePerson = compose(
  connect(
    state => ({ person: person(state), personLoading: personLoading(state) }),
    dispatch => ({ actions: bindActionCreators({ getPerson }, dispatch) })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions, investor } = this.props;
      await actions.getPerson({ id: investor.investorable.id });
    }
  }),
  branch(
    ({ personLoading }) => personLoading,
    renderComponent(() => <LinearProgress />)
  ),
  withProps(({ profile, person }) => ({
    entity: person,
    showMatching: !_.isEmpty(profile.user.company_owners)
  }))
);

const enhanceCompany = compose(
  connect(
    (
      state,
      {
        match: {
          params: { id }
        }
      }
    ) => ({
      company: company(state),
      companyLoading: companyLoading(state),
      isInvestorOwned: isInvestorOwned({ investorId: id, state: "accepted" })(state),
      isOwnershipPending: isInvestorOwned({ investorId: id, state: "pending" })(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getProfile, createCompanyOwner, getCompany }, dispatch)
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions, investor } = this.props;
      await actions.getCompany({ id: investor.investorable.id });
    }
  }),
  branch(
    ({ company, companyLoading }) => isEmpty(company) || companyLoading,
    renderComponent(() => <LinearProgress />)
  ),
  withProps(({ company, profile, isInvestorOwned }) => ({
    entity: company,
    isCompany: true,
    canRequestOwnership: !isInvestorOwned,
    showMatching: !_.isEmpty(profile.user.company_owners)
  }))
);

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    state => ({
      investor: investor(state),
      profile: profile(state),
      investorLoading: investorLoading(state),
      profileLoading: profileLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getProfile, getInvestor }, dispatch)
    })
  ),
  lifecycle({
    async componentDidMount() {
      const {
        actions,
        match: {
          params: { id }
        }
      } = this.props;
      await actions.getInvestor({ id });
      actions.getProfile();
    }
  }),
  branch(
    ({ investor, investorLoading, profileLoading }) =>
      !investor.investorable || investorLoading || profileLoading,
    renderComponent(() => <LinearProgress />)
  ),
  branch(({ investor }) => investor.investorable.type === "person", enhancePerson, enhanceCompany),
  withState("tab", "setTab", ({ location }) => location.pathname.split("/")[3]),
  withProps(({ investor }) => ({
    isInvestor: true,
    investor
  })),
  withHandlers({
    onTabChange: ({ history, setTab }) => (_, newTab) => {
      setTab(newTab);
      history.push(newTab);
    }
  })
);

export default enhance(TabLayout);
