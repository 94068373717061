/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core";
import FullScreenDialog from "./FullScreenDialog";
import ReactSelectField from "../formik/ReactSelectField";
import AutoForm from "./AutoForm";
import * as Messages from "./Messages";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    flex: "0 100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const TeamEditDialog = ({ companyPerson, onClose, onSubmit }) => {
  const classes = useStyles();
  const [formikProps] = useState({
    initialValues: companyPerson,
    onSubmit,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      invitation_email: yup
        .string()
        .email()
        .nullable(),
      job_title: yup.string().required()
    })
  });
  const [formGroup] = useState({
    default: {
      fields: [
        {
          label: "Invitation Email",
          name: "invitation_email",
          helperText: Messages.InvitationEmailHelperText,
          hidden: (companyPerson || {}).aasm_state !== "pending"
        },
        { label: "Job Title", name: "job_title" },
        {
          label: "Founder",
          name: "is_founder",
          component: ReactSelectField,
          options: [
            { value: true, label: "Si" },
            { value: false, label: "No" }
          ]
        }
      ]
    }
  });

  return (
    <FullScreenDialog title="Team" onClose={onClose}>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={formGroup}
        label="Salva"
        classes={classes}
      />
    </FullScreenDialog>
  );
};

export default TeamEditDialog;
