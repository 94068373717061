import { styled } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import React from "react";
import Select, { components } from "react-select";

const DarkArrowDropDownIcon = styled(ArrowDropDownIcon)({
  color: "rgba(0, 0, 0, 0.54)"
});

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <DarkArrowDropDownIcon />
  </components.DropdownIndicator>
);
const ReactSelect = props => {
  return (
    <Select
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      {...props}
    />
  );
};

export default ReactSelect;
