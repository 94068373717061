/* eslint-disable react/jsx-props-no-spreading */

import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { avatarLoading, avatarUrl, profileLoading } from "../ducks/selectors";
import ImageUploader from "../shared/components/ImageUploader";
import * as Messages from "./Messages";

const AvatarUploader = ({ avatarUrl, avatarLoading, profileLoading, onFileUpload }) => {
  const form = useFormikContext();
  const handleFileUpload = (...params) => {
    if (form) {
      form.setFieldValue("avatarChanged", true);
    }
    onFileUpload(...params);
  };

  return (
    <ImageUploader
      imageUrl={avatarUrl}
      imageAlt={Messages.Avatar}
      loading={avatarLoading || profileLoading}
      onFileUpload={handleFileUpload}
    />
  );
};
AvatarUploader.propTypes = {
  avatarUrl: PropTypes.string,
  onFileUpload: PropTypes.func.isRequired,
  avatarLoading: PropTypes.bool,
  profileLoading: PropTypes.bool
};

AvatarUploader.defaultProps = {
  avatarUrl: null,
  avatarLoading: false,
  profileLoading: false
};

const enhance = compose(
  connect(state => ({
    avatarUrl: avatarUrl(state),
    avatarLoading: avatarLoading(state),
    profileLoading: profileLoading(state)
  }))
);

export default enhance(AvatarUploader);
