import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { updateCompanyPerson, getCompanyPeople, search } from "../ducks/actions";
import { companyPersonById, company } from "../ducks/selectors";
import { withNotifications } from "../notifications";
import * as Messages from "./Messages";
import TeamEditDialog from "../shared/components/TeamEditDialog";

const enhance = compose(
  withNotifications,
  connect(
    (
      state,
      {
        match: {
          params: { personId }
        }
      }
    ) => ({ company: company(state), companyPerson: companyPersonById(personId)(state) }),
    dispatch => ({
      actions: bindActionCreators({ updateCompanyPerson, getCompanyPeople, search }, dispatch)
    })
  ),
  withHandlers({
    onSubmit: ({
      ok,
      ko,
      match: {
        params: { id, personId }
      },
      company,
      history,
      actions,
      companyPerson
    }) => async values => {
      try {
        await actions.updateCompanyPerson({
          id: personId,
          companyPerson: {
            ...values,
            aasm_state: companyPerson.aasm_state === "proposed" ? "accepted" : undefined
          }
        });
        await actions.getCompanyPeople({ id: company.id });
        history.push(`/investors/${id}/team`);
        ok(Messages.InvestorUpdateSuccess);
      } catch (err) {
        ko(Messages.InvestorUpdateError);
      }
    },
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/investors/${id}/team`);
    }
  })
);

export default enhance(TeamEditDialog);
