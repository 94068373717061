import { Box, makeStyles, Typography } from "@material-ui/core";
import { BusinessCenter } from "@material-ui/icons";
import React, { useState } from "react";
import * as yup from "yup";
import DatePickerField from "../formik/DatePickerField";
import IndustrySelectField from "../formik/IndustrySelectField";
import MarkdownField from "../formik/MarkdownField";
import ReactSelectField from "../formik/ReactSelectField";
import TagsSelectField from "../formik/TagsSelectField";
import AutoForm from "./AutoForm";
import FullScreenDialog from "./FullScreenDialog";
import ImageUploader from "./ImageUploader";
import * as Messages from "./Messages";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    flex: "0 100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  business_model: {
    flex: "0 100%"
  }
}));

const CompanyOverviewEditDialog = ({ onClose, company = {}, onSubmit, onLogoUpload }) => {
  const classes = useStyles();

  const [formGroup] = useState({
    default: {
      fields: [
        { label: "Website progetto", name: "project_website" },
        { label: "Nome progetto", name: "project_name" },
        { label: "Descrizione", name: "description", component: MarkdownField, required: true },
        {
          label: "Stage startup",
          name: "startup_stage",
          component: ReactSelectField,
          options: [
            { value: "idea", label: "Idea" },
            { value: "development", label: "Sviluppo" },
            { value: "on_market", label: "Sul mercato" },
            { value: "scale", label: "Scale" },
            { value: "exit", label: "Exit" }
          ]
        },
        {
          label: "Stage prodotto o servizio",
          name: "product_and_service_stage",
          component: ReactSelectField,
          options: [
            { value: "idea", label: "Idea" },
            { value: "prototype", label: "Prototipo / Beta" },
            { value: "ready", label: "Pronto" },
            { value: "first_clients", label: "Primi Client" },
            { value: "on_market", label: "Sul mercato" }
          ]
        },
        {
          label: "Stage team",
          name: "team_stage",
          component: ReactSelectField,
          options: [
            { value: "to_be_defined", label: "Da definire" },
            { value: "technical_ok", label: "Ok lato tecnico" },
            { value: "business_ok", label: "Ok lato business" },
            { value: "complete", label: "Completo" }
          ]
        },
        {
          label: "Aree di vendita",
          name: "selling_areas",
          component: ReactSelectField,
          options: [
            { value: "0", label: "Italia" },
            { value: "1", label: "Estero" }
          ],
          isMulti: true
        },
        {
          label: "Canali di vendita",
          name: "selling_channels",
          component: ReactSelectField,
          options: [
            { value: "0", label: "Direttamente" },
            { value: "1", label: "Grande Distribuzione" },
            { value: "2", label: "E-Commerce" },
            { value: "3", label: "Agenti" }
          ],
          isMulti: true
        },
        { label: "Business model", name: "business_model", multiline: true, rows: 3 },
        {
          label: "Attività e spese in ricerca e sviluppo",
          name: "activity_and_expenses_description",
          multiline: true,
          rows: 3
        },
        {
          label: "Relazioni professionali con incubatori, investitori, università ricerca",
          name: "professional_relationships_description",
          multiline: true,
          rows: 3
        },
        {
          label: "Elenco diritti privativa su proprietà industriale, intellettuale",
          name: "intellectual_property_description",
          multiline: true,
          rows: 3
        },
        {
          label: "Settore",
          name: "industryId",
          component: IndustrySelectField
        },
        {
          label: "Lista dei tag",
          name: "tag_list",
          byIndustry: true,
          component: TagsSelectField
        },
        { label: "Email", name: "email" },
        { label: "Link profilo Facebook", name: "facebook_url" },
        { label: "Link profilo Twitter", name: "twitter_url" },
        { label: "Link profilo LinkedIn", name: "linkedin_url" },
        { label: "Link profilo Instagram", name: "instagram_url" }
      ]
    },
    settore_imprese: {
      label: Messages.CompanyDisabledFields,
      fields: [
        { label: "Nome", name: "name", disabled: true },
        {
          label: "Natura legale",
          name: "legal_nature",
          disabled: true
        },
        { label: "Comune", name: "municipality", disabled: true },
        { label: "Prov.", name: "pv", disabled: true },
        { label: "Regione", name: "region", disabled: true },
        {
          label: "Sezione startup",
          name: "start_date_in_the_startup_section",
          component: DatePickerField,
          format: "DD/MM/YYYY",
          disabled: true,
          inputVariant: "outlined",
          disableFuture: true,
          variant: "inline"
        },
        {
          label: "Costituzione impresa",
          name: "start_date_in_the_business_register",
          component: DatePickerField,
          format: "DD/MM/YYYY",
          disabled: true,
          inputVariant: "outlined",
          disableFuture: true,
          variant: "inline"
        },
        {
          label: "Inizio esercizio",
          name: "start_date_of_the_actual_exercise_of_the_activity",
          component: DatePickerField,
          format: "DD/MM/YYYY",
          disabled: true,
          inputVariant: "outlined",
          disableFuture: true,
          variant: "inline"
        },
        { label: "P. Iva", name: "tax_code", disabled: true },
        { label: "Ateco 2007", name: "ateco_2007", disabled: true },
        {
          label: "Settore",
          name: "sector",
          disabled: true
        },
        {
          label: "Attività",
          name: "activity",
          disabled: true
        },
        { label: "Website", name: "website", disabled: true },
        { label: "Sezione attività", name: "activity_section", disabled: true },
        { label: "Classe impiegati", name: "class_of_employees", disabled: true },
        { label: "Classe di valore prodotto", name: "class_of_product_value", disabled: true },
        { label: "Classe di capitale sociale", name: "class_of_social_capital", disabled: true },
        { label: "Alto valore tecnologico", name: "high_technological_value", disabled: true },
        { label: "Vocazione sociale", name: "social_vocation", disabled: true },
        { label: "Data di dichiarazione", name: "date_of_declaration", disabled: true },
        {
          label: "Spese di ricerca e sviluppo",
          name: "research_and_development_expenses",
          isDisabled: true,
          component: ReactSelectField,
          options: [
            { value: null, label: "Sconosciuto" },
            { value: "unknow", label: "Sconosciuto" },
            { value: "yes", label: "Si" },
            { value: "no", label: "No" }
          ]
        },
        {
          label: "Forza lavoro con qualifiche",
          name: "labor_force_with_qualifications",
          isDisabled: true,
          component: ReactSelectField,
          options: [
            { value: null, label: "Sconosciuto" },
            { value: "unknow", label: "Sconosciuto" },
            { value: "yes", label: "Si" },
            { value: "no", label: "No" }
          ]
        },
        {
          label: "Possesso di patenti",
          name: "possession_of_patents",
          isDisabled: true,
          component: ReactSelectField,
          options: [
            { value: null, label: "Sconosciuto" },
            { value: "unknow", label: "Sconosciuto" },
            { value: "yes", label: "Si" },
            { value: "no", label: "No" }
          ]
        },
        {
          label: "Prevalenza femminile",
          name: "female_prevalence",
          isDisabled: true,
          component: ReactSelectField,
          options: [
            { value: null, label: "Sconosciuto" },
            { value: "unknow", label: "Sconosciuto" },
            { value: "yes", label: "Si" },
            { value: "no", label: "No" },
            { value: "high", label: "Alta/o" },
            { value: "majority", label: "In maggioranza" },
            { value: "exclusive", label: "Esclusiva/o" }
          ]
        },
        {
          label: "Prevalenza giovanile",
          name: "youth_prevalence",
          isDisabled: true,
          component: ReactSelectField,
          options: [
            { value: null, label: "Sconosciuto" },
            { value: "unknow", label: "Sconosciuto" },
            { value: "yes", label: "Si" },
            { value: "no", label: "No" },
            { value: "high", label: "Alta/o" },
            { value: "majority", label: "In maggioranza" },
            { value: "exclusive", label: "Esclusiva/o" }
          ]
        },
        {
          label: "Prevalenza straniera",
          name: "prevalence_foreign",
          isDisabled: true,
          component: ReactSelectField,
          options: [
            { value: null, label: "Sconosciuto" },
            { value: "unknow", label: "Sconosciuto" },
            { value: "yes", label: "Si" },
            { value: "no", label: "No" },
            { value: "high", label: "Alta/o" },
            { value: "majority", label: "In maggioranza" },
            { value: "exclusive", label: "Esclusiva/o" }
          ]
        }
      ]
    }
  });

  const [formikProps] = useState({
    initialValues: company,
    onSubmit,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      description: yup.string().required(),
      email: yup
        .string()
        .email()
        .nullable(),
      facebook_url: yup
        .string()
        .nullable()
        .matches(
          /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      twitter_url: yup
        .string()
        .nullable()
        .matches(
          /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      linkedin_url: yup
        .string()
        .nullable()
        .matches(
          /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        ),
      instagram_url: yup
        .string()
        .nullable()
        .matches(
          /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/,
          "Il link inserito non è valido, se manca aggiungi il prefisso https:// o http://"
        )
    })
  });

  return (
    <FullScreenDialog title="Overview" onClose={onClose}>
      <Box display="flex" justifyContent="center" mb={2}>
        <ImageUploader
          imageUrl={company.logo_urls && company.logo_urls.small}
          imageAlt={company.name}
          icon={BusinessCenter}
          onFileUpload={onLogoUpload}
          isSquare
        />
      </Box>
      <Typography variant="body2" gutterBottom>
        {Messages.LogoUploadHelper}
      </Typography>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={formGroup}
        label={Messages.Save}
        classes={classes}
      />
    </FullScreenDialog>
  );
};

export default CompanyOverviewEditDialog;
