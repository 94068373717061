import React from "react";
import { LinearProgress } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, branch, renderComponent, withHandlers } from "recompose";
import { companyCulture, companyCultureLoading } from "../ducks/selectors";
import { getCompanyCulture, updateCompanyCulture, getCompany } from "../ducks/actions";
import { withNotifications } from "../notifications";
import * as Messages from "./Messages";
import CultureEditDialog from "../shared/components/CultureEditDialog";

const enhance = compose(
  withRouter,
  withNotifications,
  connect(
    state => ({
      companyCulture: companyCulture(state),
      companyCultureLoading: companyCultureLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getCompanyCulture, updateCompanyCulture, getCompany }, dispatch)
    })
  ),
  branch(
    ({ companyCultureLoading }) => companyCultureLoading,
    renderComponent(() => <LinearProgress />)
  ),
  withHandlers({
    onCultureEdit: ({
      actions,
      match: {
        params: { id }
      },
      history,
      ok,
      ko
    }) => async companyCulture => {
      try {
        await actions.updateCompanyCulture({ id, companyCulture });
        await actions.getCompanyCulture({ id });
        await actions.getCompany({ id });
        history.push(`/companies/${id}/culture`);
        ok(Messages.CompanyUpdateSuccess);
      } catch (err) {
        ko(Messages.CompanyUpdateError);
      }
    },
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/companies/${id}/culture`);
    }
  })
);

export default enhance(CultureEditDialog);
