export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_INTERESTS = "GET_INTERESTS";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const GET_MY_NEWS = "GET_MY_NEWS";
export const GET_MORE_MY_NEWS = "GET_MORE_MY_NEWS";
export const GET_NEWS = "GET_NEWS";
export const GET_MORE_NEWS = "GET_MORE_NEWS";
export const SEARCH = "SEARCH";
export const GET_COMPANY = "GET_COMPANY";
export const CREATE_COMPANY_OWNER = "CREATE_COMPANY_OWNER";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const GET_COMPANY_CULTURE = "GET_COMPANY_CULTURE";
export const UPDATE_COMPANY_CULTURE = "UPDATE_COMPANY_CULTURE";
export const GET_COMPANY_FUNDING_ROUNDS = "GET_COMPANY_FUNDING_ROUNDS";
export const CREATE_COMPANY_FUNDING_ROUND = "CREATE_COMPANY_FUNDING_ROUND";
export const UPDATE_COMPANY_FUNDING_ROUND = "UPDATE_COMPANY_FUNDING_ROUND";
export const DELETE_COMPANY_FUNDING_ROUND = "DELETE_COMPANY_FUNDING_ROUND";
export const GET_COMPANY_PEOPLE = "GET_COMPANY_PEOPLE";
export const GET_PERSON = "GET_PERSON";
export const CREATE_COMPANY_PERSON = "CREATE_COMPANY_PERSON";
export const UPDATE_COMPANY_PERSON = "UPDATE_COMPANY_PERSON";
export const DELETE_COMPANY_PERSON = "DELETE_COMPANY_PERSON";
export const GET_COMPANY_INVESTORS = "GET_COMPANY_INVESTORS";
export const UPDATE_COMPANY_LOGO = "UPDATE_COMPANY_LOGO";
export const UPDATE_PERSON = "UPDATE_PERSON";
export const GET_INVESTOR = "GET_INVESTOR";
export const CONFIRM_COMPANY_PERSON = "CONFIRM_COMPANY_PERSON";
export const GET_INVESTOR_FUNDING_ROUNDS = "GET_INVESTOR_FUNDING_ROUNDS";
export const CREATE_INVESTOR_FUNDING_ROUND = "CREATE_INVESTOR_FUNDING_ROUND";
export const UPDATE_INVESTOR_FUNDING_ROUND = "UPDATE_INVESTOR_FUNDING_ROUND";
export const DELETE_INVESTOR_FUNDING_ROUND = "DELETE_INVESTOR_FUNDING_ROUND";
export const CREATE_INVESTOR = "CREATE_INVESTOR";
export const CONFIRM_COMPANY_OWNER = "CONFIRM_COMPANY_OWNER";
export const GET_COMPANY_IMPORT = "GET_COMPANY_IMPORT";
export const SEARCH_AND_IMPORT_COMPANY = "SEARCH_AND_IMPORT_COMPANY";
export const GENERATE_COMPANY = "GENERATE_COMPANY";
export const GET_INTERESTS_TAGS = "GET_INTERESTS_TAGS";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_PEOPLE = "GET_PEOPLE";
export const GET_INVESTORS = "GET_INVESTORS";
export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const GET_INDUSTRIES_TAGS = "GET_INDUSTRIES_TAGS";
export const GET_INDUSTRY = "GET_INDUSTRY";
export const CREATE_COMPANY_PROPERTY_REQUEST = "CREATE_COMPANY_PROPERTY_REQUEST";
export const GET_FAVOURITES = "GET_FAVOURITES";
export const CREATE_FAVOURITE = "CREATE_FAVOURITE";
export const DELETE_FAVOURITE = "DELETE_FAVOURITE";
export const DELETE_INVESTOR = "DELETE_INVESTOR";
