import { Divider, styled, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import ReactSelect from "../shared/components/ReactSelect";
import { useQueryParams } from "../shared/hooks";
import * as Messages from "./Messages";

const SelectContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const SpaceDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4)
}));

const SortingSelector = ({ companies, withMatching = false }) => {
  const {
    queryParams: { sortBy = "matching" },
    mergeParams
  } = useQueryParams();

  const onChange = ({ value }) => {
    mergeParams({ sortBy: value });
  };

  const options = [
    { label: Messages.Alphabetical, value: "alphabetical" }
  ];

  if(withMatching) {
    options.push({ label: Messages.Matching(), value: "matching" });
  }

  if(!_.isEmpty(companies)) {
    options.pop();

    companies.forEach(el =>
      options.push({
        label: Messages.Matching(el.name),
        value: `matching_${el.id}`
      })
    );
  } else {
    options.push({ label: Messages.ProfileCompletion, value: "profile_completion" });
  }

  const [sortingOptions] = useState(options);

  const selectedOption = _.find(sortingOptions, { value: sortBy });

  return (
    <SelectContainer>
      <Typography variant="body2" gutterBottom>
        {Messages.SortBy}
      </Typography>
      <ReactSelect value={selectedOption} onChange={onChange} options={sortingOptions} />
      <SpaceDivider />
    </SelectContainer>
  );
};

export default SortingSelector;
