import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { updateCompanyPerson, getCompanyPeople, search, getCompany } from "../ducks/actions";
import { companyPersonById } from "../ducks/selectors";
import { withNotifications } from "../notifications";
import * as Messages from "./Messages";
import TeamEditDialog from "../shared/components/TeamEditDialog";

const enhance = compose(
  withNotifications,
  connect(
    (
      state,
      {
        match: {
          params: { personId }
        }
      }
    ) => ({ companyPerson: companyPersonById(personId)(state) }),
    dispatch => ({
      actions: bindActionCreators(
        { updateCompanyPerson, getCompanyPeople, search, getCompany },
        dispatch
      )
    })
  ),
  withHandlers({
    onSubmit: ({
      ok,
      ko,
      match: {
        params: { id, personId }
      },
      history,
      actions,
      companyPerson
    }) => async values => {
      try {
        await actions.updateCompanyPerson({
          id: personId,
          companyPerson: {
            ...values,
            aasm_state: companyPerson.aasm_state === "proposed" ? "accepted" : undefined
          }
        });
        await actions.getCompanyPeople({ id });
        await actions.getCompany({ id });
        history.push(`/companies/${id}/team`);
        ok(Messages.CompanyUpdateSuccess);
      } catch (err) {
        ko(Messages.CompanyUpdateError);
      }
    },
    onClose: ({
      history,
      match: {
        params: { id }
      }
    }) => () => {
      history.push(`/companies/${id}/team`);
    }
  })
);

export default enhance(TeamEditDialog);
