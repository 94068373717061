/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import * as yup from "yup";
import AutoForm from "./AutoForm";
import FullScreenDialog from "./FullScreenDialog";

const formGroup = {
  default: {
    fields: [
      { label: "Titolo del video", name: "title" },
      { label: "Descrivi il tuo video", name: "description", multiline: true, rows: 3 },
      { label: "Inserisci il link youtube al tuo video", name: "video_url" }
    ]
  }
};

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap"
  },
  field: {
    flex: "0 100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const CultureEditDialog = ({ onClose, companyCulture = {}, onCultureEdit }) => {
  const classes = useStyles();
  const [formikProps] = useState({
    initialValues: companyCulture,
    onSubmit: onCultureEdit,
    validationSchema: yup.object({
      title: yup.string().required(),
      description: yup.string().nullable(),
      video_url: yup
        .string()
        .nullable()
        .matches(
          /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be|vimeo.com))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
          "Inserisci un link di YouTube o di Vimeo"
        )
    })
  });

  return (
    <FullScreenDialog title="Culture" onClose={onClose}>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Inserisci un video di YouTube che ti rappresenta
      </Typography>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={formGroup}
        label="Salva"
        classes={classes}
      />
    </FullScreenDialog>
  );
};

export default CultureEditDialog;
