import { LinearProgress, styled, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getCompaniesQuery,
  getInvestorsQuery,
  getPeopleQuery,
  isAuthenticated
} from "../ducks/selectors";
import InvestorTable from "../shared/components/InvestorTable";
import PersonTable from "../shared/components/PersonTable";
import StartupTable from "../shared/components/StartupTable";
import * as Messages from "./Messages";
import SeeMore from "./SeeMore";
import NoResultsAskForImport from "./NoResultsAskForImport";

const LoaderContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const SectionTitle = styled(Typography)({
  fontFamily: "aktiv-grotesk",
  fontSize: 22,
  fontWeight: 900,
  lineHeight: "24px"
});

const TableContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const AllList = () => {
  const {
    data: { data: companies },
    loading: companiesLoading
  } = useSelector(getCompaniesQuery);
  const {
    data: { data: people },
    loading: peopleLoading
  } = useSelector(getPeopleQuery);
  const {
    data: { data: investors },
    loading: investorsLoading
  } = useSelector(getInvestorsQuery);
  const isAuth = useSelector(isAuthenticated);

  if (companiesLoading || peopleLoading || investorsLoading) {
    return (
      <LoaderContainer>
        <LinearProgress />
      </LoaderContainer>
    );
  }

  return (
    <>
      <SectionTitle variant="h6">{Messages.StartUp}</SectionTitle>
      <TableContainer>
        <StartupTable startups={companies} />
        {isAuth && _.isEmpty(companies) && <NoResultsAskForImport />}
        <SeeMore />
      </TableContainer>
      <SectionTitle variant="h6">{Messages.People}</SectionTitle>
      <TableContainer>
        <PersonTable people={people} />
        <SeeMore />
      </TableContainer>
      <SectionTitle variant="h6">{Messages.Investors}</SectionTitle>
      <TableContainer>
        <InvestorTable investors={investors} />
        <SeeMore />
      </TableContainer>
    </>
  );
};

export default AllList;
