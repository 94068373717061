import React from "react";
import PropTypes from "prop-types";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  icon: {
    color: "white"
  }
}));

const DismissButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  );
};

DismissButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default DismissButton;
