/* eslint-disable no-restricted-syntax */
import { CustomPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getProfileQuery, isAuthenticated } from "../../ducks/selectors";
import FavouriteDataProvider from "../react-grid/FavouriteDataProvider";
import LinkRow from "../react-grid/LinkRow";
import LogoDataProvider from "../react-grid/LogoDataProvider";
import MatchingDataProvider from "../react-grid/MatchingDataProvider";
import * as Messages from "./Messages";

const InvestorTable = ({
  investors,
  isPaginated,
  isCompanyOwner,
  page,
  perPage,
  total,
  onSetPage,
  onSetPerPage
}) => {
  const [columns] = useState([
    {
      name: "img_url",
      title: Messages.Logo,
      getCellValue: row =>
        row.investorable?.type === "person"
          ? // eslint-disable-next-line camelcase
            row.investorable?.avatar_urls?.thumb
          : // eslint-disable-next-line camelcase
            row.investorable?.logo_urls?.thumb
    },
    { name: "name", title: Messages.Name }
    // { name: "investments_number", title: Messages.InvestmentsNumber },
    // { name: "exits_number", title: Messages.ExitsNumber },
    // { name: "city", title: Messages.City }
  ]);

  const {
    data: { data: profile }
  } = useSelector(getProfileQuery);

  if (isCompanyOwner) {
    // eslint-disable-next-line no-restricted-syntax
    for (const owner of profile.user.company_owners) {
      // eslint-disable-next-line no-plusplus
      columns.push({
        name: `matching_${owner.company.name}`,
        title: Messages.Matching(owner.company.name),
        // eslint-disable-next-line no-loop-func
        getCellValue: row =>
          !_.isEmpty(row.company_investor_matches) &&
          row.company_investor_matches.find(el => el.company.id === owner.company.id)?.score
      });
    }
  }

  const isAuth = useSelector(isAuthenticated);

  if (isAuth) {
    columns.push({
      name: "favourite_id",
      title: Messages.Favourite
    });
  }
  const showPagination = isPaginated && isAuth;

  return (
    <Grid rows={investors} columns={columns}>
      {showPagination && (
        <PagingState
          currentPage={page || 0}
          onCurrentPageChange={onSetPage}
          pageSize={perPage || 10}
          onPageSizeChange={onSetPerPage}
        />
      )}
      {showPagination && <CustomPaging totalCount={total} />}
      <LogoDataProvider
        for={["img_url"]}
        altKey="logo"
        variant="square"
        fallbackIcon={BusinessCenterIcon}
      />
      {isCompanyOwner &&
        profile.user.company_owners.map(el => (
          <MatchingDataProvider for={[`matching_${el.company.name}`]} />
        ))}
      <FavouriteDataProvider type="investor" for={["favourite_id"]} />
      <Table
        rowComponent={props => (
          <LinkRow {...props} linkTo={({ row }) => `/investors/${row.id}/overview`} />
        )}
      />
      <TableHeaderRow />
      {showPagination && <PagingPanel pageSizes={[5, 10, 15]} />}
    </Grid>
  );
};

export default InvestorTable;
