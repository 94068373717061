import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  styled,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Messages from "./Messages";

const CloseContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end"
});

const ButtonToolbar = styled(Toolbar)(({ theme }) => ({
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(1)
  }
}));

const DialogContentSpaced = styled(DialogContent)(({ theme }) => ({
  "& > *": {
    marginBottom: theme.spacing(2)
  }
}));

const Title = styled(Typography)({ fontSize: 24 });

const NotLoggedDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose}>
      <DialogContentSpaced>
        <CloseContainer>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </CloseContainer>
        <Title variant="h6" align="center">
          {Messages.NotLoggedDialogTitle}
        </Title>
        <ButtonToolbar>
          <Button onClick={onClose} variant="outlined" fullWidth>
            {Messages.NoThanks}
          </Button>
          <Button component={RouterLink} to="/login" variant="contained" color="primary" fullWidth>
            {Messages.Login}
          </Button>
        </ButtonToolbar>
        <Typography variant="body2" align="center">
          {Messages.NotAMember}
          <br />
          <Link component={RouterLink} to="/sign-up">
            {Messages.CreateAccount}
          </Link>
        </Typography>
      </DialogContentSpaced>
    </Dialog>
  );
};

export default NotLoggedDialog;
