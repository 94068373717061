/* eslint-disable react/prop-types */
import React from "react";
import { compose, branch, renderComponent } from "recompose";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Avatar,
  IconButton,
  LinearProgress
} from "@material-ui/core";

import { Business as BusinessIcon } from "@material-ui/icons";
import { profile, profileLoading } from "../ducks/selectors";

const profileBoxStyles = makeStyles(() => ({
  profileBox: {
    border: "1px solid #EBEBEB",
    borderRadius: 5,
    padding: "16px"
  },
  profile: {
    width: "100%",
    marginBottom: 8
  },
  profileName: { flexGrow: 1, fontSize: 18, letterSpacing: "-0.4px", lineHeight: "30px" },
  company: { "& img, & svg": { height: 25, width: 25, marginRight: 8 } },
  avatar: { marginRight: 16 },
  settings: {
    alignSelf: "start",
    height: "30px",
    width: "20px",
    minWidth: "20px",
    padding: "6px 16px",
    marginTop: "5px"
  }
}));

const enhance = compose(
  connect(state => ({
    profile: profile(state),
    profileLoading: profileLoading(state)
  })),
  branch(
    ({ profileLoading }) => profileLoading,
    renderComponent(() => <LinearProgress />)
  )
);

const ProfileBox = ({ profile }) => {
  const classes = profileBoxStyles();
  return (
    <Grid item className={classes.profileBox}>
      <Box display="flex" alignItems="center" className={classes.profile}>
        {profile.avatar_urls && (
          <Avatar src={profile.avatar_urls.thumb} className={classes.avatar}>
            {profile.first_name &&
              profile.last_name &&
              `${profile.first_name[0]}${profile.last_name[0]}`}
          </Avatar>
        )}
        <Typography noWrap variant="h6" className={classes.profileName}>
          {profile.first_name}
          <br />
          {profile.last_name}
        </Typography>
        <IconButton className={classes.settings} component={Link} to="/profile/me">
          <img src="images/settings-icon.png" alt="profile_settings" />
        </IconButton>
      </Box>
      {profile.last_job_title && (
        <Typography className={classes.role}>{profile.last_job_title}</Typography>
      )}
      <Box className={classes.company} display="flex" alignItems="center">
        <span>
          <BusinessIcon />
        </span>
        {profile.last_company_name && (
          <Typography component="span">{profile.last_company_name}</Typography>
        )}
      </Box>
    </Grid>
  );
};

export default enhance(ProfileBox);
