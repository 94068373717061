import { Box, Fab, Grid, makeStyles, styled, Tooltip, Typography } from "@material-ui/core";
import { School as SchoolIcon, Delete as DeleteIcon } from "@material-ui/icons";
import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import DeleteInvestorDialog from "./DeleteInvestorDialog";
import FabButton from "./FabButton";
import * as Messages from "./Messages";
import OutlinedDiv from "./OutlinedDiv";

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  icon: {
    width: 48,
    height: 48
  }
}));

const RightFab = styled(Fab)({
  float: "right",
  marginRight: "5px"
});

const Section = styled(Box)({
  marginBottom: "16px"
});

const Row = ({ label, value }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={3}>
      <Typography variant="button">{label}</Typography>
    </Grid>
    <Grid item xs={12} md={9}>
      <Typography variant="body1">{value || Messages.NotDescribed}</Typography>
    </Grid>
  </Grid>
);

const PersonOverview = ({
  person,
  isPersonClaimed,
  isInvestor,
  investor,
  onDelete,
  open,
  setOpen
}) => {
  const { url } = useRouteMatch();
  const classes = useStyles();
  const { id } = useParams();

  return (
    <Box p={1}>
      <FabButton show={isPersonClaimed} to={`${url}/edit`} />
      {isInvestor && !investor.company_funding_rounds && (
        <div>
          <Tooltip title={Messages.DeleteInvestorTooltip} placement="top">
            <RightFab color="primary" onClick={() => setOpen(true)}>
              <DeleteIcon />
            </RightFab>
          </Tooltip>
          <DeleteInvestorDialog
            onConfirmClick={() => onDelete({ id })}
            open={open}
            setOpen={setOpen}
          />
        </div>
      )}
      <Typography variant="body1" className={classes.text}>
        {person.description || Messages.NotDescribed}
      </Typography>
      <Section>
        <Typography className={classes.text}>
          <strong>{Messages.Education}</strong>
        </Typography>
        <OutlinedDiv>
          <Box display="flex" alignItems="center" p={4}>
            <SchoolIcon className={classes.icon} />
            <Box marginLeft={4}>
              <Typography variant="button">{person.education || Messages.NotDescribed}</Typography>
            </Box>
          </Box>
        </OutlinedDiv>
      </Section>
      <Section>
        <Typography className={classes.text}>
          <strong>{Messages.About}</strong>
        </Typography>
        <OutlinedDiv>
          <Row label={Messages.WhatIDo} value={person.what_i_do} />
          <Row label={Messages.Achievements} value={person.achievements} />
          <Row label={Messages.Locations} value={person.locations} />
          <Row label={Messages.Markets} value={person.markets} />
        </OutlinedDiv>
      </Section>
    </Box>
  );
};

export default PersonOverview;
