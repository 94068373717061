import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { getCompanyQuery, isInvestorOwned } from "../ducks/selectors";
import OverviewTab from "../shared/components/OverviewTab";
import CompanyOverviewEdit from "./CompanyOverviewEdit";

const CompanyOverviewTab = () => {
  const { id } = useParams();
  const { path } = useRouteMatch();

  const {
    data: { data: company }
  } = useSelector(getCompanyQuery);
  const isInvestorOwnedBool = useSelector(isInvestorOwned({ investorId: id, state: "accepted" }));

  return (
    <>
      <Switch>
        <Route path={`${path}/edit`} component={CompanyOverviewEdit} />
      </Switch>
      <OverviewTab company={company} isCompanyOwned={isInvestorOwnedBool} />
    </>
  );
};

export default CompanyOverviewTab;
