import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const SignUp = <FormattedMessage id="signup.SignUp" defaultMessage="Sign up" />;
export const Email = <FormattedMessage id="signup.Email" defaultMessage="Email" />;
export const Password = <FormattedMessage id="signup.Password" defaultMessage="Password" />;
export const PasswordConfirmation = (
  <FormattedMessage id="signup.PasswordConfirmation" defaultMessage="Conferma password" />
);
export const UserNotCreated = (
  <FormattedMessage id="signup.UserNotCreated" defaultMessage="L'utente non è stato creato" />
);
export const SignUpMessage = (
  <FormattedMessage
    id="signup.SignUpMessage"
    defaultMessage="Inserisci la tua mail e password per poterti connettere ad UpBase"
  />
);
