import { deserialize } from "../../node_modules/deserialize-json-api/src/index";

import { SOCIAL_LOGIN } from "./types";

function onRequestSaga(request, action) {
  if ([SOCIAL_LOGIN].includes(action.type)) {
    return request;
  }
  request.headers = {
    ...request.headers,
    authorization: localStorage.getItem("UPBASE_AUTH_TOKEN")
  };
  return request;
}

function onSuccessSaga(response, action) {
  if ([SOCIAL_LOGIN].includes(action.type)) {
    console.log(' deserialize-json-api social', response)

    localStorage.setItem("UPBASE_AUTH_TOKEN", response.data.token);
  }
  console.log(' deserialize-json-api action', action)

  if (action.meta && action.meta.serialize) {
    console.log(' deserialize-json-api response.data', response)
    console.log(' deserialize-json-api action', action)


    if (action.type === 'GET_INVESTORS') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_PERSON') {
      const deserializedData = response
      console.log('deserializedData GET_PERSON', deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_PEOPLE') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'SOCIAL_LOGIN') {
      const deserializedData = response
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }

    if (action.type === 'GET_PROFILE') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'CREATE_PROFILE') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'UPDATE_PROFILE') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_INTERESTS') { 
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    /*
    if (action.type === 'UPDATE_AVATAR') {  
      if (action.type === 'UPDATE_AVATAR') {
      //20.09.22 controllato, senza la response, non visualizzo i campi del profile dopo l'update
      // inoltre, da errore dopo aver caricato un'immagine, utilizzare request.POST.get/request.FILES per risolvere (su django)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    */
    if (action.type === 'GET_MY_NEWS') {  // (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
   
    if (action.type === 'GET_MORE_MY_NEWS') { // (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_NEWS') { // (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_MORE_NEWS') { // (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    /*
    if (action.type === 'SEARCH') { NO JSON
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    } */
    if (action.type === 'GET_COMPANY') {
      const deserializedData = response
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
  /*  if (action.type === 'UPDATE_COMPANY') {  NO JSON, I TAGS NON VENGONO VISUALIZZATI SU ENTRAMBI (FATTO MA DA RISOLVERE BUG TAGS)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'CREATE_COMPANY_OWNER') { NO JSON
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'CONFIRM_COMPANY_OWNER') { NO JSON
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    */
    if (action.type === 'GET_COMPANY_CULTURE') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    
    if (action.type === 'UPDATE_COMPANY_CULTURE') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
  
    if (action.type === 'GET_COMPANY_FUNDING_ROUNDS') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
  
    if (action.type === 'CREATE_COMPANY_FUNDING_ROUND') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'UPDATE_COMPANY_FUNDING_ROUND') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }

    if (action.type === 'DELETE_COMPANY_FUNDING_ROUND') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
  
    if (action.type === 'GET_COMPANY_PEOPLE') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    
    if (action.type === 'GET_PERSON') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    
    if (action.type === 'CREATE_COMPANY_PERSON') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }

    if (action.type === 'UPDATE_COMPANY_PERSON') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }

    if (action.type === 'DELETE_COMPANY_PERSON') { 
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_COMPANY_INVESTORS') {
       const deserializedData = response.data
       console.log(' deserialize-json-api  ',action.type, deserializedData)
       return { data: deserializedData };
    }
    /*
    if (action.type === 'UPDATE_COMPANY_LOGO') { 
      if (action.type === 'UPDATE_COMPANY_LOGO') {
      //20.09.2022 controllato, va in 404 http://localhost:8778/api/internal/v1/companies/4062/logo
      //la response dovrebbe servire per ripopolare la form con i valori già settati
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    */
    if (action.type === 'UPDATE_PERSON') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_INVESTOR') {
      const deserializedData = response
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'CONFIRM_COMPANY_PERSON') { // SI JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    
    if (action.type === 'GET_INVESTOR_FUNDING_ROUNDS') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    
    if (action.type === 'CREATE_INVESTOR_FUNDING_ROUND') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'UPDATE_INVESTOR_FUNDING_ROUND') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'DELETE_INVESTOR_FUNDING_ROUND') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    /*
    if (action.type === 'CREATE_INVESTOR') { 
      if (action.type === 'CREATE_INVESTOR') {
      //20.09.22 controllato, utilizzato in InvestorForm.jsx, da errore su update
      //e non si può verificare questa chiamata
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_COMPANY_IMPORT') { NO JSON
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'SEARCH_AND_IMPORT_COMPANY') { NO JSON 
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GENERATE_COMPANY') { NO JSON 500 CHIAMATA
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_INTERESTS_TAGS') { NON VIENE UTILIZZATO
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    } */

    if (action.type === 'GET_COMPANIES') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    
    if (action.type === 'GET_INDUSTRIES') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_INDUSTRIES_TAGS') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'GET_INDUSTRY') { // NO JSON
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    /*
    if (action.type === 'CREATE_COMPANY_PROPERTY_REQUEST') { NO JSON
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    */  
    if (action.type === 'GET_FAVOURITES') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'CREATE_FAVOURITE') { // NO JSON (FATTO)
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    if (action.type === 'DELETE_FAVOURITE') {
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
    /*
    if (action.type === 'DELETE_INVESTOR') { 
      const deserializedData = response.data
      console.log(' deserialize-json-api  ',action.type, deserializedData)
      return { data: deserializedData };
    }
 */
    const deserializedData =  deserialize(response.data);
    console.log (' deserialize-json-api deserializedData',deserializedData)
    return { data: deserializedData };
    
  }

  return response;
}

function onErrorSaga(error) {
  if (error && error.response && error.response.status === 401) {
    localStorage.removeItem("UPBASE_AUTH_TOKEN");
    window.location.href = "/";
  }

  return { error };
}

function* onAbortSaga() {
  // do sth, for example an action dispatch
}

export default {
  onRequest: onRequestSaga,
  onSuccess: onSuccessSaga,
  onError: onErrorSaga,
  onAbort: onAbortSaga
};
