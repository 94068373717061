import { CustomPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../../ducks/selectors";
import FavouriteDataProvider from "../react-grid/FavouriteDataProvider";
import LinkRow from "../react-grid/LinkRow";
import LogoDataProvider from "../react-grid/LogoDataProvider";
import MatchingDataProvider from "../react-grid/MatchingDataProvider";
import PlaceDataProvider from "../react-grid/PlaceDataProvider";
import * as Messages from "./Messages";

const StartupTable = ({
  startups,
  isPaginated,
  isInvestor,
  page,
  perPage,
  total,
  onSetPage,
  onSetPerPage
}) => {
  const isAuth = useSelector(isAuthenticated);

  const showPagination = isPaginated && isAuth;

  const [columns] = useState([
    {
      name: "logo_urls",
      title: Messages.Logo,
      getCellValue: (row, name) => {
        return row[name]?.small;
      }
    },
    {
      name: "name",
      title: Messages.Name,
      getCellValue: row => row.project_name || row.name
    },
    { name: "place", title: Messages.Place },
    { name: "sector", title: Messages.Sector },
    { name: "profile_completion_percent", title: Messages.StartupProfileCompletePercentage, getCellValue: row => `${row.profile_completion_percent || 0}%` }
  ]);

  if (isInvestor) {
    columns.push({
      name: "matching",
      title: Messages.Matching(),
      getCellValue: row =>
        row.company_investor_matches?.length && row.company_investor_matches[0].score
    });
  }

  if (isAuth) {
    columns.push({
      name: "favourite_id",
      title: Messages.Favourite
    });
  }

  return (
    <Grid rows={startups} columns={columns}>
      {showPagination && (
        <PagingState
          currentPage={page || 0}
          onCurrentPageChange={onSetPage}
          pageSize={perPage || 10}
          onPageSizeChange={onSetPerPage}
        />
      )}
      {showPagination && <CustomPaging totalCount={total} />}
      <LogoDataProvider
        for={["logo_urls"]}
        altKey="name"
        variant="square"
        fallbackIcon={BusinessCenterIcon}
      />
      <PlaceDataProvider for={["place"]} />
      <MatchingDataProvider for={["matching"]} />
      <FavouriteDataProvider type="company" for={["favourite_id"]} />
      <Table
        rowComponent={props =>
          isAuth ? (
            <LinkRow {...props} linkTo={({ row }) => `/companies/${row.id}/overview`} />
          ) : (
            <Table.Row {...props} />
          )
        }
      />
      <TableHeaderRow />
      {showPagination && <PagingPanel pageSizes={[5, 10, 15]} />}
    </Grid>
  );
};

export default StartupTable;
