/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import _, { reduce } from "lodash";
import moment from "moment";
import * as types from "./types";

export const socialLogin = ({ idToken, email }) => ({
  type: types.SOCIAL_LOGIN,
  request: {
    url: "/api/internal/v2/login/social/firebase",
    method: "POST",
    data: { id_token: idToken, email }
  }
});

export const logout = () => ({
  type: types.LOGOUT
});

export const getProfile = () => ({
  type: types.GET_PROFILE,
  request: {
    url: "/api/internal/v2/profiles/me",
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const createProfile = ({
  profile: { firstName, lastName, birthday, place, lastCompanyName, lastJobTitle }
}) => ({
  type: types.CREATE_PROFILE,
  request: {
    url: "/api/internal/v1/profiles/me",
    method: "POST",
    data: {
      profile: {
        first_name: firstName,
        last_name: lastName,
        birthday,
        place,
        last_company_name: lastCompanyName,
        last_job_title: lastJobTitle
      }
    }
  }
});

export const updateProfile = ({
  profile: { firstName, lastName, birthday, place, lastCompanyName, lastJobTitle, interestIds }
}) => ({
  type: types.UPDATE_PROFILE,
  request: {
    url: "/api/internal/v1/profiles/me",
    method: "PATCH",
    data: {
      profile: {
        first_name: firstName,
        last_name: lastName,
        birthday,
        place,
        last_company_name: lastCompanyName,
        last_job_title: lastJobTitle,
        interest_ids: interestIds
      }
    }
  }
});

export const getInterests = () => ({
  type: types.GET_INTERESTS,
  request: {
    url: "/api/internal/v2/interests",
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const updateAvatar = ({ avatar }) => {
  const formData = new FormData();
  formData.set("profile[avatar]", avatar);

  return {
    type: types.UPDATE_AVATAR,
    request: {
      url: "/api/internal/v1/profiles/me/avatar",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      method: "patch",
      data: formData
    },
    meta: {
      serialize: true,
      mutations: {
        [types.GET_PROFILE]: (data, mutationData) => {
          return { ...data, ...mutationData };
        }
      }
    }
  };
};

export const getMyNews = ({ source, page, perPage }) => ({
  type: types.GET_MY_NEWS,
  request: {
    url: `/api/internal/v1/news/me/${source}`,
    params: {
      page,
      per_page: perPage
    }
  }
});

export const getMoreMyNews = ({ source, page, perPage }) => ({
  type: types.GET_MORE_MY_NEWS,
  request: {
    url: `/api/internal/v1/news/me/${source}`,
    params: {
      page,
      per_page: perPage
    }
  },
  meta: {
    mutations: {
      [types.GET_MY_NEWS]: (data, mutationData) => {
        return data
          ? { data: [...data.data, ...mutationData.data], meta: mutationData.meta }
          : mutationData;
      }
    }
  }
});

export const getNews = ({ source, page, perPage }) => ({
  type: types.GET_NEWS,
  request: {
    url: `/api/internal/v1/news/${source}`,
    params: {
      page,
      per_page: perPage
    }
  }
});

export const getMoreNews = ({ source, page, perPage }) => ({
  type: types.GET_MORE_NEWS,
  request: {
    url: `/api/internal/v1/news/${source}`,
    params: {
      page,
      per_page: perPage
    }
  },
  meta: {
    mutations: {
      [types.GET_NEWS]: (data, mutationData) => {
        return data
          ? { data: [...data.data, ...mutationData.data], meta: mutationData.meta }
          : mutationData;
      }
    }
  }
});

const mapSearchParamsToRansack = {
  name: "q[search_by_normalized]",
  tags: "q[tagged_with_all]"
  // construction_date_from: "q[start_date_in_the_startup_section_gt]",
  // construction_date_to: "q[start_date_in_the_startup_section_lt]",
  // financing_from: "q[financing_gt]",
  // financing_to: "q[financing_lt]",
  // sector: "q[sector_i_cont]",
  // seeking_financing: "q[seeking_financing_true]",
  // compiled_profile: "q[compiled_profile_true]",
  // current_company: "q[current_company_i_cont]",
  // previous_company: "q[previous_company_i_cont]",
  // formation: "q[formation_i_cont]",
  // residence: "q[residence_i_cont]",
  // role: "q[role_i_cont]",
  // spoke_to: "q[spoke_to_i_cont]",
  // city: "q[city_i_cont]",
  // investor_type: "q[investor_type_i_cont]",
  // investments_number_from: "q[investments_number_gt]",
  // investments_number_to: "q[investments_number_lt]",
  // exits_number_from: "q[exits_number_gt]",
  // exits_number_to: "q[exits_number_lt]",
  // startups_number_from: "q[startups_number_gt]",
  // startups_number_to: "q[startups_number_lt]",
  // investment_sector: "q[investment_sector_i_cont]",
  // investment_stage: "q[investment_stage_i_cont]",
  // investment_value_from: "q[investment_value_gt]",
  // investment_value_to: "q[investment_value_lt]"
};

const mapValueToRansack = value => ({
  seeking_financing: Boolean(value),
  compiled_profile: Boolean(value)
});

const mapTypeToBackend = {
  startups: "companies",
  people: "people",
  investors: "investors"
};

const mapTypeToSort = {
  startups: ["name asc"],
  people: ["first_name asc", "last_name asc"],
  investors: ["name asc"]
};

export const search = ({ mode, type, page, per_page, ...searchParams }) => {
  const params = reduce(
    searchParams,
    (result, value, key) => {
      if (mapSearchParamsToRansack[key])
        result[mapSearchParamsToRansack[key]] = mapValueToRansack(value)[key] || value;
      else result[`q[${key}_eq]`] = value;
      return result;
    },
    {}
  );

  return {
    type: types.SEARCH,
    request: {
      url: `/api/internal/v1/${mapTypeToBackend[type]}`,
      params: {
        ...params,
        "q[startups]": type === "startups",
        "q[s]": mapTypeToSort[type],
        mode,
        page,
        per_page
      }
    },
    meta: {
      serialize: true
    }
  };
};

export const getCompany = ({ id }) => ({
  type: types.GET_COMPANY,
  request: {
    url: `/api/internal/v2/companies/${id}`
  },
  meta: {
    serialize: true
  }
});

export const updateCompany = ({ id, company }) => ({
  type: types.UPDATE_COMPANY,
  request: {
    url: `/api/internal/v1/companies/${id}`,
    method: "PATCH",
    data: { company }
  },
  meta: {
    serialize: true
  }
});

export const createCompanyOwner = ({ id, ownerEmail: owner_email }) => ({
  type: types.CREATE_COMPANY_OWNER,
  request: {
    url: `/api/internal/v1/companies/${id}/company_owners`,
    method: "POST",
    data: {
      company_owner: {
        owner_email
      }
    }
  },
  meta: {
    serialize: true
  }
});

export const confirmCompanyOwner = ({ token }) => ({
  type: types.CONFIRM_COMPANY_OWNER,
  request: {
    url: `/api/internal/v1/company_owners/confirm/${token}`,
    method: "PATCH"
  },
  meta: {
    serialize: true
  }
});

export const getCompanyCulture = ({ id }) => ({
  type: types.GET_COMPANY_CULTURE,
  request: {
    url: `/api/internal/v2/companies/${id}/culture`
  },
  meta: {
    serialize: true
  }
});

export const updateCompanyCulture = ({ id, companyCulture: company_culture }) => ({
  type: types.UPDATE_COMPANY_CULTURE,
  request: {
    url: `/api/internal/v1/companies/${id}/culture`,
    method: "PATCH",
    data: { company_culture }
  },
  meta: {
    serialize: true
  }
});

export const getCompanyFundingRounds = ({ id }) => ({
  type: types.GET_COMPANY_FUNDING_ROUNDS,
  request: {
    url: `/api/internal/v2/companies/${id}/company_funding_rounds`
  },
  meta: {
    serialize: true
  }
});

export const createCompanyFundingRound = ({ companyId, round: company_funding_round }) => ({
  type: types.CREATE_COMPANY_FUNDING_ROUND,
  request: {
    url: `/api/internal/v1/companies/${companyId}/company_funding_rounds`,
    method: "POST",
    data: { company_funding_round }
  },
  meta: {
    serialize: true
  }
});

export const updateCompanyFundingRound = ({ roundId, round: company_funding_round }) => ({
  type: types.UPDATE_COMPANY_FUNDING_ROUND,
  request: {
    url: `/api/internal/v1/company_funding_rounds/${roundId}`,
    method: "PATCH",
    data: { company_funding_round }
  },
  meta: {
    serialize: true
  }
});

export const deleteCompanyFundingRound = ({ roundId }) => ({
  type: types.DELETE_COMPANY_FUNDING_ROUND,
  request: {
    url: `/api/internal/v1/company_funding_rounds/${roundId}`,
    method: "DELETE"
  },
  meta: {
    mutations: {
      [types.GET_COMPANY_FUNDING_ROUNDS]: ({ data }) => {
        return { data: data.filter(x => x.id !== roundId) };
      }
    }
  }
});
export const getCompanyPeople = ({ id }) => ({
  type: types.GET_COMPANY_PEOPLE,
  request: {
    url: `/api/internal/v2/companies/${id}/company_people`
  },
  meta: {
    serialize: true
  }
});

export const getPerson = ({ id }) => ({
  type: types.GET_PERSON,
  request: {
    url: `/api/internal/v2/people/${id}`
  },
  meta: {
    serialize: true
  }
});
export const createCompanyPerson = ({ id, companyPerson: company_person }) => ({
  type: types.CREATE_COMPANY_PERSON,
  request: {
    url: `/api/internal/v1/companies/${id}/company_people`,
    method: "POST",
    data: { company_person }
  },
  meta: {
    serialize: true
  }
});

export const updateCompanyPerson = ({ id, companyPerson: company_person }) => ({
  type: types.UPDATE_COMPANY_PERSON,
  request: {
    url: `/api/internal/v1/company_people/${id}`,
    method: "PATCH",
    data: { company_person }
  },
  meta: {
    serialize: true
  }
});

export const deleteCompanyPerson = ({ id }) => ({
  type: types.DELETE_COMPANY_PERSON,
  request: {
    url: `/api/internal/v1/company_people/${id}`,
    method: "DELETE"
  }
});

export const getCompanyInvestors = ({ id }) => ({
  type: types.GET_COMPANY_INVESTORS,
  request: {
    url: `/api/internal/v2/companies/${id}/investors`
  },
  meta: {
    serialize: true
  }
});

export const updateCompanyLogo = ({ id, logo }) => {
  const formData = new FormData();
  formData.set("company[logo]", logo);

  return {
    type: types.UPDATE_COMPANY_LOGO,
    request: {
      url: `/api/internal/v1/companies/${id}/logo`,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      method: "PATCH",
      data: formData
    },
    meta: {
      serialize: true,
      mutations: {
        [types.GET_COMPANY]: (data, mutationData) => {
          return { ...data, ...mutationData };
        }
      }
    }
  };
};

export const updatePerson = ({ id, person }) => ({
  type: types.UPDATE_PERSON,
  request: {
    url: `/api/internal/v1/people/${id}`,
    method: "PATCH",
    data: {
      person
    }
  },
  meta: {
    serialize: true
  }
});

export const getInvestor = ({ id }) => ({
  type: types.GET_INVESTOR,
  request: {
    url: `/api/internal/v2/investors/${id}`,
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const confirmCompanyPerson = ({ token }) => ({
  type: types.CONFIRM_COMPANY_PERSON,
  request: {
    url: `/api/internal/v1/company_people/confirm/${token}`,
    method: "POST"
  },
  meta: {
    serialize: true
  }
});

export const getInvestorFundingRounds = ({ investorId }) => ({
  type: types.GET_INVESTOR_FUNDING_ROUNDS,
  request: {
    url: `/api/internal/v2/investors/${investorId}/company_funding_round_investors`
  },
  meta: {
    serialize: true
  }
});

export const createInvestorFundingRound = ({
  investorId,
  companyFundingRound: company_funding_round_attributes
}) => ({
  type: types.CREATE_INVESTOR_FUNDING_ROUND,
  request: {
    url: `/api/internal/v1/investors/${investorId}/company_funding_round_investors`,
    method: "POST",
    data: {
      company_funding_round_investor: {
        company_funding_round_attributes
      }
    }
  },
  meta: {
    serialize: true
  }
});

export const updateInvestorFundingRound = ({
  id,
  companyFundingRound: company_funding_round_attributes
}) => ({
  type: types.UPDATE_INVESTOR_FUNDING_ROUND,
  request: {
    url: `/api/internal/v1/company_funding_round_investors/${id}`,
    method: "PATCH",
    data: {
      company_funding_round_investor: {
        company_funding_round_attributes
      }
    }
  },
  meta: {
    serialize: true
  }
});

export const deleteInvestorFundingRound = ({ id }) => ({
  type: types.DELETE_INVESTOR_FUNDING_ROUND,
  request: {
    url: `/api/internal/v1/company_funding_round_investors/${id}`,
    method: "DELETE"
  },
  meta: {
    mutations: {
      [types.GET_INVESTOR_FUNDING_ROUNDS]: ({ data, meta }) => {
        return { data: data.filter(item => item.id !== id), meta };
      }
    }
  }
});

export const createInvestor = ({ investor }) => ({
  type: types.CREATE_INVESTOR,
  request: {
    url: "/api/internal/v1/investors",
    method: "POST",
    data: {
      investor
    }
  },
  meta: {
    serialize: true
  }
});

export const getCompanyImport = ({ id }) => ({
  type: types.GET_COMPANY_IMPORT,
  request: {
    url: `/api/internal/v1/companies/import/${id}`
  },
  meta: {
    serialize: true
  }
});

export const searchAndImportCompany = ({ id }) => ({
  type: types.SEARCH_AND_IMPORT_COMPANY,
  request: {
    url: "/api/internal/v1/companies/import",
    method: "POST",
    data: {
      import: {
        id
      }
    }
  }
});

export const generateCompany = ({ id }) => ({
  type: types.GENERATE_COMPANY,
  request: {
    url: `/api/internal/v1/companies/import/${id}`,
    method: "PATCH",
    data: {}
  }
});

export const getInterestsTags = () => ({
  type: types.GET_INTERESTS_TAGS,
  request: {
    url: `/api/internal/v1/interests/tags`,
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const getCompanies = ({
  mode,
  startups,
  name,
  industryId,
  tags,
  constitutionYear,
  region,
  shareCapital,
  funding,
  sortBy,
  investorId,
  taxCode,
  page = 1,
  perPage = 10
}) => {
  let sortParam = { "q[order_by_score]": investorId };

  if (sortBy === "profile_completion") {
    sortParam = { "q[s]": ["profile_completion_percent desc"] };
  } else if (sortBy === "alphabetical" || !investorId) {
    sortParam = { "q[s]": ["name asc"] };
  }

  return {
    type: types.GET_COMPANIES,
    request: {
      url: "/api/internal/v2/companies",
      params: {
        "q[startups]": startups,
        "q[search_by_normalized]": name,
        "q[industries_id_eq]": industryId,
        "q[tagged_with_any]": tags,
        "q[start_date_of_the_actual_exercise_of_the_activity_gteq]": moment(
          constitutionYear,
          "YYYY"
        )
          .startOf("year")
          .format("YYYY-MM-DD"),
        "q[start_date_of_the_actual_exercise_of_the_activity_lt]": moment(constitutionYear, "YYYY")
          .endOf("year")
          .format("YYYY-MM-DD"),
        "q[region_eq]": region,
        "q[class_of_social_capital_eq]": shareCapital,
        "q[company_funding_rounds_status_description_eq]": funding,
        "q[tax_code_eq]": taxCode,
        ...sortParam,
        mode,
        page,
        per_page: perPage
      }
    },
    meta: {
      serialize: true
    }
  };
};

export const getPeople = ({ name, industryId, tags, page = 1, perPage = 10 }) => ({
  type: types.GET_PEOPLE,
  request: {
    url: "/api/internal/v2/people",
    params: {
      "q[search_by_normalized]": name,
      "q[industries_id_or_companies_industries_id_eq]": industryId,
      "q[tagged_with_all]": tags,
      "q[s]": ["first_name asc", "last_name asc"],
      page,
      per_page: perPage
    }
  },
  meta: {
    serialize: true
  }
});

export const getInvestors = ({
  name,
  industryId,
  tags,
  funding,
  sortBy,
  page = 1,
  perPage = 10
}) => {
  const sortByParams = sortBy ? sortBy.split("_") : "";
  const sortParam =
    sortByParams[0] === "matching"
      ? { "q[order_by_score]": sortByParams[1] }
      : { "q[s]": ["name asc"] };
  return {
    type: types.GET_INVESTORS,
    request: {
      url: "/api/internal/v2/investors",
      params: {
        "q[search_by_normalized]": name,
        "q[investorable_of_Person_type_industries_id_or_investorable_of_Person_type_companies_industries_id_or_investorable_of_Company_type_industries_id_eq]": industryId,
        "q[tagged_with_all]": tags,
        "q[company_funding_rounds_status_description_eq]": funding,
        ...sortParam,
        page,
        per_page: perPage
      }
    },
    meta: {
      serialize: true
    }
  };
};

export const getIndustries = () => ({
  type: types.GET_INDUSTRIES,
  request: {
    url: "/api/internal/v2/industries",
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const getIndustriesTags = () => ({
  type: types.GET_INDUSTRIES_TAGS,
  request: {
    url: "/api/internal/v2/industries/tags",
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const getIndustry = ({ id }) => ({
  type: types.GET_INDUSTRY,
  request: {
    url: `/api/internal/v2/industries/${id}`,
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const createCompanyPropertyRequest = ({ id, website, body }) => ({
  type: types.CREATE_COMPANY_PROPERTY_REQUEST,
  request: {
    url: `/api/internal/v1/companies/${id}/company_property_requests`,
    method: "POST",
    data: {
      company_property_request: {
        website,
        body
      }
    }
  },
  meta: {
    serialize: true
  }
});

export const getFavourites = () => ({
  type: types.GET_FAVOURITES,
  request: {
    url: "/api/internal/v2/favourites",
    method: "GET"
  },
  meta: {
    serialize: true
  }
});

export const createFavourite = ({ id, type }) => ({
  type: types.CREATE_FAVOURITE,
  request: {
    url: "/api/internal/v1/favourites",
    method: "POST",
    data: {
      favourite: {
        favouritable_id: id,
        favouritable_type: _.startCase(_.camelCase(type)).replace(/ /g, "")
      }
    }
  },
  meta: {
    serialize: true,
    mutations: {
      [types.GET_COMPANIES]: ({ data, ...rest }, { data: { id: favourite_id } }) => {
        return {
          data: data.map(x => (x.id === id ? { ...x, favourite_id } : x)),
          ...rest
        };
      },
      [types.GET_PEOPLE]: ({ data, ...rest }, { data: { id: favourite_id } }) => {
        return {
          data: data.map(x => (x.id === id ? { ...x, favourite_id } : x)),
          ...rest
        };
      },
      [types.GET_INVESTORS]: ({ data, ...rest }, { data: { id: favourite_id } }) => {
        return {
          data: data.map(x => (x.id === id ? { ...x, favourite_id } : x)),
          ...rest
        };
      },
      [types.GET_COMPANY]: ({ data, ...rest }, { data: { id: favourite_id } }) => {
        return { data: { ...data, favourite_id }, ...rest };
      },
      [types.GET_PERSON]: ({ data, ...rest }, { data: { id: favourite_id } }) => {
        return { data: { ...data, favourite_id }, ...rest };
      },
      [types.GET_INVESTOR]: ({ data, ...rest }, { data: { id: favourite_id } }) => {
        return { data: { ...data, favourite_id }, ...rest };
      }
    }
  }
});

export const deleteFavourite = ({ id }) => ({
  type: types.DELETE_FAVOURITE,
  request: {
    url: `/api/internal/v1/favourites/${id}`,
    method: "DELETE"
  },
  meta: {
    serialize: true,
    mutations: {
      [types.GET_FAVOURITES]: ({ data, ...rest }) => {
        return { data: data.filter(fav => fav.id !== id), ...rest };
      },
      [types.GET_COMPANIES]: ({ data, ...rest }) => {
        return {
          data: data.map(x => (x.favourite_id === id ? { ...x, favourite_id: null } : x)),
          ...rest
        };
      },
      [types.GET_PEOPLE]: ({ data, ...rest }) => {
        return {
          data: data.map(x => (x.favourite_id === id ? { ...x, favourite_id: null } : x)),
          ...rest
        };
      },
      [types.GET_INVESTORS]: ({ data, ...rest }) => {
        return {
          data: data.map(x => (x.favourite_id === id ? { ...x, favourite_id: null } : x)),
          ...rest
        };
      },
      [types.GET_COMPANY]: ({ data, ...rest }) => {
        return { data: { ...data, favourite_id: null }, ...rest };
      },
      [types.GET_PERSON]: ({ data, ...rest }) => {
        return { data: { ...data, favourite_id: null }, ...rest };
      },
      [types.GET_INVESTOR]: ({ data, ...rest }) => {
        return { data: { ...data, favourite_id: null }, ...rest };
      }
    }
  }
});
export const deleteInvestor = ({ id }) => ({
  type: types.DELETE_INVESTOR,
  request: {
    url: `/api/internal/v1/investors/${id}`,
    method: "DELETE"
  }
});
