import { Box, Button, Grid, Typography, withStyles } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import CompanyPersonBox from "./CompanyPersonBox";
import FabButton from "./FabButton";
import * as Messages from "./Messages";
import withTooltip from "./withTooltip";

const MissingMember = () => <Typography>{Messages.MissingMember}</Typography>;

const SmallButton = withStyles({
  label: {
    padding: 0
  }
})(Button);

const TeamTab = ({
  companyPeopleMember,
  isCompanyOwned,
  isAuthenticated,
  isCompanyPersonProposed,
  onCompanyPersonCreate,
  onCompanyPersonDelete
}) => {
  const { url } = useRouteMatch();
  return (
    <Box p={2}>
      <FabButton show={isAuthenticated && isCompanyOwned} to={`${url}/create`} />

      {isAuthenticated && !isCompanyOwned && !isCompanyPersonProposed && (
        <Box display="flex" justifyContent="flex-end">
          {withTooltip({ title: Messages.RequestValidation, visible: isCompanyPersonProposed })(
            <SmallButton
              color="primary"
              variant="outlined"
              disabled={isCompanyPersonProposed}
              onClick={onCompanyPersonCreate}
            >
              <Typography>{Messages.IsAMember}</Typography>
            </SmallButton>
          )}
        </Box>
      )}
      <Typography variant="button">
        <strong>{Messages.Team}</strong>
      </Typography>
      {companyPeopleMember.length === 0 ? (
        <MissingMember />
      ) : (
        <Grid container spacing={2}>
          {_.orderBy(
            companyPeopleMember,
            ["is_founder", "person.last_name", "person.first_name"],
            ["desc", "asc", "asc"]
          ).map(person => (
            <Grid key={person.id} item xs={12} sm={6} md={4}>
              <CompanyPersonBox
                isCompanyOwned={isCompanyOwned}
                companyPerson={person}
                onDelete={onCompanyPersonDelete({ person })}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TeamTab;
