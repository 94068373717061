import { Divider, Grid, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { SearchLayout } from "../../layouts/search";
import About from "./About";
import ContactBox from "./ContactBox";
import Header from "./Header";
import MatchingBox from "./MatchingBox";
import * as Messages from "./Messages";
import OutlinedDiv from "./OutlinedDiv";

const TabLayout = ({
  children,
  tab,
  onTabChange,
  entity,
  canRequestOwnership,
  isOwnershipPending,
  isCompany,
  showMatching,
  isInvestor,
  investor
}) => {
  return (
    <SearchLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <OutlinedDiv minHeight={360}>
            <Header
              entity={entity}
              canRequestOwnership={canRequestOwnership}
              isOwnershipPending={isOwnershipPending}
              isCompany={isCompany}
              isInvestor={isInvestor}
              investor={investor}
            />

            <Tabs
              value={tab}
              onChange={onTabChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab value="overview" label={Messages.Overview} />
              {isCompany && <Tab value="team" label={Messages.Team} />}
              {isCompany && <Tab value="culture" label={Messages.Culture} />}
              <Tab value="funding" label={Messages.Funding} />
            </Tabs>
            <Divider />
            {children}
          </OutlinedDiv>
        </Grid>
        <Grid item xs={12} md={3}>
          <OutlinedDiv>
            <ContactBox entity={entity} />
          </OutlinedDiv>
          {showMatching && (
            <OutlinedDiv>
              <MatchingBox entity={isInvestor ? investor : entity} />
            </OutlinedDiv>
          )}
          {isCompany && (
            <OutlinedDiv>
              <About company={entity} />
            </OutlinedDiv>
          )}
        </Grid>
      </Grid>
    </SearchLayout>
  );
};

export default TabLayout;
