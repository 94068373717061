/* eslint-disable react/no-unescaped-entities */

import { Box, Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { PublicLayout } from "../layouts/public";

const useStyles = makeStyles(theme => ({
  titleContainer: {
    position: "relative",
    minHeight: 480,
    paddingBottom: 32
  },
  title: {
    position: "absolute",
    padding: 24,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white"
  },
  titleImage: {
    objectFit: "none",
    objectPosition: "center"
  },
  logoContainer: {
    textAlign: "center",
    marginTop: 48,
    marginBottom: 48,
  },
  bigImageResponsive: {
    width: "100%",
    padding: "1rem 0 1rem 4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0 1rem 0"
    }
  },
  startBoxContainer:{
    position: "relative",
    minHeight: 480,
    paddingBottom: 32
  },
  startBox: {
    position: "absolute",
    padding: 64,
    top: '30%',
    bottom: '30%',
    left: '30%',
    right: '30%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      top: '10%',
      bottom: '10%',
      left: '5%',
      right: '5%',
    }
  }
}));

const AboutUsPage = () => {
  const styles = useStyles();

  return (
    <PublicLayout>
      <Container>
        <Grid container className={styles.titleContainer}>
          <img
            className={styles.titleImage}
            src="/images/about-us/about-us-main.png"
            alt="About Us"
            width="100%"
          />
          <div className={styles.title}>
            <Typography variant="h5" component="h1">
              UpBase è il database dell'ecosistema Italiano dell'innovazione digitale.
            </Typography>
            <Typography variant="subtitle1">
              È la piattaforma di StartupItalia nata per favorire il matching tra startup ed investitori.
            </Typography>
          </div>
        </Grid>

        <Grid container justify="center" className={styles.logoContainer}>
          <Grid item xs={12} sm={10} lg={8}>
            <img
              src="/images/about-us/about-us-logo.png"
              alt="UpBase powered by StartupItalia"
              width="50%"
            />
          </Grid>
        </Grid>

        <Grid container justify="center" style={{marginTop: 48, marginBottom: 48}}>
          <Grid item xs={12} sm={10} lg={8}>
            <Typography variant="subtitle1">
              Con StartupItalia, negli anni abbiamo constatato che il nostro Paese è pieno di idee innovative che hanno bisogno di essere sostenute e incentivate.<br/>
              Se per una startup non è facile trovare investitori, anche per gli investitori non è facile trovare informazioni sempre aggiornate sulle startup che vogliono finanziare.<br/>
              L’obiettivo di UpBase è colmare questo gap grazie a un database che include tutte le startup innovative presenti sul Registro Imprese e che permette alle imprese e agli investitori di entrare in contatto, incontrarsi, scambiarsi informazioni per conoscersi meglio.
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" style={{marginTop: 48, marginBottom: 48}}>
          <Grid item xs={12} sm={10} lg={8}>
            <Typography variant="subtitle1">
              La nostra piattaforma racchiude l’intero ecosistema italiano dell’innovazione digitale, fatto di startup, team, investitori, incubatori e acceleratori.<br/>
              Permette alle startup di trovare investitori ideali per crescere insieme, agli investitori di finanziare le startup innovative italiane e, perché no, trovare il proprio Unicorno!
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={3} style={{marginTop: 48, marginBottom: 48}}>
          <Grid item xs={12} sm={6}>
            <img
              className={styles.bigImageResponsive}
              src="/images/about-us/about-us-first-banner.png"
              alt="Scopri i talenti dietro alle migliori startup italiane"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <Typography variant="h3">Title</Typography>
            <br /> */}
            <Typography variant="subtitle1">
              Se sei una startup cerca in UpBase la tua azienda, richiedine la proprietà e completa il profilo con tutte le informazioni mancanti!
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={3} style={{marginTop: 48, marginBottom: 48}}>
          <Box clone order={{ xs: 3, sm: 2 }}>
            <Grid item xs={12} sm={6}>
              {/* <Typography variant="h3">Title</Typography>
              <br /> */}
              <Typography variant="subtitle1">
                Se sei un investitore trova nuove startup su cui investire, filtrale per settore e scopri su chi puntare sulla base di categorie di interesse
              </Typography>
            </Grid>
          </Box>
          <Box clone order={{ xs: 2, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <img
                className={styles.bigImageResponsive}
                src="/images/about-us/about-us-second-banner.png"
                alt="Fai decollare la tua startup"
              />
            </Grid>
          </Box>
        </Grid>

        <Grid container justify="center">
          <Grid item xs={12} sm={10} lg={8}>
            <Typography variant="subtitle1">
              Da un sondaggio SWG è emerso che per la maggior parte dei founder, la principale difficoltà di gestione di una startup è rappresentata dalla mancanza di capitale e accesso al credito.<br/>
              Inoltre, il 51% ha dovuto cambiare modello di business in seguito alla pandemia.<br/>
              Questi due dati contribuiscono a delineare solo in parte ostacoli e problemi che affrontano i founder italiani.<br/>
              Con StartupItalia siamo a contatto quotidianamente con founder e investitori che vogliono avere un impatto positivo sull’ecosistema dell’innovazione.
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <img
            className={styles.titleImage}
            src="/images/about-us/about-us-last-banner.png"
            alt="About Us"
            width="100%"
          />
        </Grid>

        <Grid container justify="center" style={{marginBottom: 48}}>
          <Grid item xs={12} sm={10} lg={8}>
            <Typography variant="subtitle1">
              Per trasformare delle idee in modelli di business sostenibili e crescere, però, serve una vera sinergia.<br/>
              Sinergia che potrete trovare grazie alla nostra piattaforma di matching tra startup e investitori. Iscrivetevi anche alla newsletter perchè vi contatteremo per farvi conoscere il prossimo evento di StartupItalia dedicato al matching tra startup e investitori.<br/>
              Crediamo in un’Italia innovativa, in cui l’accesso a investimenti e capitali sia democratico e aperto a tutti coloro che hanno un’idea e vogliono contribuire a cambiare (in meglio) il mondo.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={styles.startBoxContainer}>
          <img
            src="/images/about-us/about-us-start-now.png"
            alt="About Us"
            width="100%"
          />
          <div className={styles.startBox}>
            <Typography variant="subtitle1">
              Noi di UpBase vi daremo una mano.<br/>
              Cosa aspettate?
            </Typography>
            <br/>
            <Button component={Link} to="/login" variant="contained" color="primary">Inizia Subito</Button>
          </div>
        </Grid>

      </Container>

    </PublicLayout>
  );
};

export default AboutUsPage;
