import _ from "lodash";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

/* eslint-disable import/prefer-default-export */
export const useQueryParams = () => {
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(search, { arrayFormat: "bracket" });

  const mergeParams = params => {
    const newParams = { ...queryParams, ...params };
    const cleanParams = _.pickBy(newParams, Boolean);
    history.push({
      search: qs.stringify(cleanParams, {
        arrayFormat: "bracket"
      })
    });
  };

  return { queryParams, mergeParams };
};
