/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import firebase from "firebase/app";
import { auth } from "../firebase";
import { socialLogin } from "../ducks/actions";
import SocialLoginButton from "../shared/components/SocialLoginButton";
import * as Messages from "./Messages";

const GoogleLogin = ({ onClick }) => (
  <SocialLoginButton icon="google" text={Messages.SignInWithGoogle} onClick={onClick} />
);
GoogleLogin.propTypes = {
  onClick: PropTypes.func.isRequired
};

const enhance = compose(
  withRouter,
  connect(undefined, dispatch => ({ actions: bindActionCreators({ socialLogin }, dispatch) })),
  withHandlers({
    onClick: ({ actions, history }) => async () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      try {
        const result = await auth.getAuth().signInWithPopup(provider);
        const { email } = result.additionalUserInfo.profile;
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        const { response } = await actions.socialLogin({ idToken, email });
        // modifica cristian cau 26.08.2022
        console.log("SOCIAL_LOGIN response",response)
        history.push(response.data.data.profile ? "/home" : "profile/new");
        // history.push(response.data.data.relationships.profile.data ? "/home" : "profile/new");
       } catch (err) {
        console.warn(err);
      }
    }
  })
);

export default enhance(GoogleLogin);
