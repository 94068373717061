/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose, withHandlers } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import firebase from "firebase/app";
import { auth } from "../firebase";
import { socialLogin } from "../ducks/actions";
import SocialLoginButton from "../shared/components/SocialLoginButton";
import * as Messages from "./Messages";

const FacebookLogin = ({ onClick }) => (
  <SocialLoginButton icon="fb" text={Messages.SignInWithFacebook} onClick={onClick} />
);

FacebookLogin.propTypes = {
  onClick: PropTypes.func.isRequired
};

const enhance = compose(
  withRouter,
  connect(undefined, dispatch => ({ actions: bindActionCreators({ socialLogin }, dispatch) })),
  withHandlers({
    onClick: ({ actions, history }) => async () => {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      provider.addScope("user_birthday");
      try {
        const result = await auth.getAuth().signInWithPopup(provider);

        console.log("user", auth.getAuth().currentUser);
        const { email } = result.additionalUserInfo.profile;
        const idToken = await auth.getAuth().currentUser.getIdToken(true);
        const { response } = await actions.socialLogin({ idToken, email });
         // modifica cristian cau 26.08.2022
         console.log("SOCIAL_LOGIN response",response)
         history.push(response.data.data.profile ? "/home" : "profile/new");
         // history.push(response.data.data.relationships.profile.data ? "/home" : "profile/new");
       } catch (err) {
        console.warn(err);
      }
    }
  })
);

export default enhance(FacebookLogin);
