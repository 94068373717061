/* eslint-disable react/prop-types */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Messages from "./Messages";

const FailedStartupImportDialog = ({ /* onConfirmClick, */ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{Messages.FailedStartupImportTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{Messages.FailedStartupImportContactUs}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
              {Messages.Close}
            </Button>
            {/* <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
              {Messages.Cancel}
            </Button>
            <Button onClick={onConfirmClick} variant="contained">
              {Messages.Confirm}
            </Button> */}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FailedStartupImportDialog;
