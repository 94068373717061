import { makeStyles, Typography, useTheme } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl.macro";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import { getInvestors } from "../ducks/actions";
import AutoForm from "../shared/components/AutoForm";
import FullScreenDialog from "../shared/components/FullScreenDialog";
import CurrencyField from "../shared/formik/CurrencyField";
import DatePickerField from "../shared/formik/DatePickerField";
import ReactSelectField from "../shared/formik/ReactSelectField";
import { fundingStatusOptions } from "../shared/utils";
import * as Messages from "./Messages";

const useStyles = makeStyles(() => ({
  field: {
    marginTop: 8
  }
}));

const InvestorField = ({ form, field, ...other }) => {
  const dispatch = useDispatch();
  const handleChange = options => {
    form.setFieldValue(
      field.name,
      (options || []).map(item => ({ label: item.label, value: item.value })),
      true
    );
  };

  const onLoadInvestors = async (inputValue, callback) => {
    const {
      response: {
        data: { data: investors }
      }
    } = await dispatch(getInvestors({ name: inputValue }));
    const investorOptions = investors.map(investor => ({
      label: investor.name,
      value: investor.id,
      type: investor.investorable?.type
    }));

    const investorsOptionsByType = _.groupBy(investorOptions, "type");

    const groupedInvestorsOptions = [
      { label: Messages.People, options: investorsOptionsByType.person || [] },
      { label: Messages.IncubatorsAndAccelerators, options: investorsOptionsByType.company || [] }
    ];
    callback(groupedInvestorsOptions);
  };

  const currentError = form.errors[field.name];
  const error = Boolean(currentError) && Boolean(form.touched[field.name]);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <AsyncSelect
        {...field}
        loadOptions={onLoadInvestors}
        isMulti
        cacheOptions
        defaultOptions
        placeholder={Messages.InvestorsPlaceholder}
        className={classes.field}
        onBlur={field.onBlur}
        inputId={field.name}
        styles={{
          control: base => ({
            // border: "solid 1px",
            // borderRadius: "4px",
            ...base,
            borderColor: error ? theme.palette.error.main : base.borderColor
          })
        }}
        onChange={handleChange}
        {...other}
      />
      {error && (
        <Typography variant="body2" color="error">
          <FormattedMessage id={currentError} />
        </Typography>
      )}
    </>
  );
};

const RoundDialog = ({ title, onClose, onSubmit, round }) => {
  const [fieldConfiguration] = useState({
    default: {
      fields: [
        {
          name: "status_description",
          label: "Status*",
          component: ReactSelectField,
          options: fundingStatusOptions
        },
        {
          name: "amount",
          label: "Importo*",
          component: CurrencyField,
          outputFormat: "number",
          currencySymbol: "€",
          minimumValue: "0",
          decimalCharacter: ",",
          digitGroupSeparator: "."
        },
        {
          name: "date",
          label: "Data dell'investimento*",
          component: DatePickerField,
          format: "DD/MM/YYYY",
          inputVariant: "outlined",
          disableFuture: true,
          variant: "inline"
        },
        {
          name: "investor_ids",
          label: "Investitori*",
          component: InvestorField
        }
      ]
    }
  });

  const [validationSchema] = useState(
    yup.object().shape({
      date: yup
        .mixed()
        .required()
        .typeError("Errore data"),
      status_description: yup.string().required(),
      amount: yup
        .string()
        // .matches(
        //   /^[0-9]+(.[0-9]{1,2})?$/,
        //   "Importo non valido (usare il punto come virgola decimale)"
        // )
        .required(),
      investor_ids: yup.array().required()
    })
  );

  const [formikProps] = useState({
    enableReinitialize: true,
    initialValues: {
      ...round,
      date: (round || {}).date ? round.date : null,
      investor_ids: ((round || {}).investors || []).map(investor => ({
        label: investor.name,
        value: investor.id
      }))
    },
    onSubmit,
    validationSchema
  });

  return (
    <FullScreenDialog title={title} onClose={onClose}>
      <AutoForm
        formikProps={formikProps}
        fieldConfiguration={fieldConfiguration}
        label={Messages.Save}
      />
    </FullScreenDialog>
  );
};

const RoundShape = PropTypes.shape({
  status_description: PropTypes.string,
  amount: PropTypes.string,
  date: PropTypes.string
});

RoundDialog.propTypes = {
  title: PropTypes.string.isRequired,
  round: RoundShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RoundDialog;
